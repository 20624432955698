// 直播模式
import TRTC from 'trtc-js-sdk'

class ShareClient {
  constructor(options) {
    this.sdkAppId_ = options.sdkAppId;
    this.userId_ = options.userId;
    this.userSig_ = options.userSig;
    this.roomId_ = options.roomId;
    this.shareId_ = `share-${options.userId}`

    this.isJoined_ = false;
    this.isPublished_ = false;
    this.localStream_ = null;

    this.client_ = TRTC.createClient({
      mode: 'rtc',
      sdkAppId: this.sdkAppId_,
      userId: this.shareId_,
      userSig: window.genTestUserSig(this.shareId_).userSig,
      // streamId: `${this.sdkAppId_}_${this.roomId_}_${thsi.shareId_}_main_share`
    });

    this.handleEvents()
  }

  async join () {
    if (this.isJoined_) {
      console.warn('duplicate RtcClient.join() observed');
      return;
    }
    // create a local stream for screen share
    this.localStream_ = TRTC.createStream({
      // disable audio as RtcClient already enable audio
      audio: true,
      // enable screen share
      screen: true,
      userId: this.shareId_
    });

    try {
      // initialize the local stream to populate the screen stream
      this.localStream_.setScreenProfile('1080p');
      await this.localStream_.initialize();
      console.log('ShareClient initialize local stream for screen share success');

      this.localStream_.on('player-state-changed', event => {
        console.log(`local stream ${event.type} player is ${event.state}`);
      });
      document.getElementById('cancelScreenSharing').style.display='block'
      document.getElementById('screenSharing').style.display='none'
      // 当用户通过浏览器自带的按钮停止屏幕分享时，会监听到 screen-sharing-stopped 事件
      this.localStream_.on('screen-sharing-stopped', event => {
        console.log('share stream video track ended');
        document.getElementById('cancelScreenSharing').style.display='none'
        document.getElementById('screenSharing').style.display='block'
        this.leave();
      });
    } catch (error) {
      console.error('fail to initialize share stream -', error);
      switch (error.name) {
        case 'NotReadableError':
          alert('屏幕分享失败，请确保系统允许当前浏览器获取屏幕内容');
          return;
        case 'NotAllowedError':
          if (error.message === 'Permission denied by system') {
            alert('屏幕分享失败，请确保系统允许当前浏览器获取屏幕内容');
          } else {
            document.getElementById('cancelScreenSharing').style.display='none'
            document.getElementById('screenSharing').style.display='block'
            console.log('User refused to share the screen');
          }
          return;
        default:
          return;
      }
    }

    try {
      await this.client_.join({
        roomId: parseInt(this.roomId_)
      });
      console.log('ShareClient join room success');
      this.isJoined_ = true;
    } catch (error) {
      console.error('ShareClient join room failed! ' + error);
    }

    try {
      // publish the screen share stream
      await this.client_.publish(this.localStream_);
      this.isPublished_ = true;
      // 本地屏幕共享流
      // this.localStream_.play('video-container', {
      //   objectFit: 'contain'
      // })
    } catch (error) {
      console.error('ShareClient failed to publish local stream ' + error);
    }
  }

  async leave () {
    if (!this.isJoined_) {
      console.warn('leave() - please join() firstly');
      return;
    }
    if (this.isPublished_) {
      await this.client_.unpublish(this.localStream_);
      this.isPublished_ = false;
    }
    if (this.isJoined_) {
      await this.client_.leave();
      this.isJoined_ = false;
    }
    if (this.localStream_) {
      this.localStream_.stop();
      this.localStream_.close();
      this.localStream_ = null;
    }
  }

  handleEvents () {
    // 指明该 shareClient 不接收任何远端流 （它只负责发送屏幕分享流）
    this.client_.on('stream-added', event => {
      const remoteStream = event.stream
      this.client_.unsubscribe(remoteStream)
    })

    // 用户被踢出房间通知，被踢原因有：
    // 同名用户登录，注意：同名用户同时登陆是不允许的行为，可能会导致双方音视频通话异常，此乃应用业务逻辑错误！
    // 被账户管理员主动踢出房间
    this.client_.on('client-banned', error => {
      console.error('Share client用户被踢出房间通知: ' + error);
      // 退出刷新页面
    });
  }
}

class LiveClient {
  constructor(options) {
    this.sdkAppId_ = options.sdkAppId;
    this.userId_ = options.userId;
    this.userSig_ = options.userSig;
    this.roomId_ = options.roomId;

    this.isJoined_ = false;
    this.isPublished_ = false;
    this.localStream_ = null;

    this.client_ = TRTC.createClient({
      mode: 'rtc',
      sdkAppId: this.sdkAppId_,
      userId: this.userId_,
      userSig: this.userSig_,
      shareId:`share-${this.userId_}`
    });

    // this.shareClient_ = new ShareClient(options)

    this.handleEvents();
  }

  async join () {
    if (this.isJoined_) {
      console.warn('duplicate RtcClient.join() observed');
      return;
    }
    // create a local stream for screen share
    this.localStream_ = TRTC.createStream({
      // disable audio as RtcClient already enable audio
      audio: true,
      // enable screen share
      video: true,
      userId: this.userId_
    });

    try {
      // initialize the local stream
      this.localStream_.setScreenProfile('1080p');
      await this.localStream_.initialize();
      console.log('local stream success');

      this.localStream_.on('player-state-changed', event => {
        console.log(`local stream ${event.type} player is ${event.state}`);
      });

    } catch (error) {
      console.error('fail to initialize share stream -', error);
    }

    try {
      await this.client_.join({
        roomId: parseInt(this.roomId_)
      });
      console.log('join room success');
      this.isJoined_ = true;
    } catch (error) {
      window.dispatchEvent(new Event('leave'));
      console.warn('RtcClient.join join room fail:', JSON.stringify(error))
      console.error('join room failed! ' + error);
    }

    try {
      await this.client_.publish(this.localStream_);
      this.isPublished_ = true;
      this.localStream_.play('user-video-container', {
        objectFit: 'contain'
      })
    } catch (error) {
      console.error('ShareClient failed to publish local stream ' + error);
    }

    // this.shareClient_.join()
  }

  async leave () {
    // 结束屏幕共享流
    await this.shareClient_.leave()

    if (!this.isJoined_) {
      console.warn('leave() - please join() firstly');
      return;
    }
    if (this.isPublished_) {
      await this.client_.unpublish(this.localStream_);
      this.isPublished_ = false;
    }
    if (this.isJoined_) {
      await this.client_.leave();
      this.isJoined_ = false;
    }
    if (this.localStream_) {
      this.localStream_.stop();
      this.localStream_.close();
      this.localStream_ = null;
    }

  }

  handleEvents () {
    this.client_.on('error', err => {
      console.error(err);
      alert(err);
    });
    // 主 Client 中指定取消订阅 shareId 的远端流
    this.client_.on('stream-added', evt => {
      const remoteStream = evt.stream;
      const id = remoteStream.getId();
      const remoteUserId = remoteStream.getUserId();
      if (remoteUserId === `share-${this.userId_}`) {
        // 取消订阅自己的屏幕分享流
        this.client_.unsubscribe(remoteStream);
      } else {
        // 订阅其他一般远端流
        this.client_.subscribe(remoteStream);
      }
      console.log('subscribe to this remote stream');
    });
    // 用户被踢出房间通知，被踢原因有：
    // 同名用户登录，注意：同名用户同时登陆是不允许的行为，可能会导致双方音视频通话异常，此乃应用业务逻辑错误！
    // 被账户管理员主动踢出房间
    this.client_.on('client-banned', error => {
      console.error('用户被踢出房间通知: ' + error);
      // 退出刷新页面
    });
  }
}

export{LiveClient,ShareClient}
