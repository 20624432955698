import api from './request'

/**
 * 开箱page
 */
export const pagePacking = (params) => {
    return api.post('/admin/packing/pagePacking', params, { headers: { "Content-Type": "application/json" } })
}
/**
 * 新增开箱
 */
export const addPacking = (params) => {
    return api.post('/admin/packing/addPacking', params, { headers: { "Content-Type": "application/json" } })
}
/**
 * 删除开箱
 */
export const delPacking = (params) => {
    return api.get('/admin/packing/delPacking', { params })
}
/**
 * 开箱单目录明细
 */
export const packingCatalogCaseList = (params) => {
    return api.get('/admin/packing/packingCatalogCaseList', { params })
}
/**
 * 开箱单目录json
 */
export const packingCatalogTreeList = (params) => {
    return api.get('/admin/packing/packingCatalogTreeList', { params })
}
/**
 * 修改开箱单目录json
 */
export const updPackingCatalog = (params) => {
    return api.get('/admin/packing/updPackingCatalog', { params })
}
/**
 * 修改单行
 */
export const updPackingCatalogCase = (params) => {
    return api.post('/admin/packing/updPackingCatalogCase', params, { headers: { "Content-Type": "application/json" } })
}

/**
 * 查看回执单
 */
export const getPackingInfoByHuizhi = (params) => {
    return api.get('/admin/packing/getPackingInfoByHuizhi', { params })
}
/**
 * 保存回执单
 */
export const savePackingList = (params) => {
    return api.post('/admin/packing/savePackingList', params, { headers: { "Content-Type": "application/json" } })
}

/**
 * 签名
 */
export const updPackingSignature = (params) => {
    return api.post('/admin/packing/updPackingSignature', params, { headers: { "Content-Type": "application/json" } })
}

/**
 * 查看签名
 */
export const getPackingSignature = (params) => {
    return api.get('/admin/packing/getPackingSignature', { params })
}
/**
 * 单个箱行作废
 */
export const updPackCatalogCaseInvalid = (params) => {
    return api.get('/admin/packing/updPackCatalogCaseInvalid', { params })
}
/**
 * 单个箱行作废
 */
export const updPackCatalogInvalid = (params) => {
    return api.get('/admin/packing/updPackCatalogInvalid', { params })
}
