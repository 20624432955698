import api from './request'

/**
 * 获取好友列表哦
 * @param {*} params 
 * @returns 
 */
export const getFriendList = (params) => {
  return api.get('/admin/webUserFriend/pageManagerAccountAndFriend', { params })
}

/**
 * 获取好友列表哦
 * @param {*} params 
 * @returns 
 */
export const delFriendById = (params) => {
  return api.get('/admin/webUserFriend/delFriend', { params })
}
/**
 * 添加好友
 * @param {*} params 
 * @returns 
 */
export const addFriendById = (params) => {
  return api.get('/admin/webUserFriend/addFriend', { params })
}
/**
 * 获取摄像头列表
 * @param {*} params
 * @returns
 */
export const getLiveList = (params) => {
  return api.get('/admin/displayLive/getLiveList', )
}

/**
 * 获取摄像头列表名称
 * @param {*} params
 * @returns
 */
export const jsonLiveList = (params) => {
  return api.get('/admin/displayLive/jsonLiveList', )
}

/**
 * 踢人
 */
export const removeUser = (params) => {
  return api.get('admin/htcs/trtc/room/removeUser',{ params } )
}
