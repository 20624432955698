<template>
  <div @click="handleFriendClick" class="scroll-container">
    <div class="group-item">
      <avatar :src="friend.avatar" />
      <div class="group-name text-ellipsis">{{ friend.realName || friend.friendImId }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    friend: {
      type: Object,
      required: true,
    },
  },
  methods: {
    handleFriendClick() {
      this.tim
        .getConversationProfile(`C2C${this.friend.friendImId}`)
        .then(({ data }) => {
          this.$store.commit('updateCurrentConversation', data)
           const conversationID = `C2C${this.friend.friendImId}`
          this.$store.dispatch('checkoutConversation', conversationID)
        })
        .catch((error) => {
          this.$store.commit('showMessage', {
            type: 'error',
            message: error.message,
          })
        })
    },
    quitGroup() {
      this.tim.quitGroup(this.group.groupID).catch((error) => {
        this.$store.commit('showMessage', {
          type: 'error',
          message: error.message,
        })
      })
    },
  },
}
</script>

<style lang="stylus" scoped>
.scroll-container {
  overflow-y: scroll;
  flex: 1;

  .group-item {
    display: flex;
    padding: 15px 20px;
    height: 70px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: 0.2s;

    &:hover {
      background-color: $background;
    }

    .avatar {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 10px;
      flex-shrink: 0;
    }

    .group-name {
      flex: 1;
      color: $font-light;
      line-height: 30px;
    }
  }
}
</style>
