<template>
  <el-dialog
    title="视频会议"
    :visible="visible"
    custom-class="custom-video-meeting"
    :before-close="beforeClose"
    :close-on-click-modal="false"
    :append-to-body="true"
    top="8vh"
    :modal="false"
    v-dialogDrag
  >
    <div style="position: absolute;top:23px;right:78px;cursor: pointer;" @click="shrinkBtnVideo" title="缩小放大">一</div>
    <div style="position: absolute;top:23px;right:48px;cursor: pointer;" @click="fullEnlarge"><img src="@/assets//image/large.png" alt=""></div>
    <!-- :show-close="false" -->
    <div style="width:100%;height:100%;background: #fff;" id="call">
      <div v-if="EnlargeShow" style="text-align: right;margin-right: 24px;margin-top:10px;" @click="fullEnlarge"><img src="@/assets//image/large.png" alt=""></div>
      <section class="video-meeting-container"   >
        <section class="friend-list">
          <div class="el-form-item">
            <el-input
                    v-model="form.realName"
                    @keypress.native.enter.prevent.stop="getFriendList"
                    placeholder="请输入关键字搜索好友"
            ></el-input>
          </div>

          <ul class="f-list" v-if="list.length">
            <li class="f-list-li" v-for="(item,key) in list" :key="'meeting'+key">
              <transition name="fade">
                <el-checkbox
                        v-if="mutilpart"
                        v-model="item.selected"
                        @change="updateCheckbox"
                        :disabled="userEnteredMeeting(item)"
                ></el-checkbox>
              </transition>
              <el-image :src="item.avatar" fit="cover" @click="toggleCheckbox(key)"></el-image>
              <span class="f-user-info">
              <span class="f-user-name" :title="item.realName">{{item.realName}}</span>
              <template>
                <span
                        class="f-user-name selectColor"
                        v-if="!userEnteredMeeting(item)&&!mutilpart"
                        @click.stop="inviteUser(item)"
                >立即邀请</span>
                <span class="f-user-name selectColor" v-if="userEnteredMeeting(item)">已加入</span>
              </template>
            </span>
            </li>
          </ul>
          <div v-else>
            <Nodata />
          </div>
          <div class="multi-div" v-if="list.length">
            <el-checkbox v-model="mutilpart">多选</el-checkbox>
            <el-button type="success" @click.stop="inviteUsers">邀请</el-button>
          </div>
        </section>
        <section class="video-list">
          <section class="video-list-sec">
            <div class="meeting-video-list">
              <div style="padding-left:10px;margin-top:10px;">
                <el-select v-model="deviceName" multiple placeholder="请添加摄像头" style="width: 252px;"  collapse-tags @change="selectDevice">
                  <el-option
                          v-for="item in liveList"
                          :key="item.deviceId"
                          :label="item.deviceName"
                          :value="item.deviceId"
                          :disabled="item.deviceOnLine === 0?true:false"
                  >
                  </el-option>
                </el-select>
                <!--摄像头-->
                <template  >
                  <PlayerLiveMetting  :liveData="liveData" :selectUserIdOfGroup="selectUserIdOfMeeting" @userIdData = "userIdData"></PlayerLiveMetting>
                </template>
              </div>

              <div v-if="joinedList.length">
                <template v-for="(user,key) in joinedList">
                  <div
                          v-if="user.display||user.userID==userID"
                          class="meeting-item"
                          @click="selectUserIdOfMeeting = user.userID"
                          :class="{'is-mine-meeting':user.userID==userID,'big':selectUserIdOfMeeting===user.userID,'small':selectUserIdOfMeeting!==user.userID}"
                          :key="'meeting'+user.userID+key"
                  >
                    <div :id="'video-meeting_'+user.userID" style="height:100%;background: #000;" >
                      <div style="position: absolute;left:10px;bottom:15px;color: #000;z-index: 1;">{{user.nick}}</div>
                      <template v-if="isCalling">
                        <template v-if="user.userID==userID">
                          <div class="video-meeting-mask" v-if="!isVideoOn">
                            <img class="image" src="@/assets/tweblive/camera-max.png" />
                            <p class="notice">摄像头未打开</p>
                          </div>
                        </template>
                        <template v-else>
                          <div class="video-meeting-mask" v-if="!user.isVideoOn">
                            <img class="image" src="@/assets/tweblive/camera-max.png" />
                            <p class="notice">摄像头未打开</p>
                          </div>
                        </template>
                      </template>
                      <div class="meeting-status" v-if="isCalling">
                        <template v-if="user.userID==userID">
                          <img v-if="isVideoOn" src="@/assets/tweblive/camera-on.png" alt />
                          <img v-else src="@/assets/tweblive/camera-off.png" alt />
                          <img v-if="isAudioOn" src="@/assets/tweblive/mic-on.png" alt />
                          <img v-else src="@/assets/tweblive/mic-off.png" alt />
                          <img v-if="!fullScreenShow" src="@/assets/tweblive/fullScrren.png" @click="fullScreen(user.userID)" >
                          <img v-else src="@/assets/tweblive/fullscreen_exit.png" @click="fullScreen(user.userID)" >
                        </template>
                        <template v-else>
                          <img v-if="user.isVideoOn" src="@/assets/tweblive/camera-on.png" alt />
                          <img v-else src="@/assets/tweblive/camera-off.png" alt />
                          <img v-if="user.isAudioOn" src="@/assets/tweblive/mic-on.png" alt />
                          <img v-else src="@/assets/tweblive/mic-off.png" alt />
                          <img v-if="!fullScreenShow" src="@/assets/tweblive/fullScrren.png" @click="fullScreen(user.userID)" >
                          <img v-else src="@/assets/tweblive/fullscreen_exit.png" @click="fullScreen(user.userID)" >
                        </template>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </section>
          <section class="meeting-operator" v-if="isCalling">
            <el-select v-model="value" placeholder="请选择要录像的人物" :disabled="disableChange" @visible-change="visibleChange($event)">
              <el-option
                      v-for="userProfile in joinedList"
                      :key="userProfile.userID"
                      :label="userProfile.nick"
                      :value="userProfile.userID">
              </el-option>
            </el-select>
            <el-button
                    v-if="!videotapeStart"
                    class="action-btn"
                    type="success"
                    @click="videotape"
            >录像</el-button>
            <el-button
                    v-if="videotapeStart"
                    class="action-btn"
                    type="danger"
                    @click="Stopvideotape"
            >停止录像</el-button>
            <el-button class="right" type="danger" @click="screenSharing" id="screenSharing" v-show="isShare">屏幕共享</el-button>
            <el-button class="right" type="danger" @click="cancelScreenSharing" id="cancelScreenSharing" v-show="!isShare">取消屏幕共享</el-button>
            <el-button type="success" @click.stop="toggleVideo">{{isVideoOn ? '关闭摄像头' : '打开摄像头'}}</el-button>
            <el-button type="success" @click.stop="toggleAudio">{{isAudioOn ? '关闭麦克风' : '打开麦克风'}}</el-button>
            <el-button type="danger" @click.stop="holdUp">挂 断</el-button>
          </section>
        </section>
      </section>
    </div>

  </el-dialog>
</template>
<script>
import { getFriendList ,getLiveList} from '@/api/im'
import MeetingClient from '@/utils/video-meeting-client'
import { mapState } from 'vuex'
import { debounce } from '@/utils'
  import PlayerLive from "./PlayerLive";
import PlayerLiveMetting from "@/components/Chat/layout/components/PlayerLiveMetting";
import {launchIntoFullscreen, exitFullscreen, checkFull} from "../../../../utils/fullScreen";
import { ShareClient } from '@/utils/live-client'
import { startTrtcRoomTranscribe ,stopTrtcRoomTranscribe} from '@/api/screenShot'
import '@/api/dialog'

export default {
  name: 'MeetingCall',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      videoRoom: (state) => state.video.videoRoom,
      currentConversation: (state) => state.conversation.currentConversation,
      userID: (state) => state.user.userID,
      userSig: (state) => state.user.userSig,
      sdkAppID: (state) => state.user.sdkAppID,
      realName: (state) => state.user.realName,
      callComponentType: (state) => state.video.callComponentType,
    }),
    // 是否
    isCalling() {
      return this.VideoMeetingTRTC
    },
  },
  components: {
    PlayerLive,
    PlayerLiveMetting
  },
  inject: ['reload'],
  watch: {
    visible: {
      handler(val) {
        if (val) {
          this.init()
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      list: [],
      joinedList: [],
      form: {
        realName: '',
      },
      mutilpart: false,
      VideoMeetingTRTC: null,
      isVideoOn: true,
      isAudioOn: true,
      selectUserIdOfMeeting: '',
      liveList:[],
      deviceName:'',
      selectList:[],
      liveData:[],
      fullScreenShow:false,
      fullUserId:'',
      EnlargeShow:false,
      isShare:true,
      suo:false,
      videotapeStart:false,
      value:'',
      disableChange:false
    }
  },
  created() {
      let that = this
      window.addEventListener('resize', () => {
        if (!checkFull()) {
          console.log('123')
          //触发esc事件，执行业务逻辑。
          that.fullScreenShow = false
          that.EnlargeShow = false
        }
      })

  },
  mounted() {
    window.addEventListener('beforeunload', (event) => {
      this.handleEndTrtcCalling()
    })
    this.getLiveList()
    this.selectUserIdOfMeeting = this.userID
  },
  destroyed() {
    window.removeEventListener('beforeunload', (event) => {
      this.handleEndTrtcCalling()
    })
  },
  methods: {
    userIdData(value){
      this.selectUserIdOfMeeting = value
    },
    //下拉选状态
    visibleChange(event){
      this.visibleChangeStatus = event
    },
    //录像
    videotape(){
      if(this.value===''){
        this.$Message.warning('请选择要录制的人员')
      }else{
        console.log(this.VideoMeetingTRTC)
        if(this.isFromGroup){
          var groupID = this.currentConversation.conversationID
          var groupName = this.displayTitle
        }else{
          var groupID = ''
          var groupName = ''
        }
        startTrtcRoomTranscribe({imId:this.value,roomId:this.VideoMeetingTRTC.roomId_,groupId:groupID,}).then((res)=>{
          if(res.code === 200){
            this.videotapeStart = true
            this.disableChange = true
          }else{
            this.$Message.error(res.msg)
          }
        })
      }
    },
    //停止录像
    Stopvideotape(){
      stopTrtcRoomTranscribe({imId:this.value,roomId:this.VideoMeetingTRTC.roomId_}).then((res)=>{
        if(res.code===200){
          this.disableChange = false
          this.videotapeStart = false
        }else{
          this.$Message.error(res.msg)
        }
      })
    },
    shrinkBtnVideo(){
      this.suo = !this.suo
      const dialogDom = document.querySelector('.custom-video-meeting')
      console.log(dialogDom)
      if(this.suo){
        dialogDom.style.width = '400px';
        dialogDom.style.height = '300px';
      }else{
        dialogDom.style.width = '80vw';
        dialogDom.style.height = '80vh';
      }

    },
    fullEnlarge(){
      this.EnlargeShow = !this.EnlargeShow
      if (this.EnlargeShow) {
        launchIntoFullscreen(document.getElementById('call'))
        return false;
      } else {
        exitFullscreen(document.getElementById('call'))
      }
    },
    //全屏
    fullScreen(userID){
      this.fullUserId = userID
      this.fullScreenShow = !this.fullScreenShow;
      if (this.fullScreenShow) {
        launchIntoFullscreen(document.getElementById('video-meeting_'+userID))
        return false;
      } else {
        exitFullscreen()
      }
    },
    //选中摄像头
    selectDevice(value){
        this.selectList = value
        this.liveData = []
        this.selectList.forEach((item,index)=>{
          this.liveList.forEach((item1,index1)=>{
            if(item === item1.deviceId){
              this.liveData.push(item1)
              this.$nextTick(()=>{

              })
            }
          })
       })
    },
    // 刷新之后结束视频会议
    handleEndTrtcCalling() {
      // 1.解散群组
      this.dismissGroup()
        .then()
        .catch(() => {
          console.log('解散群组失败！')
        })
    },
    updateCheckbox() {
      this.$forceUpdate()
    },
    toggleCheckbox(key) {
      this.list[key].selected = !this.list[key].selected
      this.updateCheckbox()
    },
    init() {
      this.resetForm()
      this.getFriendList()
    },
    resetForm() {
      this.list = []
      this.joinedList = []
      this.form.realName = ''
      this.mutilpart = false
      this.VideoMeetingTRTC = null
      this.isVideoOn = true
      this.isAudioOn = true
    },
    // 创建群组
    createGroup() {
      return new Promise((resolve, reject) => {
        let options = {
          groupID: this.videoRoom + '',
          name: this.videoRoom + '',
          type: this.TIM.TYPES.GRP_MEETING,
          memberList: [],
        }
        this.tim
          .createGroup(options)
          .then((imResponse) => {
            resolve()
          })
          .catch((error) => {
            this.$store.commit('showMessage', {
              type: 'error',
              message: error.message,
            })
            reject()
          })
      })
    },
    // 获取群成员列表
    getGroupMemberList() {
      return new Promise((resolve, reject) => {
        let data = {
          groupID: this.videoRoom + '',
          count: 100,
          offset: 0,
        }
        this.tim
          .getGroupMemberList(data)
          .then((imResponse) => {
            let {
              data: { memberList },
            } = imResponse
            if (memberList.length) {
              memberList.map((item) => {
                item.isAudioOn = true
                item.isVideoOn = true
                return item
              })
            }
            this.joinedList = memberList
            resolve()
          })
          .catch((err) => {
            console.log('获取群成员列表失败！')
            reject()
          })
      })
    },
    // 解散群组
    dismissGroup() {
      return new Promise((resolve, reject) => {
        this.tim
          .dismissGroup(this.videoRoom + '')
          .then((res) => {
            resolve()
          })
          .catch((err) => {
            reject()
          })
      })
    },
    initTRTC: async function () {
      this.$store.dispatch('video/GENERATE_VIDEO_ROOM')
      // 1.创建临时会议群
      let bStop = true
      await this.createGroup()
        .then()
        .catch((err) => {
          bStop = false
        })
      if (!bStop) {
        console.log('建群未通过！')
        return
      }
      // 1.1阻止音视频通话
      this.$store.dispatch('video/updateCallStatus', 'calling')
      this.$store.dispatch('video/updateCallComponentType', 'meeting')
      // 2.创建群视频会议
      let options = {
        roomId: this.videoRoom,
        userId: this.userID,
        userSig: this.userSig,
        sdkAppId: this.sdkAppID,
      }
      this.VideoMeetingTRTC = new MeetingClient(options)
      // 3.监听视频会议消息
      this.initListener()
      // 3.1 获取群成员列表
      await this.getGroupMemberList()
      // 4.加入视频会议
      await this.VideoMeetingTRTC.join()
      // 5.邀请视频会议成员
    },
    resetData() {
      this.VideoMeetingTRTC = null
      this.joinedList = []
    },
    holdUp: async function () {
      this.closeDialog()
      this.Stopvideotape()
      // 2.关闭直播间
      await this.VideoMeetingTRTC.leave()
      // 1.解散群组
      await this.dismissGroup()
        .then()
        .catch(() => {
          console.log('解散群组失败！')
        })
      this.cancelScreenSharing()
      this.resetData()
    },
    // 用户是否已经加入视频会议
    userEnteredMeeting(userProfile) {
      return this.joinedList.some(
        (i) => i.friendImId === userProfile.friendImId
      )
    },
    // 发送视频会议邀请的自定义消息
    sendInviteMessage: function ({ to, payload: { data } }) {
      return new Promise((resolve, reject) => {
        const message = this.tim.createCustomMessage({
          to: to,
          conversationType: 'C2C',
          payload: {
            data: JSON.stringify(data),
            description: '',
            extension: '',
          },
        })
        this.$store.commit('pushCurrentMessageList', message)
        this.tim
          .sendMessage(message)
          .then(() => {
            resolve()
          })
          .catch((error) => {
            this.$store.commit('showMessage', {
              type: 'error',
              message: error.message,
            })
            reject()
          })
      })
    },
    // 邀请用户加入视频会议--单个邀请
    inviteUser: async function (userProfile) {
      await this.$store.dispatch('loading/open')
      let isJoined = this.joinedList.some(
        (i) => i.friendImId === userProfile.friendImId
      )
      if (!this.VideoMeetingTRTC) {
        await this.initTRTC()
      }
      // 5.邀请视频会议成员
      if (!isJoined) {
        let msg = {
          to: userProfile.friendImId,
          payload: {
            data: {
              type: 1,
              roomId: this.videoRoom,
              // content: `${this.realName}邀请您参加视频会议`,
              content: `视频会议`,
            },
          },
        }
        await this.sendInviteMessage(msg)
        await this.$store.dispatch('loading/close')
      }
    },
    // 多个邀请
    inviteUsers() {
      let selectedList = this.list.filter((i) => i.selected)
      if (selectedList.length) {
        selectedList.map((userProfile) => {
          this.inviteUser(userProfile)
        })
      }
    },
    getFriendList() {
      let data = {
        page: 1,
        limit: 9999,
        realName: this.form.realName,
      }
      getFriendList(data)
        .then((res) => {
          let {
            data: { list },
          } = res
          if (list.length) {
            list.map((i) => {
              i.selected = false
              i.isVideoOn = true
              i.isAudioOn = true
              return i
            })
          }
          this.list = list.filter((i) => i.friendImId !== this.userID)
        })
        .catch((err) => {
          this.$store.commit('showMessage', {
            type: 'error',
            message: err.msg,
          })
        })
    },
    refreshData() {
      this.$emit('refreshData')
    },
    beforeClose(done) {
      if (this.callComponentType === 'meeting') {
        this.$confirm('正在视频会议中，确定关闭么?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(async () => {
            // 2.关闭直播间
            await this.VideoMeetingTRTC.leave()
            this.closeDialog()
            // 1.解散群组
            await this.dismissGroup()
              .then()
              .catch(() => {
                console.log('解散群组失败！')
              })
            this.cancelScreenSharing()

          })
          .catch(() => {})
      } else {
        this.closeDialog()
      }
      done()
    },
    closeDialog() {
      // 挂断之后，将音视频状态改为空闲
      this.$store.dispatch('video/updateCallComponentType', '')
      this.$store.dispatch('video/updateCallStatus', 'idle')
      this.$emit('closeDialog', 'dialogOfMeetingCall')
      this.liveData=[]
      this.deviceName = ''
      // this.reload()
    },
    submit() {
      this.refreshData()
      this.closeDialog()
    },
    // trtc Client 方法
    initListener() {
      if (this.VideoMeetingTRTC) {
        // fired when a remote peer is joining the room
        this.VideoMeetingTRTC.client_.on('peer-join', this.handleUserEnter)
        // fired when a remote peer is leaving the room
        this.VideoMeetingTRTC.client_.on('peer-leave', this.handleUserLeave)
        // fired when a remote stream is added
        this.VideoMeetingTRTC.client_.on('stream-added', this.handleStreamAdded)
        // fired when a remote stream has been subscribed
        this.VideoMeetingTRTC.client_.on(
          'stream-subscribed',
          this.handleStreamSubscribed
        )
        // fired when the remote stream is removed, e.g. the remote user called Client.unpublish()
        this.VideoMeetingTRTC.client_.on(
          'stream-removed',
          this.handleStreamRemoved
        )
        this.VideoMeetingTRTC.client_.on('mute-audio', this.muteAudio)
        this.VideoMeetingTRTC.client_.on('unmute-audio', this.unmuteAudio)
        this.VideoMeetingTRTC.client_.on('mute-video', this.muteVideo)
        this.VideoMeetingTRTC.client_.on('unmute-video', this.unmuteVideo)
      }
    },
    // fired when a remote peer is joining the room
    handleUserEnter(evt) {
      console.log('handleUserEnter', evt)
      if (this.joinedList.every((i) => i.userID != evt.userId)) {
        let nick = ''
        this.list.forEach((item,index)=>{
          if(evt.userId === item.friendImId){
            nick = item.realName
          }
        })
        this.joinedList.push({
          userID: evt.userId,
          isAudioOn: true,
          isVideoOn: true,
          display: true,
          nick:nick
        })

      } else {
        for (let item of this.joinedList) {
          if (item.userID == evt.userId) {
            item.display = true
          }
        }
      }
    },
    // fired when a remote peer is leaving the room
    handleUserLeave(evt) {
      console.log('handleUserLeave', evt)
      let userId = evt.userId
      for (let item of this.joinedList) {
        if (item.userID == userId) {
          item.display = false
        }
      }
    },
    // fired when a remote stream is added
    handleStreamAdded(evt) {
      console.log('handleStreamAdded', evt)
      const remoteStream = evt.stream
      const id = remoteStream.getId()
      const userId = remoteStream.getUserId()
      console.log(
        `remote stream added: [${userId}] ID: ${id} type: ${remoteStream.getType()}`
      )
      // 订阅远程流
      console.log('subscribe to this remote stream')
      this.VideoMeetingTRTC.client_.subscribe(remoteStream)
    },
    // fired when a remote stream has been subscribed
    handleStreamSubscribed(evt) {
      console.log('handleStreamSubscribed', evt)
      const uid = evt.userId
      const remoteStream = evt.stream
      const id = `video-meeting_${remoteStream.userId_}`
      console.log('handleStreamSubscribed', id)
      this.VideoMeetingTRTC.remoteStreams_.push(remoteStream)
      remoteStream.on('player-state-changed', (event) => {
        console.log(`${event.type} player is ${event.state}`)
      })
      remoteStream.play(id, { objectFit: 'cover' })
      if (!remoteStream.hasVideo()) {
        console.log('remoteStream.hasVideo is false')
      }
      console.log('stream-subscribed ID: ', id)
    },
    // fired when the remote stream is removed, e.g. the remote user called Client.unpublish()
    handleStreamRemoved(evt) {
      console.log('handleStreamRemoved', evt)
      const remoteStream = evt.stream
      const id = remoteStream.getId()
      remoteStream.stop()
      this.VideoMeetingTRTC.remoteStreams_ =
        this.VideoMeetingTRTC.remoteStreams_.filter((stream) => {
          return stream.getId() !== id
        })
      // removeView(id)
      console.log(`stream-removed ID: ${id}  type: ${remoteStream.getType()}`)
    },
    muteAudio(evt) {
      let userId = evt.userId
      let userList = this.joinedList.filter((i) => i.userID === userId)
      if (userList.length) {
        userList[0].isAudioOn = false
      }
    },
    unmuteAudio(evt) {
      let userId = evt.userId
      let userList = this.joinedList.filter((i) => i.userID === userId)
      if (userList.length) {
        userList[0].isAudioOn = true
      }
    },
    muteVideo(evt) {
      let userId = evt.userId
      let userList = this.joinedList.filter((i) => i.userID === userId)
      if (userList.length) {
        userList[0].isVideoOn = false
      }
    },
    unmuteVideo(evt) {
      let userId = evt.userId
      let userList = this.joinedList.filter((i) => i.userID === userId)
      if (userList.length) {
        userList[0].isVideoOn = true
      }
    },
    toggleVideo() {
      this.isVideoOn = !this.isVideoOn
      if (this.isVideoOn) {
        // 启用摄像头
        this.VideoMeetingTRTC.unmuteLocalVideo()
      } else {
        // 禁用摄像头
        this.VideoMeetingTRTC.muteLocalVideo()
      }
    },
    toggleAudio() {
      this.isAudioOn = !this.isAudioOn
      if (this.isAudioOn) {
        // 启用麦克风
        this.VideoMeetingTRTC.unmuteLocalAudio()
      } else {
        // 禁用麦克风
        this.VideoMeetingTRTC.muteLocalAudio()
      }
    },
    //获取摄像头列表
    getLiveList(){
      getLiveList().then(( res )=>{
        console.log(res)
        if(res.code === 200){
          this.liveList = res.data
        }
      })
    },
    //屏幕共享
    screenSharing: async function () {
      // await  this.VideoMeetingTRTC.unpublish()
      let options = {
        userId: this.userID,
        userSig: this.userSig,
        sdkAppId: this.sdkAppID,
        roomId: this.videoRoom,
        shareId:`share-${this.userId}`,
      }
      this.shareClient = new ShareClient(options)
      console.log(this.shareClient)
      //
      // // return new Promise(async (resolve, reject) => {
      this.shareClient.join().then((res)=>{
          console.log(res)
          if(res!==undefined){``
            this.isShare = res
            console.log(this.isShare)
          }
      }).catch((err)=>{
          console.log(err)
      })
      //   resolve()
      // })
    },
    //取消屏幕共享
    cancelScreenSharing(){
      if(this.shareClient!==undefined){
        this.shareClient.leave()
        document.getElementById('cancelScreenSharing').style.display='none'
        document.getElementById('screenSharing').style.display='block'
      }
    }
  },
}
</script>
<style lang='scss' scoped>
/deep/.custom-video-meeting {
  width: 80vw;
  height: 80vh;
  max-width: 1280px;
  box-shadow: 0 11px 20px 0 rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  .el-dialog__body {
    padding: 16px 24px;
    flex: 1;
  }
}
.el-dialog {
  .video-meeting-container {
    width: 98%;
    height: 98%;
    display: flex;
    position: relative;
    background: #fff;
    margin:0 auto;
    top:1%;
  }
  .friend-list,
  .video-list {
    // 80vh - el-dialog__header height - el-dialog__body padding
    height: calc(80vh - 86px);
    position: relative;
  }
  .video-list-sec {
    width: 100%;
    height: calc(100% - 52px);
    overflow: hidden;
    position: relative;
  }
  .friend-list {
    width: 200px;
    display: flex;
    flex-direction: column;
  }
  .multi-div {
    margin-top: 8px;
    .el-checkbox {
      margin-right: 10px;
    }
  }
  .video-list {
    flex: 1;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-left: 20px;
    height: 100%;
  }
  .f-list {
    flex: 1;
    overflow: hidden auto;
    .f-list-li {
      width: 100%;
      height: 70px;
      margin-right: 10px;
    }
    .el-checkbox {
      margin-right: 10px;
      vertical-align: middle;
    }
    .el-image {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      vertical-align: middle;
    }
    .f-user-info {
      display: flex;
      flex-direction: column;
      float: right;
      width: 80px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      span {
        line-height: 24px;
      }
    }
  }
  // 视频

  .meeting-video-list {
    height: 100%;
    overflow: hidden auto;
    .meeting-item {
      width: 247px;
      height: 200px;
      overflow: hidden;
      margin: 10px;
      position: relative;
    }
    .meeting-status {
      position: absolute;
      right: 10px;
      bottom: 10px;
      z-index: 2;
      img {
        margin-right: 8px;
      }
    }
    .video-meeting-mask {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: #d8d8d8;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: 1;
    }
    .big {
      position: absolute;
      width: calc(100% - 276px);
      height: calc(100% - 16px);
      z-index: 9999;
      top: 0;
      margin-left: 276px;
      background:#000;
      ::v-deep video {
        width: 100% !important;
        height: 100% !important;
        object-fit: contain !important;
      }
    }
    .small {
      z-index: 99999;
      background:#000;
      ::v-deep video {
        width: 247px !important;
        height: 200px !important;
        object-fit: contain !important;
      }
    }
  }
  .meeting-operator {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    bottom: 10px;
    .el-button {
      width: 120px;
    }
  }
}
/deep/.vcp-volume{
  display: none !important;
}
  .childLive{
    max-height: 90%;
    overflow-y: scroll;
  }
.el-dialog__wrapper{
  pointer-events:none;
  /deep/ .el-dialog{
    pointer-events:auto;
  }
}
  .action-btn{
    margin-left: 10px;
  }
</style>