import { getUserInfo, loginOut ,getSystemConfig} from "@/api";
import { setCookie, removeCookie } from "@/utils/auth"

import { roleTypeTransToRoleName } from "@/settings"
import { removeToken } from "@/utils/auth";
import { Message } from "element-ui"
import store from "..";
const user = {
  namespaced: true,
  state: {
    prePass: "qst@",         //
    firstLogin: true,       //是否为首次登录   false :  非第一登陆  true：第一次登陆
    userId: -1,     //用户id
    account: "",   //用户工号
    realName: "",  //用户真实姓名
    phoneNumber: "",   //电话号码
    avatar: "",    // 用户头像
    dpmId: 0,      //用户职位id
    dpmName: "",   //用户职位名称
    imId: null,
    roles: [],     // 用户角色
    workBenchs: [],    //用户首页操作面板权限
    menuList: [],      //菜单栏
    tenantList: [], //身份归属
    permissions: [],
    keyValue:'',
    // 腾讯IM begin
    currentUserProfile: {},
    isLogin: false,
    isSDKReady: false, // TIM SDK 是否 ready
    userID: 0,
    userSig: '',
    sdkAppID: 0,
    // 腾讯IM end
    logo:''
  },

  mutations: {
    SET_FIRSTLOGIN (state, bStop) {
      state.firstLogin = bStop
    },
    SET_USERID (state, id) {
      state.userId = id;
    },
    SET_ACCOUNT (state, account) {
      state.account = account
    },
    SET_REALNAME (state, realName) {
      state.realName = realName
    },
    SET_PHONENUMBER (state, phone) {
      state.phoneNumber = phone
    },
    SET_AVATAR (state, avatar) {
      state.avatar = avatar
    },
    SET_DPMID (state, dpmId) {
      state.dpmId = dpmId
    },
    SET_DPMNAME (state, dpmName) {
      state.dpmName = dpmName
    },
    SET_WORKBENCHS (state, workBenchs) {
      state.workBenchs = [...workBenchs]
    },
    SET_ROLES (state, roles) {
      state.roles = roles;
    },
    SET_MENU (state, menuList) {
      state.menuList = menuList
    },
    SET_TENANT (state,tenantList) {
      state.tenantList = tenantList
    },
    SET_KEYVALUE(state,keyValue){
      state.keyValue = keyValue
    },
    SET_LOGO(state,keyValue){
      state.logo = keyValue
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions
    },

    // 腾讯IM begin

    updateCurrentUserProfile (state, userProfile) {
      state.currentUserProfile = userProfile
    },
    toggleIsLogin (state, isLogin) {
      state.isLogin = typeof isLogin === 'undefined' ? !state.isLogin : isLogin
    },
    toggleIsSDKReady (state, isSDKReady) {
      state.isSDKReady = typeof isSDKReady === 'undefined' ? !state.isSDKReady : isSDKReady
    },
    reset (state) {
      Object.assign(state, {
        currentUserProfile: {},
        isLogin: false,
        isSDKReady: false // TIM SDK 是否 ready
      })
    },
    GET_USER_INFO (state, payload) {
      state.userID = payload.userID
      state.userSig = payload.userSig
      state.sdkAppID = payload.sdkAppID
    },
    // 腾讯IM end

  },

  actions: {
    /**
     * 根据token获取用户信息
     * @param commit
     * @returns {Promise<unknown>}
     */
    getUserInfo ({ commit }) {
      return new Promise((resolve, reject) => {
        getUserInfo().then(res => {
          let { userId, account, realName, fphoneNo, avatar, dpmId, dpmName, workBenchs, imId, roles, securityPassword, roleMenuList ,roleTenantList,tenantId,perms} = res.data;  ////securityPassword 1：首次登陆  0 ：非首次登陆
          let firstLogin = securityPassword == 0 ? false : true;      //false  ：非首次登陆  true首次登陆
          localStorage.setItem('locale2', tenantId);
          getSystemConfig({keyName:'index_pic_logo'}).then((item)=>{
            if(item.code == 200){
              commit('SET_LOGO',item.data.keyValue)
            }
          })
          commit('SET_PERMISSIONS',perms)
          commit("SET_USERID", userId)
          commit("SET_ACCOUNT", account)
          commit("SET_REALNAME", realName)
          commit("SET_PHONENUMBER", fphoneNo)
          commit("SET_AVATAR", avatar)
          commit("SET_DPMID", dpmId)
          commit("SET_DPMNAME", dpmName)
          commit("SET_WORKBENCHS", workBenchs)
          commit("SET_FIRSTLOGIN", firstLogin)
          commit('SET_MENU', roleMenuList)
          commit('SET_TENANT',roleTenantList)
          //处理角色
          let rolesArr = []
          roles.map(item => {
            rolesArr.push(roleTypeTransToRoleName(item.roleType))
          })
          commit('SET_ROLES', rolesArr)
          // 登录IM
          let userID = imId
          store.dispatch('user/loginIM', userID)
          getSystemConfig({keyName:'pulish_server_web'}).then((item)=>{
            if(item.code == 200){
              commit('SET_KEYVALUE',item.data.keyValue)
            }
          })
          resolve();
        }).catch(err => {
          Message.error(err.msg);
          reject();
        })

      })
    },
    loginOut ({ commit }) {
      return new Promise((resolve, reject) => {
        loginOut().then(res => {
          let { code } = res;
          if (code == 200) {
            commit("SET_USERID", -1)
            commit("SET_ACCOUNT", "")
            commit("SET_REALNAME", "")
            commit("SET_PHONENUMBER", "")
            commit("SET_AVATAR", "")
            commit("SET_DPMNAME", "")
            commit("SET_WORKBENCHS", [])
            removeToken();
            commit("SET_FIRSTLOGIN", true)
            commit('SET_ROLES', [])
            commit('SET_MENU', [])
            commit('SET_TENANT', [])
            commit('SET_KEYVALUE', '')
            commit('SET_LOGO', '')
            commit('SET_PERMISSIONS', [])
            if (store.state.user.isLogin) {
              store.dispatch('user/logout')
            } else {
              store.commit('user/reset')
            }
            resolve();
          } else {
            reject("退出失败！");
          }
        }).catch(err => {
          reject("退出失败！");
        })
      })
    },
    // 腾讯IM begin
    loginIM ({ commit }, userID) {
      if (store.state.user.isLogin) return
      trtcCalling
        .login({
          userID: userID,
          userSig: window.genTestUserSig(userID).userSig,
        })
        .then(() => {
          commit('toggleIsLogin', true)
          commit('GET_USER_INFO', {
            userID: userID,
            userSig: window.genTestUserSig(userID).userSig,
            sdkAppID: window.genTestUserSig(userID).SDKAppID,
          })
          store.commit('startComputeCurrent')
        })
        .catch((error) => {
          store.commit('showMessage', {
            type: 'error',
            message: '当前登录信息已过期',
          })
          setTimeout(function(){
            window.location.href='/login'
          },1000)
        })
    },
    logout (context) {
      // 若有当前会话，在退出登录时已读上报
      if (context.rootState.conversation.currentConversation.conversationID) {
        tim.setMessageRead({ conversationID: context.rootState.conversation.currentConversation.conversationID })
      }
      if (!store.state.user.isSDKReady) {
        return
      }
      tim.logout().then(() => {
        context.commit('toggleIsLogin', false)
        store.commit('stopComputeCurrent')
        context.commit('reset')
      })
    },
    // 腾讯IM end
    toggleIsSDKReady ({ commit }, isSDKReady) {
      commit('toggleIsSDKReady', isSDKReady)
    }
  }
}

export default user
