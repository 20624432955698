// 我的桌面
const Department = () =>
  import( /* webpackChunkName: "department" */ "@/views/Dashboard/Department")
const DepartmentNotice = () =>
  import( /* webpackChunkName: "DepartmentNotice" */ "@/views/Dashboard/DepartmentNotice")
// 实时看板
const TimeBoard = () =>
  import( /* webpackChunkName: "TimeBoard" */ "@/views/Dashboard/TimeBoard")
// 审核任务 - 列表
const CheckTaskBoard = () =>
  import( /* webpackChunkName: "CheckTaskBoard" */ "@/views/Dashboard/CheckTaskBoard")
// 审核任务-待审核
const CheckTaskOfApplying = () =>
  import( /* webpackChunkName: "CheckTaskOfApplying" */ "@/views/Dashboard/CheckTaskBoard/CheckTaskOfApplying")
// 审核任务-已审核
const CheckTaskOfApplyed = () =>
  import( /* webpackChunkName: "CheckTaskOfApplyed" */ "@/views/Dashboard/CheckTaskBoard/CheckTaskOfApplyed")

//审核任务详情
const CheckTaskDetail = () =>
  import( /* webpackChunkName: "CheckTaskDetail" */ "@/views/Dashboard/CheckTaskBoard/CheckTask/CheckTaskDetail")
//审核任务详情 - 审核
const CheckTaskApply = () =>
  import( /* webpackChunkName: "CheckTaskApply" */ "@/views/Dashboard/CheckTaskBoard/CheckTask/CheckTaskApply")
// 上传出差文件
const UploadOfTrip = () =>
  import( /* webpackChunkName: "UploadOfTrip" */ "@/views/Dashboard/Upload/UploadOfTrip")
// // 上传安装文件
// const UploadOfInstall = () =>
//   import( /* webpackChunkName: "UploadOfInstall" */ "@/views/Dashboard/UploadOfInstall")
// //上传调试文件
// const UploadOfDebugger = () =>
//   import( /* webpackChunkName: "UploadOfDebugger" */ "@/views/Dashboard/UploadOfDebugger")
// //上传其他文件
const UploadOfOther = () =>
  import( /* webpackChunkName: "UploadOfOther" */ "@/views/Dashboard/Upload/UploadOfOther")
// 数据填充【合格证填报】
const PaddingData = () =>
  import( /* webpackChunkName: "PaddingData" */ "@/views/Dashboard/PaddingData")
// 合格证搜索
const ConformityList = () =>
  import( /* webpackChunkName: "ConformityList" */ "@/views/Dashboard/PaddingData/ConformityList")
const RemoteMonitoring = () =>
    import(/* webpackChunkName: "RemoteMonitoring" */"@/views/Dashboard/RemoteMonitoring")

// 报销审核
const BxshListOfAlready = () =>
  import( /* webpackChunkName: "BxshListOfAlready" */ "@/views/Dashboard/BxshList/BxshListOfAlready")
const BxshListOfUn = () =>
  import( /* webpackChunkName: "BxshListOfUn" */ "@/views/Dashboard/BxshList/BxshListOfUn")
const Transaction = () =>
    import( /* webpackChunkName: "BxshListOfUn" */ "@/views/Dashboard/Transaction")
export default [
  {
    path: 'department',
    name: 'Department',
    meta: { title: '我的桌面', affix: true, noCache: true },
    component: Department
  },
  {
    path: "time-board",
    name: "TimeBoard",
    component: TimeBoard,
    meta: { title: '实时看板' },
  },
  {
    path: "check-list",
    name: "CheckTaskBoard",
    meta: { title: '审核任务', noCache: true },
    component: CheckTaskBoard,
  },
  // 待审核
  {
    path: "check-list/applying",
    name: "CheckTaskOfApplying",
    meta: { title: '审核任务-待审核', noCache: true },
    component: CheckTaskOfApplying,
  },
  // 已审核
  {
    path: "check-list/applyed",
    name: "CheckTaskOfApplyed",
    meta: { title: '审核任务-已审核', noCache: true },
    component: CheckTaskOfApplyed,
  },
  //详情
  {
    path: 'check-des/:id',
    name: 'CheckTaskDetail',
    meta: { title: '审核任务-详情' },
    component: CheckTaskDetail
  },
  //审核
  {
    path: 'check-apply/:id',
    name: 'CheckTaskApply',
    meta: { title: '审核任务-审核' },
    component: CheckTaskApply
  },
  {
    path: 'upload-trip',
    name: "UploadOfTrip",
    component: UploadOfTrip,
    meta: { title: '上传出差资料' },
  },
  {
    path: 'upload-other',
    name: "UploadOfOther",
    component: UploadOfOther,
    meta: { title: '上传其他资料' },
  },
  {
    path: 'pad-data',
    name: 'PaddingData',
    meta: { title: '填报数据' },
    component: PaddingData
  },
  {
    path: 'conform-list',
    name: 'ConformityList',
    meta: { title: '合格证搜索', noCache: true },
    component: ConformityList
  },
  {
    path: 'notices',
    name: 'DepartmentNotice',
    meta: { title: '公告列表' },
    component: DepartmentNotice
  },
  {
    path: 'bxsh-un',
    name: 'BxshListOfUn',
    meta: { title: "报销审核-未报销" },
    component: BxshListOfUn
  },
  {
    path: 'bxsh-al',
    name: 'BxshListOfAlready',
    meta: { title: "报销审核-已报销" },
    component: BxshListOfAlready
  },
  {
    path: 'transaction',
    name: 'Transaction',
    meta: { title: "事务报备记录" },
    component: Transaction
  },
  {
    path: 'remote-monitoring',
    name: 'RemoteMonitoring',
    meta: { title: '远程监控' },
    component: RemoteMonitoring
  },

]