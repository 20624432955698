const HTCSWebAccount = () =>
  import( /* webpackChunkName: "HTCSWebAccount" */ "@/views/HTCSWeb/HTCSWebAccount")

const HTCSWebAccountOfAdd = () =>
  import( /* webpackChunkName: "HTCSWebAccountOfAdd" */ "@/views/HTCSWeb/HTCSWebAccount/HTCSWebAccountOfAdd")
const HTCSWebAccountOfEdit = () =>
  import( /* webpackChunkName: "HTCSWebAccountOfEdit" */ "@/views/HTCSWeb/HTCSWebAccount/HTCSWebAccountOfEdit")

export default [
  {
    path: "list",
    name: "HTCSWebAccount",
    component: HTCSWebAccount,
    meta: { title: "账户管理" }
  },
  {
    path: "add",
    name: "HTCSWebAccountOfAdd",
    component: HTCSWebAccountOfAdd,
    meta: { title: "新增账户" }
  },
  {
    path: "edit/:id/:tab",
    name: "HTCSWebAccountOfEdit",
    component: HTCSWebAccountOfEdit,
    meta: { title: "修改账户" }
  },

]