const SystemDict = () =>
  import(/* webpackChunkName: "SystemDict" */ "@/views/SystemManage/dict");
const SystemDictDetail = () =>
  import(
    /* webpackChunkName: "SystemDictDetail" */ "@/views/SystemManage/dict/data"
  );
export default [
  {
    path: "dict/list",
    name: "SystemDict",
    meta: { title: "字段管理" },
    component: SystemDict,
  },
  {
    path: "dict/detail/:dictId",
    name: "SystemDictDetail",
    component: SystemDictDetail,
    meta: { title: "字典详情" },
  },
];
