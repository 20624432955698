<template>
    <el-dialog
            :title="catalogName+'签名'"
            :visible.sync="visible"
            width="860px"
            :append-to-body="true"
            :close-on-click-modal = "false">
        <table width="800" border="1" align="center" cellpadding="5" cellspacing="0">
            <caption>
                <span style="font-size: 20px;">杭州汽轮机股份有限公司</span><br/><span style="font-size: 24px;">设备开箱交付清点记录</span>
            </caption>
            <tr height="50">
                <td class="text-allgn">设备名称</td>
                <td class="text-allgn">{{receiptData.deviceName}}</td>
                <td class="text-allgn">用户单位</td>
                <td class="text-allgn">{{receiptData.userCompany}}</td>
            </tr>
            <tr height="50">
                <td class="text-allgn">产品代号</td>
                <td class="text-allgn">{{receiptData.productCode}}</td>
                <td class="text-allgn">供货单位</td>
                <td class="text-allgn">{{receiptData.supplyCompany}}</td>
            </tr>
            <tr height="50">
                <td class="text-allgn">产品型号</td>
                <td class="text-allgn">{{receiptData.productType}}</td>
                <td class="text-allgn">开箱日期</td>
                <td class="text-allgn">{{receiptData.openBeginDate}}-{{receiptData.openEndDate}}</td>
            </tr>
            <tr height="80">
                <td colspan="4">
                    <div style="display: flex;">
                        <div style="margin:0 10px;width:105px;">&nbsp;验收清点清空:&nbsp;</div>
                        <div>
                            <el-checkbox-group v-model="checkTypeArray">
                                <div><el-checkbox v-for="(item,key) in receiptData.checkTypeArray" :label="item.key" :key="'receiptData_'+key">{{item.value}}</el-checkbox></div>
                            </el-checkbox-group>
                        </div>
                    </div>
                </td>
            </tr>
            <tr height="50">
                <td colspan="4">
                    <div style="display: flex;">
                        <div style="margin:0 10px;">&nbsp;缺陷类型:&nbsp;</div>
                        <div>
                            <el-checkbox-group v-model="checkList">
                                <el-checkbox v-for="(item,key) in receiptData.checkedTypeList" :label="item.checkedType" :checked="item.checked===1?true:false" disabled :key="'checkedTypeStr_'+key">{{item.checkedTypeStr}}</el-checkbox>
                            </el-checkbox-group>
                        </div>
                    </div>
                </td>
            </tr>
            <tr v-for="(item,key) in receiptData.ohterInfo" :key="'caseName_'+key">
                <td colspan="4">
                    <div style="margin-top:20px;margin-bottom: 20px;margin-left:10px;" >
                        {{item.checkedTypeStr}}({{item.catalogName}}):{{item.caseNo}}({{item.caseName}})，{{item.checkedDesc}}
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="4">
                    <div style="margin-top:20px;margin-bottom: 20px;display: flex;">
                        <div>备注：</div>
                        <div style="flex: 1">
                            <el-input
                                    type="textarea"
                                    :rows="3"
                                    style="width: 80%;"
                                    placeholder="请输入内容"
                                    v-model="receiptData.openRemark">
                            </el-input>
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td colspan="2">
                    <div style="margin-top:20px;margin-bottom: 20px;">用户单位：
                        <div style="border: 1px solid #ccc;width: 400px;height:300px;background:rgba(204,204,204,0.2);text-align: center;" v-if="!huizhiJiaSign && jiaUserNameFileUrl==''">
                            <el-button  type="primary" style="margin-top:124px;"  @click="signJiaClick">点击签名</el-button>
                        </div>
                        <div style="width:400px;height:300px;border-right: 1px solid #ccc;text-align: center;line-height: 300px;display: flex;justify-content: center;align-items: center;" v-if="jiaUserNameFileUrl!==''">
                            <img :src="jiaUserNameFileUrl" style="display: block;margin: 0 auto;" alt="">
                        </div>
                        <vue-esign v-if="huizhiJiaSign" style="border: 1px solid #ccc;" ref="receipJiasign" :width="400" :height="300" :isCrop="isCrop" :lineWidth="lineWidth" :lineColor="lineColor"  :bgColor.sync="bgColor" />
                    </div>
                </td>
                <td colspan="2">
                    <div style="margin-top:20px;margin-bottom: 20px;">
                        杭州汽轮机股份有限公司
                        <div style="border: 1px solid #ccc;width: 400px;height:300px;background:rgba(204,204,204,0.2);text-align: center;" v-if="!huizhiYiSign && yiUserNameFileUrl==''">
                            <el-button  type="primary" style="margin-top:124px;"  @click="signYiClick">点击签名</el-button>
                        </div>
                        <div style="width:400px;height:300px;text-align: center;line-height: 300px;border-left: 1px solid #ccc;display: flex;justify-content: center;align-items: center;" v-if="yiUserNameFileUrl!==''">
                            <img :src="yiUserNameFileUrl" style="display: block;margin: 0 auto;" alt="">
                        </div>
                        <vue-esign v-if="huizhiYiSign" style="border: 1px solid #ccc;" ref="receipYisign" :width="400" :height="300" :isCrop="isCrop" :lineWidth="lineWidth" :lineColor="lineColor"  :bgColor.sync="bgColor" />
                    </div>
                </td>
            </tr>
        </table>
        <span slot="footer" class="dialog-footer">
                    <el-button @click="closeDialogReceipt">取 消</el-button>
                    <el-button type="primary" @click="submitReceipt">提 交</el-button>
                </span>
    </el-dialog>
</template>

<script>
    import {  getPackingInfoByHuizhi , getPackingSignature , savePackingList} from '@/api/open-box'
    export default {
        name: "Receipt",
        props:{
            visible: {
                type: Boolean,
                default: false,
            },
            options: {
                type: Object,
                required: true,
            },
        },
        watch: {
            visible: {
                handler(val) {
                    if (val) {
                        this.receipt()
                    }
                },
                immediate: true,
            },
        },
        data(){
            return {
                catalogName:'',
                receiptData:{},
                checkTypeArray:[],
                checkList:[],
                huizhiJiaSign:false,
                huizhiYiSign:false,
                lineWidth: 6,
                lineColor: '#000000',
                bgColor: '',
                isCrop: true,
                resultImg: '',
                jiaUserNameFileUrl:'',
                yiUserNameFileUrl:''
            }
        },
        methods:{
            signJiaClick(){
                this.huizhiJiaSign = true
            },
            signYiClick(){
                this.huizhiYiSign = true
            },
            closeDialogReceipt(){
                if(this.$refs.receipJiasign!==undefined){
                    this.$refs.receipJiasign.reset()
                }
                if(this.$refs.receipYisign!==undefined){
                    this.$refs.receipYisign.reset()
                }
                this.$emit('closeDialog', 'dialogOfSign')
            },
            submitReceipt(){
                let data1 = {}
                data1.signatureType = 'packing'
                data1.signatureTypeId = this.options.signatureTypeId
                if(this.huizhiJiaSign){
                    this.$refs.receipJiasign.generate().then(res => {
                        console.log(res)
                        this.resultImg = res
                    })
                }
                if(this.huizhiYiSign){
                    this.$refs.receipYisign.generate().then(res => {
                        console.log(res)
                        this.resultImg = res
                    })
                }
                this.$nextTick(()=>{
                    let data = {
                        openRemark:this.receiptData.openRemark,
                        packId:this.options.signatureTypeId,
                    }
                    if(this.jiaUserNameFileUrl!==''){

                    }else{
                        data.jiaUserNameBase64File = this.resultImg
                    }
                    if(this.yiUserNameFileUrl !== ''){

                    }else{
                        data.yiUserNameBase64File = this.resultImg
                    }
                    if(this.resultImg === ''){
                        this.$Message.warning('请先签字')
                    }else{
                        savePackingList(data).then((res)=>{
                            if(res.code === 200){
                                this.huizhiJiaSign = false
                                this.huizhiYiSign = false
                                this.$emit('closeDialog', 'dialogOfReceipt')
                                this.$Message.success('签名成功')
                            }
                        }).catch((err)=>{
                            this.huizhiJiaSign = false
                            this.huizhiYiSign = false
                            this.$emit('closeDialog', 'dialogOfReceipt')
                        })
                    }
                })

            },
            //生成回执单
            receipt(){
                let data = {
                    signatureType:'packing',
                    signatureTypeId:this.options.signatureTypeId
                }
                this.getPackingSignature(data)
                this.$store.dispatch('loading/setLayoutLoading', true)
                getPackingInfoByHuizhi({packId:this.options.signatureTypeId}).then((res)=>{
                    if(res.code === 200){
                        this.receiptData = res.data
                        this.$store.dispatch('loading/setLayoutLoading', false)
                        this.dialogVisibleReceipt = true
                    }
                }).catch((err)=>{
                    this.$Message.error(res.msg)
                    this.$store.dispatch('loading/setLayoutLoading', false)
                })
            },
            //查看签名
            getPackingSignature(){
                getPackingSignature({signatureType:this.options.signatureType,signatureTypeId:this.options.signatureTypeId}).then((res)=>{
                    if(res.code === 200){
                        this.jiaUserNameFileUrl = res.data.jiaUserNameFileUrl
                        this.yiUserNameFileUrl = res.data.yiUserNameFileUrl
                    }
                })
            },
        }
    }
</script>

<style scoped>

</style>