import "babel-polyfill";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import $ from "jquery";
window.jQuery = $;
window.$ = $;
import { MainTop, Nodata, MyPagination, ScrollPane } from "./components";
import { handleTree ,addDateRange} from "@/utils/index";
// IM begin
import trtcCalling from "./trtc-calling"; //实例
import TRTCCalling from "trtc-calling-js";

import Avatar from "@/components/Chat/avatar.vue";
import tim from "@/tim";
import TIM from "tim-js-sdk";
import "./assets/icon/iconfont.css";
import "./assets/icon/tim.css";
import "@/styles/ruoyi.scss";

import timOfTWebLive from "./timOfTWebLive";
import TWebLive from "tweblive";
// IM end
import plugins from '@/plugins'

import {
  Pagination,
  Dialog,
  Autocomplete,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Menu,
  Submenu,
  MenuItem,
  Input,
  Radio,
  RadioGroup,
  RadioButton,
  Checkbox,
  CheckboxGroup,
  Switch,
  Select,
  Option,
  Button,
  Table,
  TableColumn,
  DatePicker,
  TimeSelect,
  TimePicker,
  Tooltip,
  Breadcrumb,
  BreadcrumbItem,
  Form,
  FormItem,
  Tabs,
  TabPane,
  Tag,
  Slider,
  Upload,
  Progress,
  Badge,
  Carousel,
  CarouselItem,
  Collapse,
  CollapseItem,
  Cascader,
  Timeline,
  TimelineItem,
  Link,
  Divider,
  Image,
  Calendar,
  Backtop,
  PageHeader,
  Loading,
  Message,
  Container,
  Main,
  Col,
  InputNumber,
  MessageBox,
  Card,
  Scrollbar,
  Header,
  Row,
  Steps,
  Step,
  Rate,
  Transfer,
  Drawer,
  Popover,
  tree,
} from "element-ui";
import "mint-ui/lib/style.css";
import "@/permission";
import "@/styles/index.scss";
import "@/styles/common.scss";
import "@/styles/reset.scss";
import "@/styles/element.scss";
import "@/directives";
import "@/filters";
import lodash from "lodash";
import Base64 from "js-base64";
// 字典数据组件
import DictData from '@/components/DictData'

Vue.prototype.$_ = lodash;
Vue.prototype.$axios = axios;
Vue.config.productionTip = false;
Vue.prototype.handleTree = handleTree;
Vue.prototype.addDateRange = addDateRange

import contentmenu from "v-contextmenu";
import "v-contextmenu/dist/index.css";
import VueCropper from "vue-cropper";
import vueEsign from "vue-esign";

Vue.use(vueEsign);
Vue.use(VueCropper);
Vue.use(contentmenu);

Vue.use(plugins)
Vue.use(MainTop());
Vue.use(Nodata());
Vue.use(MyPagination());
Vue.use(ScrollPane());
Vue.prototype.$ELEMENT = { size: "small", zIndex: 9999 };
Vue.use(Pagination);
Vue.use(Dialog);
Vue.use(Autocomplete);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItem);
Vue.use(Input);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(RadioButton);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Switch);
Vue.use(Select);
Vue.use(Option);
Vue.use(Button);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(DatePicker);
Vue.use(TimeSelect);
Vue.use(TimePicker);
Vue.use(Tooltip);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Tag);
Vue.use(Slider);
Vue.use(Upload);
Vue.use(Progress);
Vue.use(Badge);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Cascader);
Vue.use(Timeline);
Vue.use(TimelineItem);
Vue.use(Link);
Vue.use(Divider);
Vue.use(Image);
Vue.use(Calendar);
Vue.use(Backtop);
Vue.use(PageHeader);
Vue.use(Container);
Vue.use(Header);
Vue.use(Main);
Vue.use(Col);
Vue.use(Row);
Vue.use(InputNumber);
Vue.use(Card);
Vue.use(Scrollbar);
Vue.use(Steps);
Vue.use(Step);
Vue.use(Rate);
Vue.use(Transfer);
Vue.use(Drawer);
Vue.use(Popover);
Vue.use(tree);
Vue.use(Loading.directive);
Vue.use(Base64);
Vue.prototype.$Message = Message;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$alert = MessageBox.alert;

DictData.install()
// IM begin
window.tim = tim;
window.TIM = TIM;
window.store = store;
Vue.prototype.$bus = new Vue(); // event Bus 用于无关系组件间的通信。
Vue.prototype.tim = tim;
Vue.prototype.TIM = TIM;

timOfTWebLive._tim = tim;
Vue.prototype.timOfTWebLive = timOfTWebLive;

Vue.prototype.TWebLive = TWebLive;
Vue.prototype.$trtcCalling = trtcCalling;
Vue.prototype.TrtcCalling = TRTCCalling;

window.tim = tim;
window.TIM = TIM;
window.trtcCalling = trtcCalling;
window.TRTCCalling = TRTCCalling;
window.store = store;

Vue.prototype.$store = store;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.component("avatar", Avatar);
// IM end
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
