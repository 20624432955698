import Cookies from 'js-cookie'

const TokenKey = 'hql-token'

export const getToken = () => {
    return Cookies.get(TokenKey)
}

export const setToken = (token) => {
    return Cookies.set(TokenKey, token)
}

export const removeToken = () => {
    return Cookies.remove(TokenKey)
}

export const getCookie = (key, options) => {
    return Cookies.get(key, options);
}

export const setCookie = (key, value, options) => {
    return Cookies.set(key, value, options);
}

export const removeCookie = (key, options) => {
    return Cookies.remove(key, options)
}
