const video = {
    namespaced: true,
    state: {
        videoRoom: 0,
        // trtcCalling相关
        callComponentType: '',  // 通话类型 ： video  voice  meeting  live
        callStatus: 'idle', // 状态, idle : 挂断, calling ： 被邀请, connected ： 已连接
        isInviter: false, // 当前用户是否为邀请方  false:被邀请方  true：邀请方
        isFromGroup: false, //群聊 ：true、单聊：false
        // 
        meetingUserList: [],     //邀请进入到房间的用户列表
        muteVideoUserIdList: [], // 关闭摄像头的用户
        muteAudioUserIdList: [], //关闭麦克风的用户
        inviteeListByReject: [],//作为邀请者邀请的人员列表。群聊拒绝接听时使用
    },
    mutations: {
        GENERATE_VIDEO_ROOM (state, videoRoom) {
            if (videoRoom) {
                state.videoRoom = videoRoom
            } else {
                state.videoRoom = Math.ceil(Math.random() * (2 ** 31 - 1))
            }
        },
        // TRTCCalling
        updateCallComponentType (state, type) {
            state.callComponentType = type
        },
        updateCallStatus (state, callStatus) {
            state.callStatus = callStatus;
        },
        updateIsInviter (state, isInviter) {
            state.isInviter = isInviter;
        },
        userJoinMeeting (state, userProfile) {
            console.log('video userJoinMeeting', userProfile);
            if (state.meetingUserList.length === 0) {
                state.meetingUserList.push(userProfile)
            } else {
                if (state.meetingUserList.some(i => i.userID !== userProfile.userID)) {
                    state.meetingUserList.push(userProfile)
                }
                let hash = {}
                state.meetingUserList=state.meetingUserList.reduce( (preVal,curVal) => {
                    hash[curVal.userID] ? '' : hash[curVal.userID] = preVal.push(curVal);
                    return preVal
                }, [])
            }


        },
        userLeaveMeeting (state, userId) {
            for (let i = 0; i < state.meetingUserList.length; i++) {
                let { userID } = state.meetingUserList[i]
                if (userID === userId) {
                    state.meetingUserList.splice(i, 1)
                    break;
                }
            }
        },
        dissolveMeeting (state) {
            state.meetingUserList = []
        },
        updateMuteVideoUserIdList (state, userIdList) {
            state.muteVideoUserIdList = userIdList;
        },
        updateMuteAudioUserIdList (state, userIdList) {
            state.muteAudioUserIdList = userIdList;
        },
        updateGroupChat (state, isFromGroup) {
            state.isFromGroup = isFromGroup
        },
        updateInviteeListByReject (state, list) {
            state.inviteeListByReject = [...list]
        }
    },
    actions: {
        GENERATE_VIDEO_ROOM ({ commit }, videoRoom) {
            commit('GENERATE_VIDEO_ROOM', videoRoom)
        },
        updateCallComponentType ({ commit }, type) {
            commit('updateCallComponentType', type)
        },
        updateCallStatus ({ commit }, callStatus) {
            commit('updateCallStatus', callStatus)
        },
        updateIsInviter ({ commit }, isInviter) {
            commit('updateIsInviter', isInviter)
        },
        userJoinMeeting ({ commit }, userProfile) {
            commit('userJoinMeeting', userProfile)
        },
        userLeaveMeeting ({ commit }, userId) {
            commit('userLeaveMeeting', userId)
        },
        dissolveMeeting ({ commit }) {
            commit('dissolveMeeting')
        },
        updateMuteVideoUserIdList ({ commit }, userIdList) {
            commit('updateMuteVideoUserIdList', userIdList)
        },
        updateMuteAudioUserIdList ({ commit }, userIdList) {
            commit('updateMuteAudioUserIdList', userIdList)
        },
        updateGroupChat ({ commit }, isFromGroup) {
            commit('updateGroupChat', isFromGroup)
        },
        updateInviteeListByReject ({ commit }, list) {
            commit('updateInviteeListByReject', list)
        }
    }
}

export default video