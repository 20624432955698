const HTCSWebExperts = () =>
  import( /* webpackChunkName: "HTCSWebExperts" */ "@/views/HTCSWeb/HTCSWebExperts")
const HTCSWebExpertsOfAdd = () =>
  import( /* webpackChunkName: "HTCSWebExpertsOfAdd" */ "@/views/HTCSWeb/HTCSWebExperts/HTCSWebExpertsOfAdd")

const HTCSWebExpertsOfEdit = () =>
  import( /* webpackChunkName: "HTCSWebExpertsOfEdit" */ "@/views/HTCSWeb/HTCSWebExperts/HTCSWebExpertsOfEdit")
export default [
  {
    path: "list",
    name: "HTCSWebExperts",
    component: HTCSWebExperts,
    meta: { title: "专家管理" }
  },
  {
    path: "add",
    name: "HTCSWebExpertsOfAdd",
    component: HTCSWebExpertsOfAdd,
    meta: { title: "新增专家" }
  },
  {
    path: "edit/:id",
    name: "HTCSWebExpertsOfEdit",
    component: HTCSWebExpertsOfEdit,
    meta: { title: "修改专家" }
  },
]