<template>
    <div id="canvasVue" class="pad-box">
        <div class="paint-type">
            <div class="paint" @click="paint"></div>
            <div class="move" @click="move"></div>
            <div class="eraser" @click="eraser"></div>
            <div class="withdraw" @click="withdraw"></div>
            <div class="clean" @click="clean"></div>
            <div class="enlarge" @click="enlarge"></div>
            <div class="narrow" @click="narrow"></div>
        </div>
        <div class="paint-move">
            <div class="moveTop" @click="moveTop"></div>
            <div class="flex">
                <div class="moveLeft" @click="moveLeft"></div>
                <div class="restore" @click="restore"></div>
                <div class="moveRight" @click="moveRight"></div>
            </div>
            <div class="flex">
                <div class="moveBottom" @click="moveBottom"></div>
            </div>
        </div>
        <div class="paint-color">
            <div class="red" @click="red"></div>
            <div class="blue" @click="blue"></div>
            <div class="green" @click="green"></div>
            <div class="line"></div>
            <div class="small" @click="small"></div>
            <div class="middle" @click="middle"></div>
            <div class="big" @click="big"></div>
        </div>
        <div class="paint-foot">
            <input
                    id="file"
                    type="file"
                    accept="image/png,image/jpg,image/jpeg"
                    style="display: none"
            />
            <div class="addImg" @click="addImg"></div>
            <div class="mute" @click="mute"></div>
            <div class="send cancle" @click="cancleSend"></div>
            <div class="send confirm" @click="confirmSend"></div>
            <div style="margin-right: 20px"> <el-button type="primary" @click="exitMeeting" round>退出白板</el-button></div>
        </div>
        <div class="pad-canvas">
            <canvas id="canvas" class="canvasPaint" ref="padCanvas"></canvas>
            <canvas id="canvasImage" class="canvasImage"></canvas>
            <video class="videoLocal" autoplay playsinline muted ref="rv_local" />
        </div>
    </div>
</template>
<script>
    import {mapState} from "vuex";
    import {uploadFileList,listWhieBoardHistoryMsg,cleanWhieBoardHistoryMsg} from '@/api/index'
    export default {
        name: 'index',
        data() {
            return{
                boardMsg: "", //白板消息
                imgData: "",
                openAudio: true, //是否开启语言
                fromId: "",
                type: "",
                paintWidth: "",
                paintType: "",
                paintColor: "",
                oldX: "",
                oldY: "",
                offsetX: 0,
                offsetY: 0,
                scale: 1,
                imageScale: 1, //图片缩放比例
                imageWidth: null,
                imageHeight: null,
                imageX: null,
                imageY: null,
                imageUrl: null,
                cursorstyle: "default",
                rv_local: {
                    nickName: "",
                    streamId: "",
                    openAudio: true,
                    openVideo: false
                },
                socket:null,
                withdrawShow:true
            }
        },
        computed: {
            ...mapState({
                userID: (state) => state.user.userID,
            }),
        },
        props:{
            options:{
                type:Object,
                default:{
                    roomId:0
                }
            }
        },
        methods: {
            red() {
                this.paintType = 0;
                this.paintColor = "red";
                this.cursorstyle = "default";
                $("#canvas").css("cursor", "url(../../../../src/assets/images/pencil.png),default");
                console.log("画笔变红色");
            },
            green() {
                this.paintType = 0;
                this.paintColor = "green";
                this.cursorstyle = "default";
                $("#canvas").css("cursor", "url(../../../../src/assets/images/pencil.png),default");
                console.log("画笔变绿色");
            },
            blue() {
                this.paintType = 0;
                this.paintColor = "blue";
                this.cursorstyle = "default";
                $("#canvas").css("cursor", "url(../../../../src/assets/images/pencil.png),default");
                console.log("画笔变蓝色");
            },
            paint() {
                this.withdrawShow = true
                console.log("切换画笔");
                this.paintType = 0;
                this.cursorstyle = "default";
                $("#canvas").css("cursor", "url(../../../../src/assets/images/pencil.png),default");
                console.log(this.cursorstyle);
            },
            withdraw() {
                var _this = this;
                _this.withdrawShow = false
                let timestamp = Date.parse(new Date());
                let boardMsg = {
                    color: 0,
                    time: timestamp,
                    type: 3,
                    width: 0.0,
                    offsetX: 0.0,
                    offsetY: 0.0,
                    picType: 0,
                    scale: 0.0
                };
                let messages = {
                    namespace: _this.options.roomId,
                    from:_this.userID,
                    type: 'whiteboard',
                    message: JSON.stringify(boardMsg),
                };
                // let id = this.fromId;
                // let chatType = this.type;
                console.log('撤销')
                _this.socket.send(JSON.stringify(messages))
                // window.parent.sendCustomMsg(id, messages, chatType);
                if (
                    localStorage.getItem("whiteboardMsgs_" + this.options.roomId).length !== 0
                ) {
                    var whiteboardMsgs = JSON.parse(
                        localStorage.getItem("whiteboardMsgs_" + this.options.roomId)
                    );
                } else {
                    var whiteboardMsgs = [];
                }
                console.log(typeof whiteboardMsgs)
                whiteboardMsgs.pop();
                console.log(whiteboardMsgs)
                localStorage.setItem(
                    "whiteboardMsgs_" + this.options.roomId,
                    JSON.stringify(whiteboardMsgs)
                );
                console.log("撤回消息");
                this.drawRepeat();
            },
            eraser() {
                this.withdrawShow = true
                this.paintType = 1;
                this.cursorstyle = "default";
                $("#canvas").css("cursor", "url(../../../../src/assets/images/erase.png),default");
                console.log("橡皮擦");
            },
            clean() {
                var _this = this;
                let timestamp = Date.parse(new Date());
                let boardMsg = {
                    color: 0,
                    time: timestamp,
                    type: 2,
                    width: 0.0,
                    offsetX: 0.0,
                    offsetY: 0.0,
                    picType: 0,
                    scale: 0.0
                };
                let messages = {
                    namespace:_this.options.roomId,
                    from:_this.userID,
                    type: 'whiteboard',
                    message: JSON.stringify(boardMsg),
                };
                let id = this.fromId;
                let chatType = this.type;
                cleanWhieBoardHistoryMsg({namespace:_this.options.roomId}).then((res)=>{
                    console.log(res)
                })
                _this.socket.send(JSON.stringify(messages))
                // window.parent.sendCustomMsg(id, messages, chatType);
                this.offsetX = 0;
                this.offsetY = 0;
                this.scale = 1;
                let whiteboardMsgs = [];
                localStorage.setItem("whiteboardMsgs_" + this.options.roomId, whiteboardMsgs);
                this.drawRepeat();
            },
            small() {
                this.paintType = 0;
                this.paintWidth = 3;
                this.cursorstyle = "default";
                $("#canvas").css("cursor", "url(../../../../src/assets/images/pencil.png),default");
                console.log("小号画笔");
            },
            middle() {
                this.paintType = 0;
                this.paintWidth = 5;
                this.cursorstyle = "default";
                $("#canvas").css("cursor", "url(../../../../src/assets/images/pencil.png),default");
                console.log("中号画笔");
            },
            big() {
                this.paintType = 0;
                this.paintWidth = 8;
                this.cursorstyle = "default";
                $("#canvas").css("cursor", "url(../../../../src/assets/images/pencil.png),default");
                console.log("大号画笔");
            },
            enlarge() {
                if (this.scale >= 10) {
                    _this.$Message.warning("已经最大了");
                    return false;
                }
                this.scale = this.accAdd(this.scale, 0.1);
                this.drawRepeat();
                console.log("放大", this.scale);
            },
            narrow() {
                if (this.scale <= 0.5) {
                    _this.$Message.warning("不能再小了");
                    return false;
                }
                this.scale = this.Subtr(this.scale, 0.1);
                this.drawRepeat();
                console.log("缩小", this.scale);
            },
            restore() {
                this.offsetX = 0;
                this.offsetY = 0;
                this.scale = 1;
                this.drawRepeat();
            },
            moveTop() {
                this.offsetY = this.offsetY - 100;
                this.drawRepeat();
                console.log(this.offsetX, this.offsetY);
                console.log("上移动");
            },
            moveLeft() {
                this.offsetX = this.offsetX - 100;
                this.drawRepeat();
                console.log(this.offsetX, this.offsetY);
                console.log("左移动");
            },
            moveBottom() {
                this.offsetY = this.offsetY + 100;
                this.drawRepeat();
                console.log(this.offsetX, this.offsetY);
                console.log("下移动");
            },
            moveRight() {
                this.offsetX = this.offsetX + 100;
                this.drawRepeat();
                console.log(this.offsetX, this.offsetY);
                console.log("右移动");
            },
            //取消发送图片消息
            cancleSend() {
                var _this = this;
                _this.$Message.warning("取消发送");
                _this.cursorstyle = "default";
                $("#canvasImage").css("cursor", "url(../../../../src/assets/images/pencil.png),default");
                $(".send").css("display", "none");
                $("#canvasImage").css("display", "none");
                _this.drawFunction();
                _this.drawRepeat();
            },
            //确认发送图片消息
            confirmSend() {
                var _this = this;
                //发送图片消息
                let timestamp = Date.parse(new Date());
                let boardMsg = {
                    color: 0,
                    time: timestamp,
                    type: 4,
                    width: 0,
                    offsetX: _this.offsetX,
                    offsetY: _this.offsetY,
                    picType: 1,
                    scale: _this.scale,
                    pic: {
                        height: (_this.imageHeight * _this.imageScale) / _this.scale,
                        width: (_this.imageWidth * _this.imageScale) / _this.scale,
                        imgScale: 1,
                        url: _this.imageUrl,
                        progcess: 0,
                        x: _this.imageX,
                        y: _this.imageY
                    }
                };
                let messages = {
                    namespace:_this.options.roomId,
                    from:_this.userID,
                    type: 'whiteboard',
                    message: JSON.stringify(boardMsg),
                };
                // let id = _this.fromId;
                // let chatType = _this.type;
                _this.socket.send(JSON.stringify(messages))
                // window.parent.sendCustomMsg(id, messages, chatType);
                if (
                    localStorage.getItem("whiteboardMsgs_" + _this.options.roomId).length !== 0
                ) {
                    var whiteboardMsgs = JSON.parse(
                        localStorage.getItem("whiteboardMsgs_" + _this.options.roomId)
                    );
                } else {
                    var whiteboardMsgs = [];
                }
                whiteboardMsgs.push(boardMsg);
                console.log(whiteboardMsgs);
                localStorage.setItem(
                    "whiteboardMsgs_" + _this.options.roomId,
                    JSON.stringify(whiteboardMsgs)
                );
                console.log("图片消息");
                _this.$Message.warning("发送成功");
                _this.imageScale = 1;
                _this.cursorstyle = "default";
                $("#canvasImage").css("cursor", "url(../../../../src/assets/images/pencil.png),default");
                $(".send").css("display", "none");
                $("#canvasImage").css("display", "none");
                _this.drawFunction();
                _this.drawRepeat();
            },
            //拖拽缩放
            imagedrag(image, imagewidth, imageheight) {
                var _this = this;
                _this.cursorstyle = "image";
                $("#canvasImage").css("cursor", "url(../../../../src/assets/images/hand.png),default");
                $(".send").css("display", "inherit");
                $("#canvasImage").css("display", "inherit");
                $("#canvasImage").attr("width", $(window).get(0).innerWidth);
                $("#canvasImage").attr("height", $(window).get(0).innerHeight);
                var c = document.getElementById("canvasImage");
                var ctx = c.getContext("2d");
                // console.log(_this.offsetX, _this.offsetY);
                var imagex = 0,
                    imagey = 0,
                    imageScale = _this.imageScale,
                    imageheight = _this.accMul(imageheight, imageScale),
                    imagewidth = _this.accMul(imagewidth, imageScale); //绘制图形的参数
                _this.imageX = imagex;
                _this.imageY = imagey;
                var timeOutEvent = 0; //区分拖拽和点击的参数
                //创建图形
                ctx.beginPath();
                console.log(image.complete);
                if (image.complete) {
                    ctx.drawImage(image, imagex, imagey, imagewidth, imageheight);
                } else {
                    setTimeout(() => {
                        _this.imagedrag(image, imagewidth, imageheight);
                    }, 1000);
                }
                ctx.closePath();
                if (_this.cursorstyle == "image") {
                    c.onmousedown = function(ev) {
                        var e = ev || event;
                        var x = e.offsetX;
                        var y = e.offsetY;
                        console.log(x, y);
                        timeOutEvent = setTimeout("longPress()", 500);
                        e.preventDefault();
                        drag(imagewidth, imageheight, image, imagex, imagey);
                    };
                    //缩放
                    c.onmousewheel = function(ev) {
                        console.log("进行缩放");
                        var e = ev || event;
                        console.log(e);
                        if (e.wheelDelta) {
                            //判断浏览器IE，谷歌滑轮事件
                            if (e.wheelDelta > 0) {
                                //当滑轮向上滚动时
                                if (_this.imageScale >= 10) {
                                    _this.$Message.warning("不能再大了");
                                    return false;
                                }
                                _this.imageScale = _this.accAdd(_this.imageScale, 0.1);
                                console.log(_this.imageScale);
                                ctx.clearRect(imagex, imagey, c.width, c.height);
                                ctx.drawImage(
                                    image,
                                    e.x,
                                    e.y,
                                    imagewidth * _this.imageScale,
                                    imageheight * _this.imageScale
                                );
                            }
                            if (e.wheelDelta < 0) {
                                //当滑轮向下滚动时
                                if (_this.imageScale <= 0.5) {
                                    _this.$Message.warning("不能再小了");
                                    return false;
                                }
                                _this.imageScale = _this.Subtr(_this.imageScale, 0.1);
                                console.log(_this.imageScale);
                                ctx.clearRect(imagex, imagey, c.width, c.height);
                                ctx.drawImage(
                                    image,
                                    e.x,
                                    e.y,
                                    imagewidth * _this.imageScale,
                                    imageheight * _this.imageScale
                                );
                            }
                        } else if (e.detail) {
                            //Firefox滑轮事件
                            if (e.detail > 0) {
                                //当滑轮向上滚动时
                                if (_this.imageScale <= 0.5) {
                                    _this.$Message.warning("不能再小了");
                                    return false;
                                }
                                _this.imageScale = _this.Subtr(_this.imageScale, 0.1);
                                console.log(_this.imageScale);
                                ctx.clearRect(imagex, imagey, c.width, c.height);
                                ctx.drawImage(
                                    image,
                                    e.x,
                                    e.y,
                                    imagewidth * _this.imageScale,
                                    imageheight * _this.imageScale
                                );
                            }
                            if (e.detail < 0) {
                                //当滑轮向下滚动时
                                if (_this.imageScale >= 10) {
                                    _this.$Message.warning("不能再大了");
                                    return false;
                                }
                                _this.imageScale = _this.accAdd(_this.imageScale, 0.1);
                                console.log(_this.imageScale);
                                ctx.clearRect(imagex, imagey, c.width, c.height);
                                ctx.drawImage(
                                    image,
                                    e.x,
                                    e.y,
                                    imagewidth * _this.imageScale,
                                    imageheight * _this.imageScale
                                );
                            }
                        }
                    };
                }

                //拖拽函数
                function drag(imagewidth, imageheight, image, imagex, imagey) {
                    // 按下鼠标判断鼠标位置是否在圆上，当画布上有多个路径时，isPointInPath只能判断最后那一个绘制的路径
                    // if (ctx.isPointInPath(x, y)) {
                    //路径正确，鼠标移动事件
                    c.onmousemove = function(ev) {
                        var e = ev || event;
                        // console.log(e);
                        //鼠标点击图片中心
                        var ax = e.offsetX - (_this.imageWidth * _this.imageScale) / 2;
                        var ay = e.offsetY - (_this.imageHeight * _this.imageScale) / 2;
                        //画布被移动
                        // ax = _this.accDiv(_this.Subtr(ax, _this.offsetX), _this.scale);
                        // ay = _this.accDiv(_this.Subtr(ay, _this.offsetY), _this.scale);
                        clearTimeout(timeOutEvent);
                        timeOutEvent = 0;
                        //鼠标移动每一帧都清楚画布内容，然后重新画圆
                        console.log(c.width, c.height);
                        ctx.clearRect(imagex, imagey, c.width, c.height);
                        ctx.drawImage(
                            image,
                            ax,
                            ay,
                            imagewidth * _this.imageScale,
                            imageheight * _this.imageScale
                        );
                    };
                    //鼠标移开事件
                    c.onmouseup = function(ev) {
                        var e = ev || event;
                        imagex = e.offsetX;
                        imagey = e.offsetY;
                        //画布被移动
                        imagex = _this.accDiv(
                            _this.Subtr(imagex, _this.offsetX),
                            _this.scale
                        );
                        imagey = _this.accDiv(
                            _this.Subtr(imagey, _this.offsetY),
                            _this.scale
                        );
                        _this.imageX = _this.Subtr(
                            imagex,
                            _this.accDiv(
                                _this.accMul(_this.imageWidth, _this.imageScale),
                                2
                            )
                        );
                        _this.imageY = _this.Subtr(
                            imagey,
                            _this.accDiv(
                                _this.accMul(_this.imageHeight, _this.imageScale),
                                2
                            )
                        );
                        c.onmousemove = null;
                        c.onmouseup = null;
                        clearTimeout(timeOutEvent);
                        if (timeOutEvent != 0) {
                            _this.$Message.warning("请长按拖动");
                        }
                    };
                    // }
                }
                function longPress() {
                    timeOutEvent = 0;
                }
            },
            addImg() {
                var _this = this;
                $("#file").click();
                $("#file")
                    .unbind()
                    .change(function() {
                        // 上传图片
                        var imgformData = new FormData();
                        imgformData.append("files", this.files[0]);
                        console.log(this.files)
                        uploadFileList(imgformData).then((data)=>{
                            var imgUrl = data.data[0].furl;
                            _this.imageUrl = imgUrl;
                            var image = new Image();
                            image.src = imgUrl;
                            image.onload = function() {
                                var w = image.width;
                                var h = image.height;
                                _this.imageWidth = w;
                                _this.imageHeight = h;
                                _this.imagedrag(image, w, h);
                            };
                            $("#file").val("");
                        })
                    });
            },
            dowm() {
                console.log("下载");
            },
            move() {
                this.withdrawShow = true
                this.cursorstyle = "move";
                $("#canvas").css("cursor", "move");
            },
            windowLoad() {
                if (localStorage.getItem("whiteboardMsgs_" + this.options.roomId) == null) {
                    let whiteboardMsg = [];
                    localStorage.setItem("whiteboardMsgs_" + this.options.roomId, whiteboardMsg);
                }
                //添加窗口尺寸改变响应监听
                $(window).resize(this.drawRepeat);
                //页面加载后先设置一下canvas大小
                this.windowAddMouseWheel();
            },
            //画图
            drawFunction() {
                var _this = this;
                let theCanvas = document.querySelector("#canvas");
                if (!theCanvas || !theCanvas.getContext) {
                    return false;
                } else {
                    let context = theCanvas.getContext("2d");
                    let isAllowDrawLine = false;
                    var points = [];
                    theCanvas.onmousedown = function(e) {
                        if (_this.cursorstyle == "default") {
                            isAllowDrawLine = true;
                            let ele = _this.windowToCanvas(theCanvas, e.clientX, e.clientY);
                            let { x, y } = ele;
                            if (_this.paintType == 0) {
                                context.lineWidth = _this.paintWidth;
                                context.strokeStyle = _this.paintColor;
                            }
                            if (_this.paintType == 1) {
                                context.lineWidth = 40;
                                context.strokeStyle = "white";
                            }
                            context.beginPath();
                            context.moveTo(
                                _this.accDiv(_this.Subtr(x, _this.clientX), _this.scale),
                                _this.accDiv(_this.Subtr(y, _this.clientY), _this.scale)
                            );
                            theCanvas.onmousemove = e => {
                                // console.log(e)
                                if (isAllowDrawLine) {
                                    let ele = _this.windowToCanvas(
                                        theCanvas,
                                        parseInt(
                                            _this.accDiv(
                                                _this.Subtr(e.clientX, _this.offsetX),
                                                _this.scale
                                            )
                                        ),
                                        parseInt(
                                            _this.accDiv(
                                                _this.Subtr(e.clientY, _this.offsetY),
                                                _this.scale
                                            )
                                        )
                                    );
                                    points.push(ele);
                                    let { x, y } = ele;
                                    context.lineTo(x, y);
                                    context.stroke();
                                }
                            };
                            context.closePath();
                        }
                        if (_this.cursorstyle == "move") {
                            isAllowDrawLine = true;
                            let ele = _this.windowToCanvas(
                                theCanvas,
                                _this.accDiv(
                                    _this.Subtr(e.clientX, _this.offsetX),
                                    _this.scale
                                ),
                                _this.accDiv(
                                    _this.Subtr(e.clientY, _this.offsetY),
                                    _this.scale
                                )
                            );
                            let oldX = ele.x;
                            let oldY = ele.y;
                            _this.oldX = oldX;
                            _this.oldY = oldY;
                            theCanvas.onmousemove = e => {
                                if (isAllowDrawLine) {
                                    let ele = _this.windowToCanvas(
                                        theCanvas,
                                        _this.accDiv(
                                            _this.Subtr(e.clientX, _this.offsetX),
                                            _this.scale
                                        ),
                                        _this.accDiv(
                                            _this.Subtr(e.clientY, _this.offsetY),
                                            _this.scale
                                        )
                                    );
                                    let { x, y } = ele;
                                    _this.offsetX = _this.Subtr(
                                        _this.accAdd(_this.offsetX, x),
                                        _this.oldX
                                    );
                                    _this.offsetY = _this.Subtr(
                                        _this.accAdd(_this.offsetY, y),
                                        _this.oldY
                                    );
                                    _this.drawRepeat();
                                }
                            };
                        }
                        theCanvas.onmouseup = function() {
                            console.log('-------------------'+_this.cursorstyle)
                            if (_this.cursorstyle == "default") {
                                isAllowDrawLine = false;
                                var type = 0;
                                var width = _this.paintWidth;
                                if (_this.paintColor == "red") {
                                    var color = -2359296;
                                }
                                if (_this.paintColor == "green") {
                                    var color = -14028544;
                                }
                                if (_this.paintColor == "blue") {
                                    var color = -12347649;
                                }
                                if (_this.paintType == 1) {
                                    var color = -16777216;
                                    var type = 1;
                                    var width = 40.0;
                                }
                                let timestamp = Date.parse(new Date());
                                let boardMsg = {
                                    color: color,
                                    points: points,
                                    time: timestamp,
                                    type: type,
                                    width: width,
                                    offsetX: _this.offsetX,
                                    offsetY: _this.offsetY,
                                    screenHeight:2098,
                                    screenWidth:1080,
                                    picType: 0,
                                    scale: _this.scale,
                                    roomId:0
                                };
                                let messages = {
                                    namespace:_this.options.roomId,
                                    from:_this.userID,
                                    type: 'whiteboard',
                                    message: JSON.stringify(boardMsg),
                                };
                                // let id = _this.fromId;
                                let id = 112
                                let chatType = _this.type;
                                console.log(JSON.stringify(messages));
                                _this.socket.send(JSON.stringify(messages))
                                console.log('發送了')
                                // window.parent.sendCustomMsg(id, messages, chatType);
                                if (
                                    localStorage.getItem("whiteboardMsgs_" + _this.options.roomId)
                                        .length !== 0
                                ) {
                                    var whiteboardMsgs = JSON.parse(
                                        localStorage.getItem("whiteboardMsgs_" + _this.options.roomId)
                                    );
                                } else {
                                    var whiteboardMsgs = [];
                                }
                                whiteboardMsgs.push(boardMsg);
                                // console.log(whiteboardMsgs);
                                localStorage.setItem(
                                    "whiteboardMsgs_" + _this.options.roomId,
                                    JSON.stringify(whiteboardMsgs)
                                );
                                points = [];
                            }
                            if (_this.cursorstyle == "move") {
                                isAllowDrawLine = false;
                            }
                        };
                    };
                }
            },
            //加法运算
            accAdd(arg1, arg2) {
                var r1, r2, m;
                try {
                    r1 = arg1.toString().split(".")[1].length;
                } catch (e) {
                    r1 = 0;
                }
                try {
                    r2 = arg2.toString().split(".")[1].length;
                } catch (e) {
                    r2 = 0;
                }
                m = Math.pow(10, Math.max(r1, r2));
                return ((arg1 * m + arg2 * m) / m).toFixed(2);
            },
            //减法运算
            Subtr(arg1, arg2) {
                var r1, r2, m, n;
                try {
                    r1 = arg1.toString().split(".")[1].length;
                } catch (e) {
                    r1 = 0;
                }
                try {
                    r2 = arg2.toString().split(".")[1].length;
                } catch (e) {
                    r2 = 0;
                }
                m = Math.pow(10, Math.max(r1, r2));
                //last modify by deeka
                //动态控制精度长度
                n = r1 >= r2 ? r1 : r2;
                return ((arg1 * m - arg2 * m) / m).toFixed(2);
            },
            //除法运算
            accDiv(arg1, arg2) {
                var t1 = 0,
                    t2 = 0,
                    r1,
                    r2;
                try {
                    t1 = arg1.toString().split(".")[1].length;
                } catch (e) {}
                try {
                    t2 = arg2.toString().split(".")[1].length;
                } catch (e) {}
                Math.r1 = Number(arg1.toString().replace(".", ""));
                Math.r2 = Number(arg2.toString().replace(".", ""));
                return (Math.r1 / Math.r2) * Math.pow(10, t2 - t1);
            },
            //乘法运算
            accMul(arg1, arg2) {
                var m = 0,
                    s1 = arg1.toString(),
                    s2 = arg2.toString();
                try {
                    m += s1.split(".")[1].length;
                } catch (e) {}
                try {
                    m += s2.split(".")[1].length;
                } catch (e) {}
                return (
                    (Number(s1.replace(".", "")) * Number(s2.replace(".", ""))) /
                    Math.pow(10, m)
                );
            },
            //监听鼠标滚动
            windowAddMouseWheel(
                ctx,
                image,
                imagex,
                imagey,
                imagewidth,
                imageheight
            ) {
                var _this = this;
                this.scrollFunc = function(e) {
                    e = e || window.event;
                    if (_this.cursorstyle == "image") {
                        console.log("正在发送图片");
                        // ctx.drawImage(image, imagex, imagey, imagewidth, imageheight);
                        return false;
                    }
                    if (e.wheelDelta) {
                        //判断浏览器IE，谷歌滑轮事件
                        if (e.wheelDelta > 0) {
                            //当滑轮向上滚动时
                            if (_this.scale >= 10) {
                                _this.$Message.warning("不能再大了");
                                return false;
                            }
                            _this.scale = _this.accAdd(_this.scale, 0.1);
                            _this.drawRepeat();
                        }
                        if (e.wheelDelta < 0) {
                            //当滑轮向下滚动时
                            if (_this.scale <= 0.5) {
                                _this.$Message.warning("不能再小了");
                                return false;
                            }
                            _this.scale = _this.Subtr(_this.scale, 0.1);
                            _this.drawRepeat();
                        }
                    } else if (e.detail) {
                        //Firefox滑轮事件
                        if (e.detail > 0) {
                            //当滑轮向上滚动时
                            if (_this.scale <= 0.5) {
                                _this.$Message.warning("不能再小了");
                                return false;
                            }
                            _this.scale = _this.Subtr(_this.scale, 0.1);
                            _this.drawRepeat();
                        }
                        if (e.detail < 0) {
                            //当滑轮向下滚动时
                            if (_this.scale >= 10) {
                                _this.$Message.warning("不能再大了");
                                return false;
                            }
                            _this.scale = _this.accAdd(_this.scale, 0.1);
                            _this.drawRepeat();
                        }
                    }
                };
                //给页面绑定滑轮滚动事件
                if (document.addEventListener) {
                    document.addEventListener("DOMMouseScroll", this.scrollFunc, false);
                }
                //滚动滑轮触发scrollFunc方法
                document.getElementById('canvasVue').onmousewheel = this.scrollFunc;
            },
            windowToCanvas(canvas, x, y) {
                let rect = canvas.getBoundingClientRect();
                return {
                    x: Math.ceil(x - rect.left * (canvas.width / rect.width)),
                    y: Math.ceil(y - rect.top * (canvas.height / rect.height))
                };
            },
            async drawRepeat() {
                var _this = this;
                var c = document.getElementById("canvas");
                var ctx = c.getContext("2d");
                if (
                    localStorage.getItem("whiteboardMsgs_" + _this.options.roomId).length !== 0
                ) {
                    var newBoardMsgs = JSON.parse(
                        localStorage.getItem("whiteboardMsgs_" + _this.options.roomId)
                    );
                } else {
                    var newBoardMsgs = [];
                }
                $("#canvas").attr("width", $(window).get(0).innerWidth);
                $("#canvas").attr("height", $(window).get(0).innerHeight);
                $("#canvasImage").attr("width", $(window).get(0).innerWidth);
                $("#canvasImage").attr("height", $(window).get(0).innerHeight);
                ctx.translate(_this.offsetX, _this.offsetY);
                ctx.scale(_this.scale, _this.scale);
                for (var i = 0; i < newBoardMsgs.length; i++) {
                    let points = newBoardMsgs[i].points;
                    if (newBoardMsgs[i].type == 0) {
                        if (newBoardMsgs[i].color == -2359296) {
                            ctx.strokeStyle = "red";
                        }
                        if (newBoardMsgs[i].color == -14028544) {
                            ctx.strokeStyle = "green";
                        }
                        if (newBoardMsgs[i].color == -12347649) {
                            ctx.strokeStyle = "blue";
                        }
                        ctx.lineWidth = newBoardMsgs[i].width;
                        if (points[0]) {
                            ctx.beginPath();
                            ctx.moveTo(points[0].x, points[0].y);
                            for (let j = 1; j < points.length; j++) {
                                ctx.lineTo(points[j].x, points[j].y);
                            }
                            ctx.stroke();
                            ctx.closePath();
                        }
                    }
                    if (newBoardMsgs[i].type == 1) {
                        ctx.strokeStyle = "white";
                        ctx.lineWidth = newBoardMsgs[i].width;
                        if (points[0]) {
                            ctx.beginPath();
                            ctx.moveTo(points[0].x, points[0].y);
                            for (let j = 0; j < points.length; j++) {
                                ctx.lineTo(points[j].x, points[j].y);
                            }
                            ctx.stroke();
                            ctx.closePath();
                        }
                    }
                    if (newBoardMsgs[i].type == 2) {
                        ctx.clearRect(
                            0,
                            0,
                            1280,
                            875
                        );
                    }
                    if (newBoardMsgs[i].type == 3) {
                        console.log("撤回消息");
                    }
                    if (newBoardMsgs[i].type == 4) {
                        var image = new Image();
                        image.src = newBoardMsgs[i].pic.url;
                        ctx.beginPath();
                        image.onload = await _this.addImage(ctx, newBoardMsgs[i], image);
                        ctx.closePath();
                    }
                }
            },
            //填充图片
            addImage(ctx, newBoardMsgs, image) {
                if (image.complete) {
                    ctx.drawImage(
                        image,
                        newBoardMsgs.pic.x,
                        newBoardMsgs.pic.y,
                        newBoardMsgs.pic.width,
                        newBoardMsgs.pic.height
                    );
                } else {
                    setTimeout(() => {
                        this.drawRepeat();
                    }, 1000);
                }
            },
            //监听缓存改变事件
            monitorStorage() {
                var _this = this;
                window.addEventListener(
                    "storage",
                    function(e) {
                        //e只是一个传参
                        console.log(e);
                        if (e.oldValue !== null) {
                            // let newValue = JSON.parse(e.newValue)
                            // let lastValue = newValue[newValue.length-1]
                            // _this.scale = lastValue.scale
                            // _this.offsetX = lastValue.offsetX
                            // _this.offsetY = lastValue.offsetY
                            _this.drawRepeat();
                        }
                    },
                    false
                );
            },
            // 退出会议
            exitMeeting(){
                window.removeEventListener("scroll", this.scrollFunc, true);
                this.$emit('exitMeeting',false)
            },
            // //会议
            // initEmedia() {
            //   let me = this;
            //   // im = window.parent.WebIM.EMedia.mgr;
            //   im.onConferenceExit = function(reason, failed) {
            //     reason = reason || 0;
            //     switch (reason) {
            //       case 0:
            //         reason = "正常挂断";
            //         break;
            //       case 1:
            //         reason = "没响应";
            //         break;
            //       case 2:
            //         reason = "服务器拒绝";
            //         break;
            //       case 3:
            //         reason = "对方忙";
            //         break;
            //       case 4:
            //         reason = "失败,可能是网络或服务器拒绝";
            //         if (failed === -9527) {
            //           reason = "失败,网络原因";
            //         }
            //         if (failed === -500) {
            //           reason = "Ticket失效";
            //         }
            //         if (failed === -502) {
            //           reason = "Ticket过期";
            //         }
            //         if (failed === -504) {
            //           reason = "链接已失效";
            //         }
            //         if (failed === -508) {
            //           reason = "会议无效";
            //         }
            //         break;
            //       case 5:
            //         reason = "不支持";
            //         break;
            //       case 10:
            //         reason = "其他设备登录";
            //         break;
            //       case 11:
            //         reason = "会议关闭";
            //         break;
            //       default:
            //         break;
            //     }
            //     console.log("Hangup reason " + (reason || 0));
            //   };
            //
            //   im.onMemberJoined = function(member) {
            //     let id = member.name;
            //     window.parent
            //       .getUserAndHeadUrl({
            //         type: "imName",
            //         value: id
            //       })
            //       .then(res => {
            //         let nickName = res.data.realName;
            //         _this.$Message.warning(nickName + " 加入群聊.");
            //       });
            //
            //     // me.props.setJoinedMembers(member)
            //   };
            //   im.onMemberExited = function(member, reason) {
            //     // 用户主动挂断时，不提示退出群聊
            //     if (reason !== undefined) {
            //       let id = member.name;
            //       window.parent
            //         .getUserAndHeadUrl({
            //           type: "imName",
            //           value: id
            //         })
            //         .then(res => {
            //           let nickName = res.data.realName;
            //           _this.$Message.warning(nickName + " 退出群聊.");
            //         });
            //     }
            //     // me.props.updateJoinedMembers(member)
            //   };
            //
            //   im.onStreamAdded = function(member, stream) {
            //     const located = stream.located();
            //     console.log("来流了", stream.located());
            //     let id = member.name;
            //     let index;
            //     if (located) {
            //       let localVideo = me.$refs.rv_local;
            //       window.parent
            //         .getUserAndHeadUrl({
            //           type: "imName",
            //           value: id
            //         })
            //         .then(res => {
            //           const nickName = res.data.realName;
            //           let lv = {
            //             nickName,
            //             stream: stream,
            //             localStreamId: stream.id,
            //             openVideo: true,
            //             openAudio: true
            //           };
            //           me.rv_local = lv;
            //
            //           im.onMediaChanaged(localVideo, function(constaints) {
            //             let lv = {
            //               nickName,
            //               stream: stream,
            //               localStreamId: stream.id,
            //               openVideo: constaints.video,
            //               openAudio: constaints.audio
            //             };
            //             me.rv_local = lv;
            //             console.log(me.rv_local);
            //             console.warn(stream.id, "voff:", this.getAttribute("voff"));
            //             console.warn(stream.id, "aoff:", this.getAttribute("aoff"));
            //           });
            //         });
            //       im.streamBindVideo(stream, localVideo);
            //     }
            //   };
            //   im.onStreamRemoved = function(member, stream) {};
            // },
            getRequest() {
                //获取到Url并且解析Url编码
                var url = decodeURI(location.search);
                var theRequest = new Object();
                if (url.indexOf("?") != -1) {
                    var str = url.substr(1);
                    strs = str.split("&");
                    for (var i = 0; i < strs.length; i++) {
                        theRequest[strs[i].split("=")[0]] = unescape(
                            strs[i].split("=")[1]
                        );
                    }
                }
                return theRequest;
            },
            joinMeet() {
                var Request = new Object();
                Request = this.getRequest();

                var password = Request["password"];
                var fromId = Request["fromid"];
                var type = Request["type"];
                this.type = type;
                this.fromId = fromId;
                this.paintColor = "red";
                this.paintType = 0;
                this.paintWidth = 3;
                // window.parent.WebIM.EMedia.mgr
                //   .joinConference(options.roomId, password, "白板会议")
                //   .then(function(options.roomId) {
                //     window.parent.WebIM.EMedia.mgr
                //       .publish({ audio: true, video: false }, "白板会议")
                //       ["catch"](function(e) {
                //       console.error(e);
                //     });
                //   })
                //   .catch(function(error) {
                //     console.log(error);
                //     if (error.error == -301) {
                //       window._this.$Message.warning("白板会议已结束");
                //       window.parent.layer.closeAll("iframe");
                //     }
                //   });
            },
            mute() {
                let localVideo = this.$refs.rv_local;
                let { stream, localStreamId, openAudio, openVideo } = this.rv_local;
                console.log(localVideo);
                if (openAudio) {
                    _this.$Message.warning("已静音");
                    // window.parent.WebIM.EMedia.mgr.triggerPauseAudio(localVideo);
                    // window.parent.WebIM.EMedia.mgr.unpublish(localVideo.stream);
                    this.rv_local.openAudio = false;
                } else {
                    _this.$Message.warning("已取消静音");
                    // window.parent.WebIM.EMedia.mgr.triggerResumeAudio(localVideo);
                    // window.parent.WebIM.EMedia.mgr
                    //   .publish({ audio: true, video: false }, "白板会议")
                    //   ["catch"](function(e) {
                    //     console.error(e);
                    //   });
                    this.rv_local.openAudio = true;
                }
            },
            //初始化socket
            initSocket(){
                this.initSocketInfo()
            },
            //初始化socket
            initSocketInfo(){
                this.socket = null
                this.socket = new WebSocket("wss://htcs.zjxianlin.com/htcs/connect/socket/"+this.userID+"/"+this.options.roomId)
                let that = this
                this.socket.onmessage = function(e) {
                    console.log(e)
                    let data =JSON.parse(e.data)
                    console.log(  JSON.parse(data.message))
                    if (
                        localStorage.getItem("whiteboardMsgs_" + that.options.roomId).length !== 0
                    ) {
                        var whiteboardMsgs = JSON.parse(
                            localStorage.getItem("whiteboardMsgs_" + that.options.roomId)
                        );
                    } else {
                        var whiteboardMsgs = [];
                    }
                    if(that.withdrawShow){
                        whiteboardMsgs.push(JSON.parse(data.message));
                    }
                    console.log(whiteboardMsgs);
                    localStorage.setItem(
                        "whiteboardMsgs_" + that.options.roomId,
                        JSON.stringify(whiteboardMsgs)
                    );
                    that.drawRepeat();
                }
                // 错误的时候触发
                this.socket.onerror = (err) => {
                    console.log(err);
                }

                // 服务器断开连接的时候触发
                this.socket.onclose = () => {
                    console.log('closed~');
                    this.initSocketInfo()
                }
            },
            //获取历史消息
            historyMsg(){
                let that = this
                listWhieBoardHistoryMsg({namespace:this.options.roomId}).then((res)=>{
                    if(res.code==200){
                        if (
                            localStorage.getItem("whiteboardMsgs_" + that.options.roomId).length !== 0
                        ) {
                            var whiteboardMsgs = JSON.parse(
                                localStorage.getItem("whiteboardMsgs_" + that.options.roomId)
                            );
                        } else {
                            var whiteboardMsgs = [];
                        }
                            whiteboardMsgs=[...whiteboardMsgs,...res.data];
                        console.log(whiteboardMsgs);
                        localStorage.setItem(
                            "whiteboardMsgs_" + that.options.roomId,
                            JSON.stringify(whiteboardMsgs)
                        );
                    }
                })
            }
        },
        created() {
            this.historyMsg()
            this.initSocket()
        },
        mounted: function() {
            let _this = this;
            _this.joinMeet();
            _this.windowLoad();
            _this.monitorStorage();
            // _this.initEmedia();

            _this.drawRepeat();
            setTimeout(() => {
                _this.drawRepeat();
            }, 1000);
            _this.drawFunction();
        },
        destroyed() {
            this.$emit('exitMeeting',false)
        }
    }
</script>

<style lang='scss' scoped>
    canvas {
        display: block;
        cursor: url(../../../../../src/assets/images/pencil.png), auto;
    }

    .paint-type {
        width: 50px;
        position: fixed;
        right: 16px;
        top: 20px;
        z-index: 1;
    }

    .paint-type div {
        margin-bottom: 5px;
        width: 43px;
        height: 43px;
        cursor: pointer;
    }

    .paint-type .paint {
        background-image: url(../../../../../src/assets/images/paint.png);
        background-size: 100%;
    }

    .paint-type .eraser {
        background-image: url(../../../../../src/assets/images/eraser.png);
        background-size: 100%;
    }
    .paint-type .move {
        background-image: url(../../../../../src/assets/images/move.png);
        background-size: 100%;
    }

    .paint-type .withdraw {
        background-image: url(../../../../../src/assets/images/withdraw.png);
        background-size: 100%;
    }

    .paint-type .clean {
        background-image: url(../../../../../src/assets/images/clean.png);
        background-size: 100%;
    }
    .paint-type .enlarge {
        background-image: url(../../../../../src/assets/images/enlarge.png);
        background-size: 100%;
    }

    .paint-type .narrow {
        background-image: url(../../../../../src/assets/images/narrow.png);
        background-size: 100%;
    }

    .paint-color {
        z-index: 1;
        position: absolute;
        right: 82px;
        top: 20px;
        background-color: #eeeeee;
        width: 200px;
        height: 30px;
        line-height: 34px;
        text-align: center;
        border-radius: 15px;
        display: flex;
    }

    .paint-color div {
        width: 16px;
        height: 16px;
        margin: auto;
        border-radius: 10px;
        border: none;
        background-color: black;
        cursor: pointer;
    }

    .paint-color .red {
        background-color: red;
    }

    .paint-color .blue {
        background-color: blue;
    }

    .paint-color .green {
        background-color: green;
    }

    .paint-color .line {
        width: 1px;
        background-color: #fff;
        height: 30px;
    }

    .paint-color .small {
        width: 8px;
        height: 8px;
    }

    .paint-color .middle {
        width: 12px;
        height: 12px;
    }

    .paint-color .big {
        width: 16px;
        height: 16px;
    }

    .paint-foot {
        z-index: 1;
        position: fixed;
        bottom: 22px;
        width: 100%;
        height: 30px;
        line-height: 34px;
        display: flex;
    }

    .mute {
        /*background-image: url(../../../../../src/assets/images/mute.png);*/
        background-size: 100%;
        width: 43px;
        height: 43px;
        text-align: center;
        margin: auto;
        cursor: pointer;
    }

    .addImg {
        background-image: url(../../../../../src/assets/images/addImg.png);
        background-size: 100%;
        width: 43px;
        height: 43px;
        float: left;
        margin-left: 16px;
        border: none;
        outline: none;
        cursor: pointer;
    }
    .cancle {
        background-image: url(../../../../../src/assets/images/clean.png);
    }
    .confirm {
        background-image: url(../../../../../src/assets/images/send.png);
    }
    .send {
        background-size: 100%;
        width: 43px;
        height: 43px;
        float: right;
        margin-right: 16px;
        display: none;
        cursor: pointer;
    }

    .pad-canvas {
        width: 100%;
        height: 100%;
    }

    .paint-move {
        z-index: 1;
        position: fixed;
        width: 150px;
        height: 150px;
        bottom: 100px;
    }

    .flex {
        display: flex;
    }

    .restore {
        background-image: url(../../../../../src/assets/images/restore.png);
        background-size: 100%;
        width: 50px;
        height: 50px;
        cursor: pointer;
    }

    .moveTop {
        background-image: url(../../../../../src/assets/images/top.png);
        background-size: 100%;
        width: 50px;
        height: 50px;
        margin-left: 50px;
        cursor: pointer;
    }

    .moveBottom {
        background-image: url(../../../../../src/assets/images/bottom.png);
        background-size: 100%;
        width: 50px;
        height: 50px;
        margin-left: 50px;
        cursor: pointer;
    }

    .moveLeft {
        background-image: url(../../../../../src/assets/images/left.png);
        background-size: 100%;
        width: 50px;
        height: 50px;
        cursor: pointer;
    }

    .moveRight {
        background-image: url(../../../../../src/assets/images/right.png);
        background-size: 100%;
        width: 50px;
        height: 50px;
        cursor: pointer;
    }
    .videoLocal {
        display: none;
    }
    .canvasPaint {
        position: absolute;
        background: #fff;
    }
    .canvasImage {
        background-color: rgba(255, 255, 255, 0);
        display: none;
        position: absolute;
    }
    ::v-deep .custom-video-meeting {
        width: 80vw;
        height: 80vh;
        max-width: 1280px;
        box-shadow: 0 11px 20px 0 rgba(0, 0, 0, 0.3);
        display: flex;
        flex-direction: column;
    .el-dialog__body {
        padding: 16px 24px;
        flex: 1;
    }
    }
    .el-dialog {

    }
</style>
