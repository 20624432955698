const HTCSWebInAccount = () =>
  import( /* webpackChunkName: "HTCSWebInAccount" */ "@/views/HTCSWeb/HTCSWebInAccount")
const HTCSWebInAccountOfAdd = () =>
  import( /* webpackChunkName: "HTCSWebInAccountOfAdd" */ "@/views/HTCSWeb/HTCSWebInAccount/HTCSWebInAccountOfAdd")
const HTCSWebInAccountOfEdit = () =>
  import( /* webpackChunkName: "HTCSWebInAccountOfEdit" */ "@/views/HTCSWeb/HTCSWebInAccount/HTCSWebInAccountOfEdit")

export default [
  {
    path: "list",
    name: "HTCSWebInAccount",
    component: HTCSWebInAccount,
    meta: { title: "内部账号" }
  },
  {
    path: "add",
    name: "HTCSWebInAccountOfAdd",
    component: HTCSWebInAccountOfAdd,
    meta: { title: "新增内部账号" }
  },
  {
    path: "edit/:id",
    name: "HTCSWebInAccountOfEdit",
    component: HTCSWebInAccountOfEdit,
    meta: { title: "修改内部账号" }
  },
]