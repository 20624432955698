<template>
  <section class="main-top">
    <span v-if="showGoBack" @click="goBack" class="back-home box-common">
      <img src="@/assets/image/left-arrow.png" alt />
      <span>返回</span>
    </span>
    <el-breadcrumb class="app-breadcrumb" separator="/">
      <transition-group name="breadcrumb">
        <el-breadcrumb-item v-for="(item,index) in levelList" :key="item.path">
          <span
            v-if="item.redirect==='noRedirect'||index==levelList.length-1"
            class="no-redirect"
          >{{ item.meta.title }}</span>
          <a v-else @click.prevent="handleLink(item)">{{ item.meta.title }}</a>
        </el-breadcrumb-item>
      </transition-group>
    </el-breadcrumb>
  </section>
</template>

<script>
import pathToRegexp from 'path-to-regexp'
import settings from '@/mixins/settings'
import store from '@/store'
import { param } from '@/utils'
export default {
  name: 'MainTop',
  mixins: [settings],
  props: ['showGoBack'],
  computed: {
    beginDate() {
      return this.getCurrentMonthFirstDay()
    },
    endDate() {
      return this.getCurrentMonthLastDay()
    },
  },
  data() {
    return {
      levelList: null,
    }
  },
  watch: {
    $route: {
      handler(route, preRoute) {
        this.getBreadcrumb()
      },
      immediate: true,
    },
  },
  created() {
    this.getBreadcrumb()
  },
  methods: {
    goBack() {
      this.$router.back(-1)
    },
    getBreadcrumb() {
      // only show routes with meta.title
      let matched = this.$route.matched.filter(
        (item) => item.meta && item.meta.title
      )
      let addDeskTop = [
        '/dashboard/time-board',
        '/dashboard/notices',
        '/dashboard/check-list',
        '/dashboard/upload/trip',
        '/dashboard/upload/install',
        '/dashboard/upload/debugger',
        '/dashboard/upload/other',
        '/dashboard/modify-trip-records',
        '/dashboard/add-trip-records',
        '/dashboard/department-notice',
      ]
      let addStaffManage = ['/staffManage/detail', '/h_staff/high-manage']
      let addUnitMange = [
        '/spare/unit-detail',
        '/spare/addUnit',
        '/spare/spec-detail',
      ]
      let routePath = this.$route.path
      let matchedArr = []
      if (addDeskTop.length > 0) {
        for (let i = 0; i < addDeskTop.length; i++) {
          let path = addDeskTop[i]
          if (routePath.match(path)) {
            matchedArr.push({
              path: '/dashboard/department',
              meta: { title: '我的桌面' },
            })
          }
        }
      }
      if (addStaffManage.length > 0) {
        for (let i = 0; i < addStaffManage.length; i++) {
          let path = addStaffManage[i]
          if (routePath.match(path)) {
            matchedArr.push({
              path: '/h_staff/board',
              meta: { title: '科员管理' },
            })
          }
        }
      }
      if (addUnitMange.length > 0) {
        for (let i = 0; i < addUnitMange.length; i++) {
          let path = addUnitMange[i]
          if (routePath.match(path)) {
            matchedArr.push({
              path:
                '/unitManage/board/-1?pageSize=10&pageNumber=1&keyword=&sort=crew_code&order=desc',
              meta: { title: '设备管理' },
            })
          }
        }
      }

      if (routePath.match('/trip-board/trip-detail')) {
        let searchType = ''
        let dptType = ''
        if (
          store.getters.roles.includes('director') ||
          store.getters.roles.includes('leader')
        ) {
          searchType = '20' //默认关键字检索的类型为出差人员
          dptType = '0' //默认科室检索的类型为员工科室
        } else {
          searchType = '10' // 关键字检索的类型为用户单位
          dptType = '1' // 默认科室检索的类型为机组科室
        }
        let query = {
          beginDate: this.beginDate,
          endDate: this.endDate,
          isMy: '0',
          dptId: '-1',
          dptType,
          fstatus: '21,13,12,10,9', //默认选中全部
          limit: 10,
          page: 1,
          order: 'desc',
          sort: 'id',
          searchType,
          searchValue: '',
          serverType: '-1',
        }
        matchedArr.push({
          path: `/trip-board/trip-board?${param(query)}`,
          meta: { title: '出差台账' },
        })
      }
      if (
        routePath.match('/spare/checkTask/tripData/editDetail') ||
        routePath.match('/spare/checkTask/tripData/detail')
      ) {
        matchedArr.push({
          path:
            '/spare/checkTask?pageNumber=1&pageSize=10&fstatus=20&searchType=10&searchValue=',
          meta: { title: '审核任务' },
        })
      }
      matched = this.$_.uniqBy(matchedArr.concat(matched), 'path')
      const first = matched[0]
      this.levelList = matched.filter((item) => {
        return item.meta && item.meta.title && item.meta.breadcrumb !== false
      })
    },
    isDashboard(route) {
      const name = route && route.name
      if (!name) {
        return false
      }
      return (
        name.trim().toLocaleLowerCase() === 'Department'.toLocaleLowerCase()
      )
    },
    pathCompile(path) {
      // To solve this problem https://github.com/PanJiaChen/vue-element-admin/issues/561
      const { params } = this.$route
      var toPath = pathToRegexp.compile(path)
      return toPath(params)
    },
    handleLink(item) {
      const { redirect, path } = item
      if (redirect) {
        this.$router.push(redirect)
        return
      }
      this.$router.push(this.pathCompile(path))
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.module.scss';
.main-top {
  height: 40px;
  margin-bottom: 5px;
  border-radius: 5px;
  display: flex;
  .el-breadcrumb {
    line-height: 40px;
    margin-left: 16px;
  }
  .back-home {
    margin: 5px 5px 5px 0px;
    font-size: 14px;
    cursor: pointer;
    padding: 0px 5px;
    border-radius: 5px;
    display: block;
    background: #f8f8f8;
    img {
      margin-right: 5px;
    }
    img,
    span {
      line-height: 30px;
      vertical-align: middle;
    }
    &:hover {
      border: 1px solid $selectColor;
      span {
        color: $selectColor;
      }
    }
  }
}
</style>
