// 开箱管理
const OpenBox = () =>
    import( /* webpackChunkName: "Live" */ "@/views/OpenBox")
// 开箱管理
const OpenBoxDetail = () =>
    import( /* webpackChunkName: "Live" */ "@/views/OpenBox/detail")
export default [
    {
        path: "open-box",
        name: "OpenBox",
        component: OpenBox,
        meta: { title: "开箱管理" }
    },
    {
        path: "open-box-detail",
        name: "OpenBoxDetail",
        component: OpenBoxDetail,
        meta: { title: "开箱管理详情" }
    }
]