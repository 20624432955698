import Vue from "vue";
import VueRouter from "vue-router";

//重写路由
const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch((err) => err);
};
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err);
};
const Redirect = () =>
  import(/* webpackChunkName: "Redirect" */ "@/views/redirect");
const Login = () => import(/* webpackChunkName: "login" */ "@/views/Login");
const ForgetPass = () =>
  import(/* webpackChunkName: "forgetPass" */ "@/views/Login/ForgetPass");
const Layout = () => import(/* webpackChunkName: "layout" */ "@/views/Layout");
const ProService = () =>
  import(/* webpackChunkName: "ProService" */ "@/views/ProService");
// 移动端上传环境安全点检表
const AppComponentsCheckList = () =>
  import(
    /* webpackChunkName: "AppComponentsCheckList" */ "@/views/AppComponents/AppComponentsCheckList"
  );
const AppComponentsContactOrder = () =>
  import(
    /* webpackChunkName: "AppComponentsContactOrder" */ "@/views/AppComponents/AppComponentsContactOrder"
  );
const NoPage = () => import(/* webpackChunkName: "noPage" */ "@/views/NoPage");

const SystemMessage = () =>
  import(/* webpackChunkName: "systemMessage" */ "@/views/SystemMessage");
//正在直播
const HTCSWebLiveStreamOfTeaching = () =>
  import(
    /* webpackChunkName: "HTCSWebLiveStreamOfTeaching" */ "@/views/HTCSWeb/HTCSWebLiveStream/HTCSWebLiveStreamOfTeaching"
  );
// 服务授权页面
const AuthWeb = () =>
  import(/* webpackChunkName: "AuthWeb" */ "@/views/AuthorizationWeb");
import dashboard from "./modules/dashboard";
import tripStatistics from "./modules/tripStatistics";
import customer from "./modules/customer";
import archives from "./modules/archives";
import staffManage from "./modules/staffManage";
import unitManage from "./modules/unitManage";
import htcsAccount from "./modules/htcs-account";
import htcsCustomer from "./modules/htcs-customer";
import htcsExperts from "./modules/htcs-experts";
import htcsInAccount from "./modules/htcs-in_account";
import htcsLiveStream from "./modules/htcs-live_stream";
import htcsOrder from "./modules/htcs-order";
import htcsRoles from "./modules/htcs-roles";
import htcsVideos from "./modules/htcs-videos";
import htcsConfig from "./modules/htcs-config";
import htcsCommonProblem from "./modules/htcs-commonProblem";
import live from "./modules/live";
import statistics from "./modules/statistics";
import recording from "./modules/recording";
import MenuManagement from "./modules/menuManagement";
import RoleManagement from "./modules/roleManagement";
import accountManagement from "./modules/accountManagement";
import openBox from "./modules/openBox";
import TripBoard from "./modules/TripBoard";
import addTrip from "./modules/addTrip";
import kkfileView from "./modules/kkfileView";
import system from "./modules/system";
import fileManage from "./modules/htcs-fileManage";
import sop from "./modules/sop";

Vue.use(VueRouter);

export const constantRoutes = [
  {
    path: "/redirect",
    component: Layout,
    children: [
      {
        path: "/redirect/:path*",
        component: Redirect,
      },
    ],
  },
  {
    path: "/authWeb",
    name: "AuthWeb",
    component: AuthWeb,
    meta: { title: "服务授权" },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { title: "登录" },
  },
  {
    path: "/forgetPass",
    name: "ForgetPass",
    component: ForgetPass,
    meta: { title: "忘记密码" },
  },
  {
    path: "/",
    redirect: "/dashboard",
  },
  {
    path: "/h-live_stream/online/:id",
    name: "HTCSWebLiveStreamOfTeaching",
    component: HTCSWebLiveStreamOfTeaching,
    meta: { title: "正在直播", noCache: true },
  },
  //我的桌面
  {
    path: "/dashboard",
    name: "Layout",
    redirect: "/dashboard/department",
    component: Layout,
    children: [...dashboard],
  },
  // 出差统计
  {
    path: "/statis",
    name: "Layout",
    redirect: "/statis/staff",
    component: Layout,
    children: [...tripStatistics],
  },
  //客户管理
  {
    path: "/customer",
    name: "Layout",
    redirect: "/customer/company",
    component: Layout,
    children: [...customer],
  },
  // 档案管理
  {
    path: "/archives",
    name: "Layout",
    redirect: "/archives/contact-order",
    component: Layout,
    children: [...archives],
  },
  // 员工管理
  {
    path: "/h_staff",
    name: "Layout",
    redirect: "/h_staff/board",
    component: Layout,
    children: [...staffManage],
  },
  // 机组管理
  {
    path: "/unitManage",
    name: "UnitManage",
    redirect: "/unitManage/board",
    component: Layout,
    children: [...unitManage],
  },
  // 系统消息
  {
    path: "/sys-msg",
    name: "SystemMessage",
    component: Layout,
    redirect: "/sys-msg/board",
    children: [
      {
        path: "board",
        name: "SystemMessage",
        component: SystemMessage,
        meta: { title: "系统消息" },
      },
    ],
  },
  // 系统管理
  {
    path: "/system",
    name: "SystemManage",
    component: Layout,
    // redirect: "/system/board",
    children: [...system],
  },
  // 前台账户
  {
    path: "/h-account",
    component: Layout,
    redirect: "/h-account/list",
    children: [...htcsAccount],
  },
  // 工单管理
  {
    path: "/h-order",
    component: Layout,
    redirect: "/h-order/list",
    children: [...htcsOrder],
  },
  // 客服管理
  {
    path: "/h-cust",
    component: Layout,
    redirect: "/h-cust/list",
    children: [...htcsCustomer],
  },
  // 监控管理
  {
    path: "/live",
    component: Layout,
    redirect: "/live/list",
    children: [...live],
  },
  // KkFile管理
  {
    path: "/kkfile",
    component: Layout,
    redirect: "/kkfile/index",
    children: [...kkfileView],
  },
  //菜单管理
  {
    path: "/MenuManagement",
    component: Layout,
    redirect: "/MenuManagement/MenuManagement",
    children: [...MenuManagement],
  },
  //角色管理
  {
    path: "/RoleManagement",
    component: Layout,
    redirect: "/RoleManagement/RoleManagement",
    children: [...RoleManagement],
  },
  //角色管理
  {
    path: "/AccountManagement",
    component: Layout,
    redirect: "/AccountManagement/AccountManagement",
    children: [...accountManagement],
  },

  //流量统计
  {
    path: "/statistics",
    component: Layout,
    redirect: "/statistics/list",
    children: [...statistics],
  },
  // 专家管理
  {
    path: "/h-experts",
    component: Layout,
    redirect: "/h-experts/list",
    children: [...htcsExperts],
  },
  // 视频管理
  {
    path: "/h-videos",
    component: Layout,
    redirect: "/h-videos/list",
    children: [...htcsVideos],
  },
  // 直播管理
  {
    path: "/h-live_stream",
    component: Layout,
    redirect: "/h-live_stream/list",
    children: [...htcsLiveStream],
  },
  //录制管理
  {
    path: "/recording",
    component: Layout,
    redirect: "/recording/managementList",
    children: [...recording],
  },
  // 内部账号
  {
    path: "/h-in_account",
    component: Layout,
    redirect: "/h-in_account/list",
    children: [...htcsInAccount],
  },
  // 角色管理
  {
    path: "/h-roles",
    component: Layout,
    redirect: "/h-roles/list",
    children: [...htcsRoles],
  },
  // 系统配置
  {
    path: "/h-config",
    component: Layout,
    redirect: "/h-config/list",
    children: [...htcsConfig],
  },
  // 常见问题配置
  {
    path: "/h-commonProblem",
    component: Layout,
    redirect: "/h-commonProblem/list",
    children: [...htcsCommonProblem],
  },
  // 开箱管理
  {
    path: "/open-box",
    component: Layout,
    redirect: "/open-box/open-box",
    children: [...openBox],
  },
  // 出差台账
  {
    path: "/trip-board",
    component: Layout,
    redirect: "/trip-board/trip-board",
    children: [...TripBoard],
  },
  // 指派出差
  {
    path: "/add-trip",
    component: Layout,
    redirect: "/add-trip/add-trip",
    children: [...addTrip],
  },
  {
    path: "/proService",
    name: "ProService",
    component: ProService,
  },
  {
    path: "/check-list*",
    name: "AppComponentsCheckList",
    component: AppComponentsCheckList,
  },
  {
    path: "/contact-desc*",
    name: "AppComponentsContactOrder",
    component: AppComponentsContactOrder,
  },
  // 文件管理
  {
    path: "/fileManage",
    name: "fileManage",
    component: Layout,
    children: [...fileManage],
  },
  {
    path: "/sop",
    name: "Layout",
    redirect: "/sop/index",
    component: Layout,
    children: [...sop],
  },
  {
    path: "*",
    name: "NoPage",
    component: NoPage,
  },
];
const createRouter = () =>
  new VueRouter({
    mode: "history", // require service support
    scrollBehavior: () => ({ y: 0 }),
    base: "/",
    // base: '/bg/',   //杭汽测试
    routes: constantRoutes,
  });
const router = createRouter();

export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
export default router;
