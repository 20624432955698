const model = {
  namespaced: true,
  state: {
    // 实时看板
    timeBoard: {
      staffListOfTrip: [],    //指派出差选中的人员列表
      crewListOfTrip: [],   //指派出差选中机组列表
      companyInfoOfTrip: {}, //选中的公司信息
      activeNames: ['staff']  //指派出差面板打开面板选项staff  company
    }
  },

  mutations: {
    SET_MODEL (state, obj) {
      let { modelName, varName, data } = obj
      if (state.hasOwnProperty(modelName)) {
        if (state[modelName].hasOwnProperty(varName)) {
          state[modelName][varName] = data
        }
      }
    }
  },

  actions: {
    setModel ({ commit }, obj) {
      commit('SET_MODEL', obj)
    }
  }
}

export default model
