<!-- chat -->
<template>
  <section class="chat-container" v-if="isLogin">
    <div
      class="loading"
      element-loading-text="正在拼命初始化..."
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <!-- v-loading="showLoading" -->
      <my-drag class="chat-wrapper"  target="#app" id="chatBox">
        <el-row>
          <el-col :xs="10" :sm="10" :md="8" :lg="8" :xl="7">
            <side-bar  @weight1="weight1"/>
          </el-col>
          <el-col :xs="14" :sm="14" :md="16" :lg="16" :xl="17">
            <current-conversation />
          </el-col>
        </el-row>
      </my-drag>
      <call-layer-voice
        ref="callLayerOfVoice"
        v-if="callComponentType==='voice'"
        class="chat-wrapper"
        @refuseCall="handleRejectCall"
        @acceptCall="handleAccept"
        @holdUpCall="handleCallEnd"
      />
      <CallLayerVideo
        ref="callLayerOfVideo"
        v-if="callComponentType==='video'"
        class="chat-wrapper"
        @refuseCall="handleRejectCall"
        @acceptCall="handleAccept"
        @holdUpCall="handleCallEnd"
        @weight1="weight1"
      />
      <image-previewer />
      <MessageTipVoice ref="msgVoiceTip" />
    </div>
  </section>
</template>

<script>
import { Notification } from 'element-ui'
import { mapState } from 'vuex'
import CurrentConversation from './conversation/current-conversation'
import SideBar from './layout/side-bar'
import ImagePreviewer from './message/image-previewer.vue'
import { translateGroupSystemNotice } from '@/utils/common'
// 语音通话
// trtcCalling 视频通话
import CallLayerVideo from './message/call-layer-video'
import CallLayerVoice from './message/call-layer-voice'
import MTA from '@/utils/mta'
import { debounce } from '@/utils'
import { MyDrag } from '$ui'
import chat from './mixins/chat'
import trtcCalling from './mixins/trtcCalling'
import MessageTipVoice from './voice/MessageTipVoice'
export default {
  name: 'Chat',
  mixins: [chat, trtcCalling],
  components: {
    SideBar,
    CurrentConversation,
    ImagePreviewer,
    CallLayerVideo,
    CallLayerVoice,
    MessageTipVoice,
    MyDrag,
  },
  computed: {
    ...mapState({
      currentUserProfile: (state) => state.user.currentUserProfile,
      currentConversation: (state) => state.conversation.currentConversation,
      currentMessageList: (state) => state.conversation.currentMessageList,
      isLogin: (state) => state.user.isLogin,
      isSDKReady: (state) => state.user.isSDKReady,
      userID: (state) => state.user.userID,
      groupList: (state) => state.group.groupList,
      // trtcCalling 参数
      callComponentType: (state) => state.video.callComponentType,
      callStatus: (state) => state.video.callStatus,
      isInviter: (state) => state.video.isInviter,
      isFromGroup: (state) => state.video.isFromGroup,
      meetingUserList: (state) => state.video.meetingUserList,
      muteVideoUserIdList: (state) => state.video.muteVideoUserIdList,
      muteAudioUserIdList: (state) => state.video.muteAudioUserIdList,
      inviteeListByReject: (state) => state.video.inviteeListByReject,
    }),
    // 是否显示 Loading 状态
    showLoading() {
      return !this.isSDKReady
    },
  },
  watch: {
    isLogin(next) {
      if (next) {
        MTA.clickStat('link_two', { show: 'true' })
      }
    },
    callComponentType:{
      handler:function(){
        console.log('------------------------'+this.callComponentType)
      },
      deep:true
    }
  },
  data() {
    return {
      // trtcCalling 参数
      inviterName: '',
      sponsor: '', //邀请人的imId
      inviteData: {},
      inviteID: '',
      userIDList:[],//同时还被邀请的人
      weight:1,
    }
  },
  created() {
    // 初始化监听器
    this.initListener()
    this.initListenerOfTrtcCalling()
  },
  mounted() {
    this.$bus.$on('msgVoiceTip', this.msgRing)
  },
  destroyed() {
    this.$bus.$off('msgVoiceTip', this.msgRing)
    this.removeListenerOfTrtcCalling()
  },
  methods: {
    //加权重
    weight1(value){
      this.weight = value
      if(this.weight == 2 && this.callStatus === 'connected'){
        document.getElementById('chatBox').style.zIndex=1000
      }else{
        document.getElementById('chatBox').style.zIndex=1
      }
    },
    initListener() {
      // 登录成功后会触发 SDK_READY 事件，该事件触发后，可正常使用 SDK 接口
      this.tim.on(this.TIM.EVENT.SDK_READY, this.onReadyStateUpdate, this)
      // SDK NOT READT
      this.tim.on(this.TIM.EVENT.SDK_NOT_READY, this.onReadyStateUpdate, this)
      // 被踢出
      this.tim.on(this.TIM.EVENT.KICKED_OUT, this.onKickOut)
      // SDK内部出错
      this.tim.on(this.TIM.EVENT.ERROR, this.onError)
      // 收到新消息
      this.tim.on(this.TIM.EVENT.MESSAGE_RECEIVED, this.onReceiveMessage)
      // 会话列表更新
      this.tim.on(
        this.TIM.EVENT.CONVERSATION_LIST_UPDATED,
        this.onUpdateConversationList
      )
      // 群组列表更新
      this.tim.on(this.TIM.EVENT.GROUP_LIST_UPDATED, this.onUpdateGroupList)
      // 网络监测
      this.tim.on(this.TIM.EVENT.NET_STATE_CHANGE, this.onNetStateChange)
      // 已读回执
      this.tim.on(this.TIM.EVENT.MESSAGE_READ_BY_PEER, this.onMessageReadByPeer)
    },
    async handleTimMessage(messageList) {
      // 消息去重
      let msgList = []
      if (messageList.length) {
        for (let i = 0; i < messageList.length; i++) {
          let item = messageList[i]
          if (this.currentMessageList.every((i) => i.ID !== item.ID)) {
            msgList.push(item)
          }
        }
      }
      if (msgList.length) {
        for (let i = 0; i < msgList.length; i++) {
          let item = msgList[i]
          // 如果是群消息提示，且该消息中nick为空，则获取nick的值 begin
          if (item.type === this.TIM.TYPES.MSG_GRP_TIP) {
            if (item.nick.length === 0) {
              let nick = ''
              await this.getUserProfile([item.payload.userIDList[0]]).then(
                (users) => {
                  nick = users[0].nick
                }
              )
              item.nick = nick
            }
          }
          // 如果是群消息提示，且该消息中nick为空，则获取nick的值 end
        }
      }

      this.handleQuitGroupTip(msgList)
      this.$store.commit('pushCurrentMessageList', msgList)
      // 响铃
      this.handleAnimation(msgList)
      // 处理trtcCalling音视频消息
      this.handleTrtcCallingMessage(msgList)
    },
    onReceiveMessage({ data: messageList }) {
      console.log('chat onReceiveMessage', messageList)
      // 直播群跳过此处
      if (messageList.length) {
        messageList.map((message) => {
          if (message.conversationType === 'GROUP') {
            let groupArr = this.$_.filter(this.groupList, {
              groupID: message.to,
            })
            if (groupArr.length) {
              // 不处理直播群消息
              if (groupArr[0].type === 'AVChatRoom') {
                // this.handleTimMessage([message])
              } else {
                this.handleTimMessage([message])
              }
            }
          } else if (message.conversationType === '@TIM#SYSTEM') {
            // 屏蔽系统消息
          } else {
            this.handleTimMessage([message])
          }
        })
      }
    },
    // 收到消息动画
    handleAnimation(messageList) {
      let msgType = [
        this.TIM.TYPES.MSG_TEXT, //文本消息
        this.TIM.TYPES.MSG_IMAGE, //图片消息
        this.TIM.TYPES.MSG_AUDIO, //音频消息
        this.TIM.TYPES.MSG_VIDEO, //视频消息
        this.TIM.TYPES.MSG_FILE, //文件消息
      ]
      if (messageList.length) {
        messageList.map((item) => {
          if (msgType.includes(item.type)) {
            this.$store.dispatch('app/setMsgType', 'txt')
            this.$bus.$emit('msgVoiceTip')
          }
        })
      }
    },
    msgRing: debounce(function () {
      this.$store.dispatch('app/shark')
      if (this.$refs.msgVoiceTip) {
        this.$refs.msgVoiceTip.play()
      }
    }, 200),
    isJSONStr(data) {
      try {
        let json = JSON.parse(data)
        return true
      } catch (error) {
        return false
      }
    },
    // 判断是否为trtcCalling插件的消息
    IsTrtcCallingMsg(payload) {
      if (payload.hasOwnProperty('data') && this.isJSONStr(payload.data)) {
        let data = JSON.parse(payload.data)
        // 音视频通话存在该字段inviteID
        console.log(data)
        if (data.hasOwnProperty('inviteID')) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    // 处理trtcCalling音视频消息
    // 模拟当前用户收到邀请时
    // 解决接听群组音视频电话时，不能定位到群ID问题
    handleTrtcCallingMessage(messageList) {
      if (messageList.length) {
        messageList.map((item) => {
          if (item.type === 'TIMCustomElem') {
            let payload = item.payload
            if (this.IsTrtcCallingMsg(payload)) {
              console.log('模拟当前用户收到邀请时', item)
              let chatDom = document.querySelector('.chat-container')
              chatDom.style.display = 'block'
              let payloadData = JSON.parse(payload.data)
              //判断依据： actionType===1 && (0!== call_end && room_id)  参考：custom-element.vue
              // 参考数据：{"actionType":1,"businessID":1,"data":"{\"room_id\":678998266,\"businessID\":\"av_call\",\"version\":4,\"call_type\":2}","groupID":"@TGS#1RC6IJJHO","inviteID":"144115234608687017-1627022838-4100384772","inviteeList":["qst-htcs_18657114710"],"inviter":"htcs_5dz9i8imgz6","onlineUserOnly":false,"timeout":30}
              let payloadDataData = null
              if(payloadData.actionType !== 5){
                 payloadDataData = JSON.parse(payloadData.data)
              }
              // console.log(payloadDataData.hasOwnProperty('room_id'))
              if (
                payloadData.actionType === 1 &&
                !payloadDataData.hasOwnProperty('call_end') &&
                payloadDataData.hasOwnProperty('room_id')
              ) {
                // console.log(this.sponsor)
                // this.sponsor = item.nick
                if (!payloadData.inviteeList.includes(this.userID)) {
                  return
                }
                // 接收到音视频请求
                let isFromGroup = payloadData.groupID && payloadData.groupID.length > 0 ? true : false
                let conversationID = ''
                if (!isFromGroup) {
                  // conversationID = `C2C${this.sponsor}`
                  conversationID = item.conversationID
                } else {
                  conversationID = `GROUP${payloadData.groupID}`
                }
                console.log(conversationID)
                if(item.conversationType !=='GROUP'){
                  if (this.callStatus === 'connected') {
                    // 正在通话中，阻止其他通话
                    return
                  }
                }
                this.selectConversation(conversationID) // 切换当前会话页
                this.$store.dispatch(
                  'video/updateCallComponentType',
                  payloadDataData['call_type'] === 1 ? 'voice' : 'video'
                )
                this.$store.dispatch('video/updateGroupChat', isFromGroup)
                this.$store.dispatch('video/updateIsInviter', false)
                this.$store.dispatch('video/updateCallStatus', 'calling')
              }
            }
          }
        })
      }
    },
    onError({ data }) {
      if (data.message !== 'Network Error') {
        this.$store.commit('showMessage', {
          message: data.message,
          type: 'error',
        })
      }
    },
    onMessageReadByPeer() {},
    onReadyStateUpdate({ name }) {
      const isSDKReady = name === this.TIM.EVENT.SDK_READY ? true : false
      this.$store.dispatch('user/toggleIsSDKReady', isSDKReady)
      if (isSDKReady) {
        this.tim
          .getMyProfile()
          .then(({ data }) => {
            this.$store.commit('user/updateCurrentUserProfile', data)
          })
          .catch((error) => {
            this.$store.commit('showMessage', {
              type: 'error',
              message: error.message,
            })
          })
      }
    },
    kickedOutReason(type) {
      switch (type) {
        case this.TIM.TYPES.KICKED_OUT_MULT_ACCOUNT:
          return '由于多实例登录'
        case this.TIM.TYPES.KICKED_OUT_MULT_DEVICE:
          return '由于多设备登录'
        case this.TIM.TYPES.KICKED_OUT_USERSIG_EXPIRED:
          return '由于 userSig 过期'
        default:
          return ''
      }
    },
    checkoutNetState(state) {
      switch (state) {
        case this.TIM.TYPES.NET_STATE_CONNECTED:
          return { message: '已接入网络', type: 'success' }
        case this.TIM.TYPES.NET_STATE_CONNECTING:
          return { message: '当前网络不稳定', type: 'warning' }
        case this.TIM.TYPES.NET_STATE_DISCONNECTED:
          return { message: '当前网络不可用', type: 'error' }
        default:
          return ''
      }
    },
    onNetStateChange(event) {
      this.$store.commit('showMessage', this.checkoutNetState(event.data.state))
    },
    onKickOut(event) {
      this.$store.commit('showMessage', {
        message: `${this.kickedOutReason(event.data.type)}被踢出，请重新登录。`,
        type: 'error',
      })
      this.$store.dispatch('user/toggleIsLogin', false)
      this.$store.dispatch('user/reset')
      this.$store
        .dispatch('user/loginOut')
        .then((res) => {
          // this.$router.push('/login')
          window.location.reload()
        })
        .catch((err) => {
          this.$store.commit('showMessage', {
            message: err.msg,
            type: 'error',
          })
        })
    },
    // 过滤掉直播群消息
    filterPassLiveMessage(messageList) {
      let arr = []
      if (messageList.length) {
        arr = messageList.filter((i) => i.subType !== 'AVChatRoom')
      }
      return arr
    },
    // 过滤掉系统消息
    filterPassSystemMessage(messageList) {
      let arr = []
      if (messageList.length) {
        arr = messageList.filter((i) => i.type !== '@TIM#SYSTEM')
      }
      return arr
    },
    onUpdateConversationList(event) {
      console.log('onUpdateConversationList', event)
      let messageList = []
      // 过滤掉系统消息
      messageList = this.filterPassSystemMessage(event.data)
      // 过滤掉直播群
      messageList = this.filterPassLiveMessage(messageList)
      this.$store.commit('updateConversationList', messageList)
    },
    onUpdateGroupList(event) {
      console.log('onUpdateGroupList', event.data)
      // 过滤掉直播群
      let list = event.data.filter((i) => i.subType !== 'AVChatRoom')
      this.$store.dispatch('group/updateGroupList', list)
    },
    onReceiveGroupSystemNotice(event) {
      const isKickedout = event.data.type === 4
      const isCurrentConversation =
        `GROUP${event.data.message.payload.groupProfile.groupID}` ===
        this.currentConversation.conversationID
      // 在当前会话被踢，需reset当前会话
      if (isKickedout && isCurrentConversation) {
        this.$store.commit('resetCurrentConversation')
      }
      Notification({
        title: '新系统通知',
        message: translateGroupSystemNotice(event.data.message),
        duration: 3000,
        onClick: () => {
          const SystemConversationID = '@TIM#SYSTEM'
          this.$store.dispatch('checkoutConversation', SystemConversationID)
        },
      })
    },
    /**
     * 处理 @ 我的消息
     * @param {Message[]} messageList
     */
    handleAt(messageList) {
      // 筛选有 @ 符号的文本消息
      const atTextMessageList = messageList.filter(
        (message) =>
          message.type === this.TIM.TYPES.MSG_TEXT &&
          message.payload.text.includes('@')
      )
      for (let i = 0; i < atTextMessageList.length; i++) {
        const message = atTextMessageList[i]
        const matched = message.payload.text.match(/@\w+/g)
        if (!matched) {
          continue
        }
        // @ 我的
        if (matched.includes(`@${this.currentUserProfile.userID}`)) {
          // 当前页面不可见时，调用window.Notification接口，系统级别通知。
          if (this.$store.getters.hidden) {
            this.notifyMe(message)
          }
          Notification({
            title: `有人在群${message.conversationID.slice(5)}提到了你`,
            message: message.payload.text,
            duration: 3000,
          })
          this.$bus.$emit('new-messsage-at-me', {
            data: { conversationID: message.conversationID },
          })
        }
      }
    },
    selectConversation(conversationID) {
      // 屏蔽错误
      if (!['C2C', 'GROUP'].includes(conversationID)) {
        if (conversationID !== this.currentConversation.conversationID) {
          this.$store.dispatch('checkoutConversation', conversationID)
        }
      }
    },
    /**
     * 使用 window.Notification 进行全局的系统通知
     * @param {Message} message
     */
    notifyMe(message) {
      // 需检测浏览器支持和用户授权
      if (!('Notification' in window)) {
        return
      } else if (window.Notification.permission === 'granted') {
        this.handleNotify(message)
      } else if (window.Notification.permission !== 'denied') {
        window.Notification.requestPermission().then((permission) => {
          // 如果用户同意，就可以向他们发送通知
          if (permission === 'granted') {
            this.handleNotify(message)
          }
        })
      }
    },
    handleNotify(message) {
      const notification = new window.Notification('有人提到了你', {
        icon: 'https://webim-1252463788.file.myqcloud.com/demo/img/logo.dc3be0d4.png',
        body: message.payload.text,
      })
      notification.onclick = () => {
        window.focus()
        this.$store.dispatch('checkoutConversation', message.conversationID)
        notification.close()
      }
    },
    handleLinkClick() {
      MTA.clickStat('link_two', { click: 'true' })
    },
    /**
     * 收到有群成员退群/被踢出的groupTip时，需要将相关群成员从当前会话的群成员列表中移除
     * @param {Message[]} messageList
     */
    handleQuitGroupTip(messageList) {
      // 筛选出当前会话的退群/被踢群的 groupTip
      const groupTips = messageList.filter((message) => {
        return (
          this.currentConversation.conversationID === message.conversationID &&
          message.type === this.TIM.TYPES.MSG_GRP_TIP &&
          (message.payload.operationType === this.TIM.TYPES.GRP_TIP_MBR_QUIT ||
            message.payload.operationType ===
              this.TIM.TYPES.GRP_TIP_MBR_KICKED_OUT)
        )
      })
      // 清理当前会话的群成员列表
      if (groupTips.length > 0) {
        groupTips.forEach((groupTip) => {
          if (
            Array.isArray(groupTip.payload.userIDList) ||
            groupTip.payload.userIDList.length > 0
          ) {
            this.$store.dispatch(
              'group/deleteGroupMemberList',
              groupTip.payload.userIDList
            )
          }
        })
      }
    },
    // 监听trtcCalling 事件
    initListenerOfTrtcCalling: function () {
      // sdk内部发生了错误
      this.$trtcCalling.on(this.TrtcCalling.EVENT.ERROR, this.handleError)
      // 事件接收方为：被邀方 。说明：收到了邀请通知
      this.$trtcCalling.on(
        this.TrtcCalling.EVENT.INVITED,
        this.handleNewInvitationReceived,
      )
      // 事件接收方为：邀请方和被邀请方 。说明：用户进入房间
      this.$trtcCalling.on(
        this.TrtcCalling.EVENT.USER_ENTER,
        this.handleUserEnter
      )
      // 事件接收方为：邀请方和被邀请方 。说明：用户离开房间
      this.$trtcCalling.on(
        this.TrtcCalling.EVENT.USER_LEAVE,
        this.handleUserLeave
      )
      // 事件接收方为：邀请方。说明：被邀用户拒绝通话
      this.$trtcCalling.on(
        this.TrtcCalling.EVENT.REJECT,
        this.handleInviteeReject
      )
      // 事件接收方为：邀请方。说明：被邀用户正在通话中，忙线
      this.$trtcCalling.on(
        this.TrtcCalling.EVENT.LINE_BUSY,
        this.handleInviteeLineBusy
      )
      // 事件接收方为：被邀请方。说明：本次通话被取消了
      this.$trtcCalling.on(
        this.TrtcCalling.EVENT.CALLING_CANCEL,
        this.handleInviterCancel
      )
      // 事件接收方为：被邀请方。说明：本次通话超时未应答
      this.$trtcCalling.on(
        this.TrtcCalling.EVENT.CALLING_TIMEOUT,
        this.handleCallTimeout
      )
      // 事件接收方为：邀请方。说明：被邀用户超时无应答
      this.$trtcCalling.on(
        this.TrtcCalling.EVENT.NO_RESP,
        this.handleNoResponse
      )
      //事件接收方为：邀请方和被邀方。说明：本次通话结束了
      this.$trtcCalling.on(
        this.TrtcCalling.EVENT.CALLING_END,
        this.handleCallEnd
      )
      // 事件接收方为：邀请方和被邀方。说明：远端用户开启/关闭了摄像头
      this.$trtcCalling.on(
        this.TrtcCalling.EVENT.USER_VIDEO_AVAILABLE,
        this.handleUserVideoChange
      )
      // 事件接收方为：邀请方和被邀方。说明：远端用户开启/关闭了麦克风
      this.$trtcCalling.on(
        this.TrtcCalling.EVENT.USER_AUDIO_AVAILABLE,
        this.handleUserAudioChange
      )
      //群聊更新邀请列表收到该回调
      this.$trtcCalling.on(
        this.TrtcCalling.EVENT.GROUP_CALL_INVITEE_LIST_UPDATE,
        this.handleGroupInviteeListUpdate
      )
    },
    removeListenerOfTrtcCalling: function () {
      this.$trtcCalling.off(this.TrtcCalling.EVENT.ERROR, this.handleError)
      this.$trtcCalling.off(
        this.TrtcCalling.EVENT.INVITED,
        this.handleNewInvitationReceived
      )

      this.$trtcCalling.off(
        this.TrtcCalling.EVENT.USER_ENTER,
        this.handleUserEnter
      )
      this.$trtcCalling.off(
        this.TrtcCalling.EVENT.USER_LEAVE,
        this.handleUserLeave
      )
      this.$trtcCalling.off(
        this.TrtcCalling.EVENT.REJECT,
        this.handleInviteeReject
      )
      this.$trtcCalling.off(
        this.TrtcCalling.EVENT.LINE_BUSY,
        this.handleInviteeLineBusy
      )
      this.$trtcCalling.off(
        this.TrtcCalling.EVENT.CALLING_CANCEL,
        this.handleInviterCancel
      )
      this.$trtcCalling.off(
        this.TrtcCalling.EVENT.CALLING_TIMEOUT,
        this.handleCallTimeout
      )
      this.$trtcCalling.off(
        this.TrtcCalling.EVENT.NO_RESP,
        this.handleNoResponse
      )
      this.$trtcCalling.off(
        this.TrtcCalling.EVENT.CALLING_END,
        this.handleCallEnd
      )
      this.$trtcCalling.off(
        this.TrtcCalling.EVENT.USER_VIDEO_AVAILABLE,
        this.handleUserVideoChange
      )
      this.$trtcCalling.off(
        this.TrtcCalling.EVENT.USER_AUDIO_AVAILABLE,
        this.handleUserAudioChange
      )
      this.$trtcCalling.off(
         this.TrtcCalling.EVENT.GROUP_CALL_INVITEE_LIST_UPDATE,
         this.handleGroupInviteeListUpdate
      )
    },
    handleError: function () {},
    // 被邀请者收到语音/视频邀请时会触发
    handleNewInvitationReceived: async function ({sponsor, userIDList, isFromGroup, inviteData, inviteID}) {
      console.log('事件接收方为：被邀方 。说明：收到了邀请通知', sponsor, userIDList, isFromGroup, inviteData, inviteID)
      console.log('%c INVITED', 'color:red')
      // const { inviteID, sponsor, inviteData ,isFromGroup, } = payload
      if (inviteData.callEnd) {
        // 最后一个人发送 invite 进行挂断`
        this.$store.dispatch('video/updateCallComponentType', '')
        this.$store.dispatch('video/updateCallStatus', 'idle')
        return
      }
      if (sponsor === this.userID) {
        // 邀请人是自己, 同一个账号有可能在多端登录
        return
      }
      // 这里需要考虑忙线的情况
      if (this.callStatus === 'connected') {
        await this.$trtcCalling.reject()
        return
      }
      this.$store.dispatch('app/setMsgType', 'calling')
      this.$bus.$emit('msgVoiceTip')
      this.inviteData = inviteData
      this.inviteID = inviteID
      this.sponsor = sponsor
    },
    // 当前登录人--拒绝接听
    handleRejectCall: async function () {
      console.log('拒绝接听')
      try {
        const { callType } = this.inviteData
        await this.$trtcCalling.reject().then(() => {this.refreshConversationMessageList()
            this.dissolveMeetingIfNeed('rejectCall')
          })
      } catch (e) {
        this.dissolveMeetingIfNeed('rejectCall')
      }
    },
    handleAccept: debounce(function () {
      this.handleAcceptCall()
    }, 500),
    // 接听
    handleAcceptCall: async function () {
      await this.$store.dispatch('loading/open')
      console.log('handleAcceptCall', this.inviteData)
      try {
        const { callType, roomID } = this.inviteData
        this.$store.dispatch('video/userJoinMeeting', this.currentUserProfile)
        await this.$trtcCalling.accept().then(() => {
            this.$store.dispatch('app/setMsgType', 'cancelCalling')
            this.$bus.$emit('msgVoiceTip')
            this.refreshConversationMessageList()
            this.$store.dispatch('video/updateCallStatus', 'connected')
          })
          .catch(() => {
            this.dissolveMeetingIfNeed('acceptError')
          })
      } catch (e) {
        this.dissolveMeetingIfNeed('acceptError')
      }
      await this.$store.dispatch('loading/close')
    },
    // 被邀请者进入房间前对用户信息读取策略
    // 单个用户获取
    beforeUserEnterGetUserProfile(userID) {
      return new Promise((resolve, reject) => {
        this.getUserProfile([userID])
          .then((userProfiles) => {
            console.log('beforeUserEnterGetUserProfile', userProfiles[0])
            resolve(userProfiles[0])
          })
          .catch((err) => {
            console.log('beforeUserEnterGetUserProfile is error', err)
            reject()
          })
      })
    },
    // 被邀请者同意进入通话
    // 进入通话中状态 callStatus = connected
    handleUserEnter: async function ({ userID }) {
      console.log('事件接收方为：被邀请方 。说明：用户进入房间', userID)
      console.log('%c USER_ENTER', 'color:red')
      let userProfile = {}
      await this.beforeUserEnterGetUserProfile(userID).then((userPro) => {
          userProfile = userPro
          this.$store.dispatch('video/userJoinMeeting', userProfile)
      })
      // this.$store.dispatch('video/userJoinMeeting', userProfile)
      this.$store.dispatch('app/setMsgType', 'cancelCalling')
      this.$bus.$emit('msgVoiceTip')
      if (this.callStatus === 'calling') {
        this.$store.dispatch('video/updateCallStatus', 'connected')
      } else {
        // 第n (n >= 3)个人被邀请入会, 并且他不是第 n 个人的邀请人
        this.$nextTick(() => {
          // 需要先等远程用户 id 的节点渲染到 dom 上
          if (this.callComponentType === 'video') {
            this.$trtcCalling.startRemoteView({
              userID: userID,
              videoViewDomID: `video-${userID}`,
            })
          } else if (this.callComponentType === 'voice') {
            // this.$trtcCalling.startRemoteView({
            //   userID: userID,
            //   videoViewDomID: `voice-${userID}`,
            // })
          }
        })
      }
    },
    // 用户离开通话
    handleUserLeave: function ({ userID }) {
      console.log('事件接收方为：邀请方和被邀请方 。说明：用户离开房间', userID)
      console.log('%c USER_LEAVE', 'color:red')
      if (this.meetingUserList.length == 2) {
        this.$store.dispatch('video/updateCallComponentType', '')
        this.$store.dispatch('video/updateCallStatus', 'idle')
        document.getElementById('chatBox').style="z-index:1;"
      }
      this.$store.dispatch('video/userLeaveMeeting', userID)
    },
    // 被邀请者拒绝通话
    handleInviteeReject: async function (event) {
      console.log('拒绝接听', event)
      console.log('%c REJECT', 'color:red')
      let userID = event.userID
      console.log('事件接收方为：邀请方。说明：被邀用户拒绝通话', userID)
      this.refreshConversationMessageList()
      let userProfile = {}
      await this.beforeUserEnterGetUserProfile(userID).then((userPro) => {
        userProfile = userPro
      })
      this.$Message.warning(`${userProfile.nick}拒绝通话`)
      if (this.isFromGroup) {
        let memberList = this.inviteeListByReject.filter(
          (i) => i.userID !== userID
        )
        this.$store.dispatch('video/updateInviteeListByReject', memberList)
      }
      this.dissolveMeetingIfNeed('reject')
    },
    // 被邀请者忙线
    handleInviteeLineBusy: async function ({ userID }) {
      console.log(
        '事件接收方为：邀请方。说明：被邀用户正在通话中，忙线',
        userID
      )
      console.log('%c LINE_BUSY', 'color:red')
      if (userID === this.userID) {
        return
      }
      this.refreshConversationMessageList()
      let userProfile = {}
      await this.beforeUserEnterGetUserProfile(userID).then((userPro) => {
        userProfile = userPro
      })
      this.$Message.warning(`${userProfile.nick}忙线`)
      this.dissolveMeetingIfNeed('lineBusy')
    },
    handleInviterCancel: function (event) {
      console.log('事件接收方为：被邀请方。说明：本次通话被取消了')
      console.log('%c CALLING_CANCEL', 'color:red')
      // 邀请被取消
      this.refreshConversationMessageList()
      this.$nextTick(() => {
        this.$store.dispatch('video/updateCallComponentType', '')
        this.$store.dispatch('video/updateCallStatus', 'idle')
        this.$Message.warning('通话已取消')
        this.dissolveMeetingIfNeed('callingCancel')
      })
    },
    // 作为被邀请方会收到，收到该回调说明本次通话超时未应答
    // 当自己收到对端超时的信令时，或者当我是被邀请者但自己超时了，通知上层通话超时
    // case: A呼叫B，B在线，B超时未响应，B会触发该事件，A也会触发该事件
    handleCallTimeout: function (event) {
      let { sponsor, userIDList } = event
      console.log('事件接收方为：被邀请方。说明：本次通话超时未应答', event)
      console.log('%c CALLING_TIMEOUT', 'color:red')
      this.$nextTick(async () => {
        this.refreshConversationMessageList()
        if (this.isFromGroup) {
          let userProfile = {}
          await this.beforeUserEnterGetUserProfile(userIDList[0]).then(
            (userPro) => {
              userProfile = userPro
            }
          )
          this.$Message.warning(`${userProfile.nick}通话超时未应答`)
          this.dissolveMeetingIfNeed('callingTimeout', userIDList)
        } else {
          this.$store.dispatch('video/updateCallComponentType', '')
          this.$store.dispatch('video/updateCallStatus', 'idle')
          this.$Message.warning('通话超时未应答')
          this.dissolveMeetingIfNeed('callingTimeout')
        }
      })
    },
    // 两种场景：
    // 1.当您处于通话中，可以调用该函数结束通话。
    // 2.当未拨通时, 可用来取消通话。
    handleCallEnd: debounce(async function () {
      console.log('事件接收方为：邀请方和被邀方。说明：本次通话结束了')
      console.log('%c CALLING_END', 'color:red')
      await this.$trtcCalling.hangup().then(() => {
          console.log('guaduan')
        this.$store.dispatch('video/updateCallStatus', 'idle')
        this.$store.dispatch('video/updateIsInviter', false)
        // this.$store.dispatch('video/updateCallComponentType', '')
        })
        .catch(() => {})
      this.refreshConversationMessageList()
      this.dissolveMeetingIfNeed('callEnd')
    }, 500),
    // 被邀请用户无应答
    handleNoResponse: async function (event) {
      let { sponsor, userIDList } = event
      console.log('事件接收方为：邀请方。说明：被邀用户超时无应答', sponsor,event)
      console.log('%c NO_RESP', 'color:red')
      if (userIDList.includes(this.userID)) {
        return
      }
      this.refreshConversationMessageList()
      if (this.isFromGroup) {
        let userProfile = {}
        await this.beforeUserEnterGetUserProfile(userIDList[0]).then(
          (userPro) => {
            userProfile = userPro
          }
        )
        this.$Message.warning(`${userProfile.nick}无应答`)
      } else {
        this.$Message.warning(`无应答`)
      }
      this.dissolveMeetingIfNeed('noResp',userIDList)
    },
    // 远端用户开启/关闭了摄像头, 会收到该回调
    handleUserVideoChange: function ({ userID, isVideoAvailable }) {
      if (isVideoAvailable) {
        const muteUserList = this.muteVideoUserIdList.filter(
          (currentID) => currentID !== userID
        )
        this.$store.dispatch('video/updateMuteVideoUserIdList', muteUserList)
      } else {
        const muteUserList = this.muteVideoUserIdList.concat(userID)
        this.$store.dispatch('video/updateMuteVideoUserIdList', muteUserList)
      }
    },
    // 远端用户开启/关闭了麦克风, 会收到该回调
    handleUserAudioChange: function ({ userID, isAudioAvailable }) {
      if (isAudioAvailable) {
        const muteUserList = this.muteAudioUserIdList.filter(
          (currentID) => currentID !== userID
        )
        this.$store.dispatch('video/updateMuteAudioUserIdList', muteUserList)
      } else {
        const muteUserList = this.muteAudioUserIdList.concat(userID)
        this.$store.dispatch('video/updateMuteAudioUserIdList', muteUserList)
      }
    },
    // 重置layer音视频数据
    resetLayer() {
      this.$store.dispatch('app/setMsgType', 'holdUp')
      this.$bus.$emit('msgVoiceTip')
      this.$store.dispatch('video/updateCallComponentType', '')
      this.$store.dispatch('video/updateCallStatus', 'idle')
      this.$store.dispatch('video/dissolveMeeting')
      this.$store.dispatch('video/updateInviteeListByReject', [])
      this.$forceUpdate()
    },
    async dissolveMeetingIfNeed(eventType, userIDList) {
      // userIDList：群聊通话超时人员列表【含当前登录人】
      if (this.isFromGroup) {
        // 群组通话
        if (eventType === 'callEnd') {
          // 监听到trtcCalling CALL_END事件时
          this.resetLayer()
        } else if (eventType === 'callingTimeout') {
          if (userIDList.includes(this.userID)) {
            // 当前登录人超时未接听时
            this.resetLayer()
          }
        } else if (eventType === 'rejectCall') {
          // 当前登录人拒绝接听
          this.resetLayer()
        } else if (eventType === 'reject') {
          if (this.inviteeListByReject.length === 1) {
            await this.$trtcCalling.hangup()
            this.resetLayer()
          }
        } else if (eventType === 'callingCancel') {
          // 取消通话
          this.resetLayer()
        }else if(eventType === 'noResp'){
          if(userIDList.length==0){
            // this.resetLayer()
          }
        }
      } else {
        // 好友通话
        this.resetLayer()
      }
    },
    handleGroupInviteeListUpdate(event){
      console.log(event)
    }
  },
}
</script>
<style lang="stylus">
.chat-container {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 10000;
  text-align: left;
  display: none;
  background: rgba(0, 0, 0, 0.4);
}

// TODO filter mac chrome 会有问题，下次修改可以去掉
.loading {
  width: 100vw;
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 10200;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size :14px;
}

.chat-wrapper {
  top: 8vh;
  width: 52vw;
  height: $height;
  max-width: 1280px;
  box-shadow: 0 11px 20px 0 rgba(0, 0, 0, 0.3);

  .official-link {
    display: flex;
    text-decoration: none;
    color: #38c9ff;
    width: fit-content;
    float: right;
    height: 45px;
    align-items: center;
  }
}

/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

/* 滚动槽 */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
}
</style>
