const HTCSWebLiveStream = () =>
  import( /* webpackChunkName: "HTCSWebLiveStream" */ "@/views/HTCSWeb/HTCSWebLiveStream")
const HTCSWebLiveStreamOfAdd = () =>
  import( /* webpackChunkName: "HTCSWebLiveStreamOfAdd" */ "@/views/HTCSWeb/HTCSWebLiveStream/HTCSWebLiveStreamOfAdd")
const HTCSWebLiveStreamOfEdit = () =>
  import( /* webpackChunkName: "HTCSWebLiveStreamOfEdit" */ "@/views/HTCSWeb/HTCSWebLiveStream/HTCSWebLiveStreamOfEdit")
const HTCSWebLiveStreamOfEvaluate = () =>
  import( /* webpackChunkName: "HTCSWebLiveStreamOfEvaluate" */ "@/views/HTCSWeb/HTCSWebLiveStream/HTCSWebLiveStreamOfEvaluate")
const HTCSWebLiveStreamOfAudience = () =>
  import( /* webpackChunkName: "HTCSWebLiveStreamOfEdit" */ "@/views/HTCSWeb/HTCSWebLiveStream/HTCSWebLiveStreamOfUser")

export default [
  {
    path: "list",
    name: "HTCSWebLiveStream",
    component: HTCSWebLiveStream,
    meta: { title: "直播管理" }
  },
  {
    path: "add",
    name: "HTCSWebLiveStreamOfAdd",
    component: HTCSWebLiveStreamOfAdd,
    meta: { title: "新增直播", noCache: true }
  },
  {
    path: "edit/:id",
    name: "HTCSWebLiveStreamOfEdit",
    component: HTCSWebLiveStreamOfEdit,
    meta: { title: "修改直播", noCache: true }
  },
  {
    path: "evaluate/:id",
    name: "HTCSWebLiveStreamOfEvaluate",
    component: HTCSWebLiveStreamOfEvaluate,
    meta: { title: "评价管理", noCache: true }
  },
  {
    path: "audience/:id",
    name: "HTCSWebLiveStreamOfAudience",
    component: HTCSWebLiveStreamOfAudience,
    meta: { title: "观看用户", noCache: true }
  },
]