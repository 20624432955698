/* global $ TRTC getCameraId getMicrophoneId resetView isHidden shareUserId addMemberView removeView addVideoView setAnimationFrame clearAnimationFrame*/
import TRTC from 'trtc-js-sdk'
export default class RtcClient {
  constructor(options) {
    this.sdkAppId_ = options.sdkAppId;
    this.userId_ = options.userId;
    this.userSig_ = options.userSig;
    this.roomId_ = options.roomId;

    this.isJoined_ = false;
    this.isPublished_ = false;
    this.isAudioMuted = false;
    this.isVideoMuted = false;
    this.localStream_ = null;
    this.remoteStreams_ = [];
    this.members_ = new Map();
    this.getAudioLevelTimer_ = -1;

    // create a client for RtcClient
    this.client_ = TRTC.createClient({
      mode: 'rtc',
      sdkAppId: this.sdkAppId_,
      userId: this.userId_,
      userSig: this.userSig_
    });
    this.handleEvents();
  }

  async join () {
    if (this.isJoined_) {
      console.warn('duplicate RtcClient.join() observed');
      return;
    }
    try {
      // join the room
      await this.client_.join({
        roomId: parseInt(this.roomId_)
      });
      console.log('join room success');
      this.isJoined_ = true;
      // create a local stream with audio/video from microphone/camera

      // not to specify cameraId/microphoneId to avoid OverConstrainedError
      this.localStream_ = TRTC.createStream({
        audio: true,
        video: true,
        userId: this.userId_,
        mirror: true
      });
      try {
        // initialize the local stream and the stream will be populated with audio/video
        this.localStream_.setScreenProfile('720p');
        await this.localStream_.initialize();
        console.log('initialize local stream success');

        this.localStream_.on('player-state-changed', event => {
          console.log(`local stream ${event.type} player is ${event.state}`);
        });
      } catch (error) {
        console.error('failed to initialize local stream - ' + error);
        switch (error.name) {
          case 'NotReadableError':
            alert(
              '暂时无法访问摄像头/麦克风，请确保系统允许当前浏览器访问摄像头/麦克风，并且没有其他应用占用摄像头/麦克风'
            );
            return;
          case 'NotAllowedError':
            if (error.message === 'Permission denied by system') {
              alert('请确保系统允许当前浏览器访问摄像头/麦克风');
            } else {
              console.log('User refused to share the screen');
            }
            return;
          case 'NotFoundError':
            alert(
              '浏览器获取不到摄像头/麦克风设备，请检查设备连接并且确保系统允许当前浏览器访问摄像头/麦克风'
            );
            return;
          default:
            return;
        }
      }

      try {
        // publish the local stream
        await this.publish();
        this.localStream_.play(`video-meeting_${store.state.user.userID}`, { objectFit: 'cover' });

      } catch (error) {
        console.error('failed to publish local stream - ', error);
      }

      // 开始获取音量
      this.startGetAudioLevel();
    } catch (error) {
      console.error('join room failed! ' + error);
    }
    //更新成员状态
    let states = this.client_.getRemoteMutedState();
    for (let state of states) {
      if (state.audioMuted) {

      }
      if (state.videoMuted) {

      }
    }
  }

  async leave () {
    if (!this.isJoined_) {
      console.warn('leave() - please join() firstly');
      return;
    }
    // ensure the local stream is unpublished before leaving.
    await this.unpublish();

    // leave the room
    await this.client_.leave();

    this.localStream_.stop();
    this.localStream_.close();
    this.localStream_ = null;
    this.isJoined_ = false;
    // 停止获取音量
    this.stopGetAudioLevel();
  }

  async publish () {
    if (!this.isJoined_) {
      console.warn('publish() - please join() firstly');
      return;
    }
    if (this.isPublished_) {
      console.warn('duplicate RtcClient.publish() observed');
      return;
    }
    try {
      await this.client_.publish(this.localStream_);
    } catch (error) {
      console.error('failed to publish local stream ' + error);
      this.isPublished_ = false;
    }

    this.isPublished_ = true;
  }

  async unpublish () {
    if (!this.isJoined_) {
      console.warn('unpublish() - please join() firstly');
      return;
    }
    if (!this.isPublished_) {
      console.warn('RtcClient.unpublish() called but not published yet');
      return;
    }

    await this.client_.unpublish(this.localStream_);
    this.isPublished_ = false;
  }
  // 禁用音频轨道
  muteLocalAudio () {
    this.localStream_.muteAudio();
  }
  // 启用音频轨道
  unmuteLocalAudio () {
    this.localStream_.unmuteAudio();
  }
  // 禁用视频轨道
  muteLocalVideo () {
    this.localStream_.muteVideo();
  }
  // 启用视频轨道
  unmuteLocalVideo () {
    this.localStream_.unmuteVideo();
  }
  // 恢复播放音视频
  resumeStreams () {
    this.localStream_.resume();
    for (let stream of this.remoteStreams_) {
      stream.resume();
    }
  }

  handleEvents () {
    this.client_.on('error', err => {
      console.error(err);
      // alert(err);
      // location.reload();
    });
    this.client_.on('client-banned', err => {
      console.error('client has been banned for ' + err);
    });

    this.client_.on('stream-updated', evt => {
      console.log('stream-updated', evt);
    });
  }

  showStreamState (stream) {
    console.log('has audio: ' + stream.hasAudio() + ' has video: ' + stream.hasVideo());
  }

  startGetAudioLevel () {
    // 监听音量回调事件，更新每个用户的音量图标
    this.client_.on('audio-volume', ({ result }) => {
      result.forEach(({ userId, audioVolume }) => {
        if (audioVolume >= 10) {
          console.warn(`userId: ${userId} is speaking audioVolume: ${audioVolume}`);
        } else {

        }
      });
    });
    this.client_.enableAudioVolumeEvaluation(100);
  }

  // 停止获取流音量
  stopGetAudioLevel () {
    this.client_.enableAudioVolumeEvaluation(-1);
  }
}
