<template>
  <my-drag :class="shrink===true?'call-container shrink-box':'call-container'" v-if="showCallLayer"  target="#app">
    <!-- 拨号中：1.拨号中 2.被呼叫中 begin -->
    <div
      v-if="callStatus==='calling'"
      class="calling-container"
      style="display:flex;flex-direction:column;"
    >
      <p v-if="!isInviter" class="call-title">来自{{displayTitle}}的语音电话</p>
      <p v-else class="call-title">正在拨打语音电话中...</p>
      <div class="call-operator">
        <template v-if="!isInviter">
          <el-button style="width:120px" type="success" @click="handleAcceptCall">接 听</el-button>
          <el-button style="width:120px" type="danger" @click="handleRefuseCall">拒 绝</el-button>
        </template>
        <template v-else>
          <el-button style="width:120px" type="danger" @click="handleHandleUpCall">挂 断</el-button>
        </template>
      </div>
    </div>
    <!-- 拨号中：1.拨号中 2.被呼叫中 end -->
    <!-- 通话中 begin -->
    <div v-if="callStatus==='connected'" class="connected-container" v-on:mouseover="changeActive($event)" v-on:mouseout="removeActive($event)">
      <!-- 单聊 begin -->
      <template v-if="!isFromGroup">
        <div class="call">
          <div class="shrink" @click="shrinkBtn">一</div>
          <template v-for="userProfile in meetingUserList">
            <div
              :key="`voice-${userProfile.userID}_${randomNum}`"
              :id="`voice-${userProfile.userID}`"
              v-if="userProfile.userID!==userID"
            >
              <div class="voice-call-ui" id="voiceCallUi">
                <el-image
                  :src="userProfile.avatar"
                  fit="cover"
                  style="width:200px;height:200px;border-radius:50%;"
                ></el-image>
                <p class="call-title" id="calTitle">正在通话中...</p>
              </div>
            </div>
          </template>
          <div class="user-operator" v-show="mouseShow">
            <div class="duration">{{ formatDurationStr }}</div>
            <div style="text-align: center;cursor: pointer;margin-left:20px;" @click="toggleAudio">
              <img v-if="isAudioOn" src="../../../assets/tweblive/mic-on.png" alt="">
              <img v-else src="../../../assets/tweblive/mic-off.png" alt="">
              <p style="color:#2c2c2c;font-size: 14px;">静音</p>
            </div>
<!--            <el-button-->
<!--                      type="success"-->
<!--                      style="width:120px"-->
<!--                      @click="toggleAudio"-->
<!--            >{{isAudioOn ? '关闭麦克风' : '打开麦克风'}}</el-button>-->
            <el-button style="width:120px;margin-right: 20px;" type="danger" @click="handleHangup">挂断</el-button>
          </div>
        </div>
      </template>
      <!-- 单聊 end -->
      <!-- 群聊 begin -->
      <template v-else>
        <div class="shrink" @click="shrinkBtn">一</div>
        <p style="font-size:16px;color:#fff;height:60px;text-indent:32px;line-height:60px;text-align: center;">群语音聊天：{{displayTitle}}</p>
        <CurrentInvite  :dialogVisible="dialogVisible"  @show="showFather"/>
        <div class="group-call">
          <div
            class="group-call-container"
            v-for="userProfile in meetingUserList"
            :key="`voice-${userProfile.userID}_${randomNum}`"
            :id="`voice-${userProfile.userID}`"
          >
            <div class="group-voice-call-ui">
              <el-image
                :src="userProfile.avatar"
                fit="cover"
                style="width:136px;height:136px;border-radius:50%;"
              ></el-image>
              <div
                :class="{'user-audio-status': true, 'is-mute': isUserMute(muteAudioUserIdList, userProfile.userID)}"
              ></div>
              <p class="user-nick">{{ userProfile.nick || userProfile.userID}}</p>
            </div>
          </div>
          <span class="addUser" @click="inviterUser"></span>
          <div class="user-operator" v-show="mouseShow">
            <div class="duration">{{ formatDurationStr }}</div>
            <div style="text-align: center;cursor: pointer;margin-left:20px;" @click="toggleAudio">
              <img v-if="isAudioOn" src="../../../assets/tweblive/mic-on.png" alt="">
              <img v-else src="../../../assets/tweblive/mic-off.png" alt="">
              <p style="color:#fff;font-size: 14px;">静音</p>
            </div>
<!--            <el-button-->
<!--              type="success"-->
<!--              style="width:120px"-->
<!--              @click="toggleAudio"-->
<!--            >{{isAudioOn ? '关闭麦克风' : '打开麦克风'}}</el-button>-->
            <el-button style="width:120px;margin-right: 20px;" type="danger" @click="handleHangup">挂断</el-button>
          </div>
        </div>
      </template>
      <!-- 群聊 end -->
    </div>
    <!-- 通话中 end -->
  </my-drag>
</template>

<script>
import { mapState } from 'vuex'
import { formatDuration } from '@/utils/formatDuration'
import { random } from '@/utils'
import trtcCalling from './../mixins/trtcCalling'
import { MyDrag } from '$ui'
import CurrentInvite from '../message/current-invite'
export default {
  name: 'CallLayerVoice',
  mixins: [trtcCalling],
  components: {
    // SearchUser,
    MyDrag,
    CurrentInvite,
  },
  computed: {
    ...mapState({
      userID: (state) => state.user.userID,
      currentConversation: (state) => state.conversation.currentConversation,
      callStatus: (state) => state.video.callStatus,
      isInviter: (state) => state.video.isInviter,
      meetingUserList: (state) => state.video.meetingUserList,
      muteVideoUserIdList: (state) => state.video.muteVideoUserIdList,
      muteAudioUserIdList: (state) => state.video.muteAudioUserIdList,
      //
      conversationList: (state) => state.conversation.conversationList,
      callComponentType: (state) => state.video.callComponentType,
      isFromGroup: (state) => state.video.isFromGroup,
    }),
    formatDurationStr() {
      return formatDuration(this.duration)
    },
    showCallLayer() {
      return (
        (this.callStatus === 'connected' || this.callStatus === 'calling') &&
        this.callComponentType.length
      )
    },
    displayTitle() {
      let str = ''
      if (this.isFromGroup) {
        str = '群组：' + this.currentConversation?.groupProfile?.name
      } else {
        str = this.currentConversation?.userProfile?.nick
      }
      return str
    },
  },
  data() {
    return {
      isVideoOn: false,
      isAudioOn: true,
      //
      start: 0,
      end: 0,
      duration: 0,
      hangUpTimer: 0, // 通话计时id
      randomNum: 0,
      shrink:false,
      mouseShow:true,
      dialogVisible:false,
    }
  },
  created() {
  },
  mounted() {
    window.addEventListener('beforeunload', (event) => {
      this.handleEndTrtcCalling()
    })
  },
  destroyed() {
    this.handleEndTrtcCalling()
    window.removeEventListener('beforeunload', (event) => {
      this.handleEndTrtcCalling()
    })
  },
  watch: {
    callStatus: function (newStatus, oldStatus) {
      // 作为被邀请者, 建立通话连接
      if (newStatus !== oldStatus && newStatus === 'connected') {
        this.start = new Date()
        clearTimeout(this.hangUpTimer)
        this.resetDuration(0)
        this.randomNum = random()
        this.$nextTick(() => {
          this.startMeeting()
        })
      }
    },
  },
  methods: {
    // 刷新之后结束通话
    handleEndTrtcCalling() {
      this.$store.dispatch('video/updateMuteVideoUserIdList', [])
      this.$store.dispatch('video/updateMuteAudioUserIdList', [])
      if (this.callStatus === 'connected' || this.callStatus === 'calling') {
        this.$trtcCalling.hangup()
        this.$store.dispatch('video/updateCallComponentType', '')
        this.$store.dispatch('video/updateCallStatus', 'idle')
      }
    },
    resetDuration(duration) {
      this.duration = duration
      this.hangUpTimer = setTimeout(() => {
        let now = new Date()
        this.resetDuration(parseInt((now - this.start) / 1000))
      }, 1000)
    },
    startMeeting: function () {
      const otherParticipants = this.meetingUserList.filter(
        (userProfile) => userProfile.userID !== this.userID
      )
      otherParticipants.forEach((userProfile) => {
        // this.$trtcCalling.startRemoteView({
        //   userID: userProfile.userID,
        //   videoViewDomID: `voice-${userProfile.userID}`,
        // })
      })
      console.log('otherParticipants', otherParticipants)
    },

    toggleAudio: function () {
      this.isAudioOn = !this.isAudioOn
      this.$trtcCalling.setMicMute(!this.isAudioOn)
      if (this.isAudioOn) {
        const muteUserList = this.muteAudioUserIdList.filter(
          (userId) => userId !== this.userID
        )
        this.$store.dispatch('video/updateMuteAudioUserIdList', muteUserList)
      } else {
        const muteUserList = this.muteAudioUserIdList.concat(this.userID)
        this.$store.dispatch('video/updateMuteAudioUserIdList', muteUserList)
      }
    },
    isUserMute: function (muteUserList, userId) {
      return muteUserList.indexOf(userId) !== -1
    },
    //拒绝接听
    handleAcceptCall() {
      this.$emit('acceptCall')
    },
    // 接听
    handleRefuseCall() {
      this.$emit('refuseCall')
    },
    // 未接听，发起端挂断
    handleHandleUpCall() {
      this.$emit('holdUpCall')
    },
    //语音通话界面缩小
    shrinkBtn(){
      this.shrink=!this.shrink
      if(this.shrink===true){
        document.getElementById('voiceCallUi').classList.add('margin')
        document.getElementById('calTitle').classList.add('lineHeight')
      }else{
        document.getElementById('voiceCallUi').classList.remove('margin')
        document.getElementById('calTitle').classList.remove('lineHeight')
      }
    },
    //鼠标划入
    changeActive(){
      this.mouseShow = true
    },
    //鼠标划出
    removeActive(){
      this.mouseShow = false
    },
    //邀请群用户
    inviterUser(){
      this.dialogVisible = true
    },
    //父子组件传参
    showFather(a){
      this.dialogVisible = a
    }
  },
}
</script>

<style lang="scss" scoped>
.calling-container,
.connected-container {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
}
.calling-container {
  justify-content: space-around;
}
.call-operator {
  text-align: center;
}
.call {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.8);
  height: 100%;
  width: 100%;
  position: relative;
}
.group-call {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  /*padding: 32px;*/
}
.group-call-container {
  width: 160px;
  height: 160px;
  position: relative;
  margin-right: -20px;
  margin-left: 20px;
}
.call-container {
  position: absolute;
  z-index: 999;
}

.duration {
  color: #fff;
  position: absolute;
  z-index: 20;
  width: 100%;
  bottom: 50px;
  display: flex;
  justify-content: center;
}

.user-operator {
  position: absolute;
  bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  z-index: 20;
}

.user-nick {
  text-align: center;
  color: #fff;
  font-size: 12px;
  position: absolute;
  width: 136px;
  z-index: 10;
  padding-top: 10px;
}
.call-title {
  color: #fff;
  text-align: center;
  font-size: 32px;
  line-height: 200px;
  height: 200px;
}
.voice-call-ui {
  position: relative;
  width: 500px;
  height: 100%;
  margin: 0 auto;
  margin-top: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.group-voice-call-ui {
  position: relative;
  width: 100%;
  height: 100%;
}
.user-audio-status {
  position: absolute;
  right: 44px;
  bottom: 40px;
  width: 22px;
  height: 27px;
  z-index: 30;
  background-image: url('../../../assets/tweblive/mic-on.png');
  background-size: cover;
}
.user-audio-status.is-mute {
  background-image: url('../../../assets/tweblive/mic-off.png');
}
  .shrink{
    position: absolute;
    right:5px;
    top: 5px;
    cursor: pointer;
    color: #fff;
    font-size: 14px;
  }
  .shrink-box{
    position: absolute;
    width: 25%;
    height: 40vh;
    right: 18vw;
    top: 13vh
  ;
  }
  .margin{
    margin-top:15px;
  }
  .lineHeight{
    line-height: 80px;
  }
  .addUser{
    width: 120px;
    height: 120px;
    margin-top: 10px;
    border-radius: 50%;
    background: #515151;
    background-image: url('../../../assets/image/addUser.png');
    background-size: 60%;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    margin-left:20px;
  }
</style>
