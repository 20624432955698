// 人员统计
const TripStatisticsOfStaff = () => import( /* webpackChunkName: "TripStatisticsOfStaff" */ "@/views/TripStatistics/TripStatisticsOfStaff")
const TripStatisticsOfCrew = () => import( /* webpackChunkName: "TripStatisticsOfCrew" */ "@/views/TripStatistics/TripStatisticsOfCrew")
const TripStatisticsOfPay = () => import( /* webpackChunkName: "TripStatisticsOfPay" */ "@/views/TripStatistics/TripStatisticsOfPay")
const TripStatisticsOfPayOfLeader = () => import( /* webpackChunkName: "TripStatisticsOfPayOfLeader" */ "@/views/TripStatistics/TripStatisticsOfPayOfLeader")
const TripStatisticsOfStaffCompare = () => import( /* webpackChunkName: "TripStatisticsOfStaffCompare" */ "@/views/TripStatistics/TripStatisticsOfStaffCompare")
const TripStatisticsOfCrewCompare = () => import( /* webpackChunkName: "TripStatisticsOfCrewCompare" */ "@/views/TripStatistics/TripStatisticsOfCrewCompare")
const TripStatisticsOfCrewOfImportant = () => import( /* webpackChunkName: "TripStatisticsOfCrewOfImportant" */ "@/views/TripStatistics/TripStatisticsOfCrewOfImportant")
const TripStatisticsOfImportantQuests = () => import( /* webpackChunkName: "TripStatisticsOfImportantQuests" */ "@/views/TripStatistics/TripStatisticsOfImportantQuests")
const TripStatisticsOfImportantQuestsOfFinance = () => import( /* webpackChunkName: "TripStatisticsOfImportantQuestsOfFinance" */ "@/views/TripStatistics/TripStatisticsOfImportantQuestsOfFinance")
const TripStatisticsOfReport = () => import( /* webpackChunkName: "TripStatisticsOfReport" */ "@/views/TripStatistics/TripStatisticsOfReport")

export default [
  {
    path: 'staff',
    name: 'TripStatisticsOfStaff',
    meta: { title: '人员', },
    component: TripStatisticsOfStaff
  },
  // noCache: true
  {
    path: 'crew',
    name: 'TripStatisticsOfCrew',
    meta: { title: '设备', },
    component: TripStatisticsOfCrew
  },
  // noCache: true
  {
    path: 'pay',
    name: 'TripStatisticsOfPay',
    meta: { title: '薪资' },
    component: TripStatisticsOfPay
  },
  {
    path: 'pay-leader',
    name: 'TripStatisticsOfPayOfLeader',
    meta: { title: '科室奖金' },
    component: TripStatisticsOfPayOfLeader
  },
  {
    path: 'staff-compare',
    name: 'TripStatisticsOfStaffCompare',
    meta: { title: '人员对比' },
    component: TripStatisticsOfStaffCompare
  },
  {
    path: 'crew-compare',
    name: 'TripStatisticsOfCrewCompare',
    meta: { title: '设备对比' },
    component: TripStatisticsOfCrewCompare
  },
  {
    path: 'crew-imp',
    name: 'TripStatisticsOfCrewOfImportant',
    meta: { title: '重点机组' },
    component: TripStatisticsOfCrewOfImportant
  },
  {
    path: 'important-quests',
    name: 'TripStatisticsOfImportantQuests',
    meta: { title: '重大问题' },
    component: TripStatisticsOfImportantQuests
  },
  {
    path: 'important-quests-finance',
    name: 'TripStatisticsOfImportantQuestsOfFinance',
    meta: { title: "科室小结" },
    component: TripStatisticsOfImportantQuestsOfFinance
  },
  {
    path: 'report',
    name: 'TripStatisticsOfReport',
    meta: { title: "满意度调查表" },
    component: TripStatisticsOfReport
  }
]