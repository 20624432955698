<template>
  <message-bubble :isMine="isMine" :message="message">
    <div class="custom-element-wrapper">
      <span class="text" title="您可以自行解析自定义消息" v-if="text!=='[视频会议]'&& text !=='[白板会议]' && text !== '[签名]' && text !=='[回执单签名]'">{{text}}</span>
      <div class="meeting-div" v-else>
        <div v-if="text=='[视频会议]'">
          <div class="meeting-div-top">
            <img src="@/assets/image/meeting.png" alt />
            <div class="meeting-div-right" :class="{'isMine':isMine}">
              <h3>视频会议：</h3>
              <p>{{meetingText}}</p>
            </div>
          </div>
          <div class="meeting-div-bot" v-if="!isMine">
            <el-button type="text" @click="joinMeetingVideo">立即加入</el-button>
          </div>
        </div>
        <div v-else-if="text == '[白板会议]'">
          <div class="meeting-div-top">
            <img src="@/assets/image/meeting.png" alt />
            <div class="meeting-div-right" :class="{'isMine':isMine}">
              <h3>白板会议：</h3>
              <p>{{meetingText}}</p>
            </div>
          </div>
          <div class="meeting-div-bot">
            <el-button type="text" @click="joinWhiteBoard">立即加入</el-button>
          </div>
        </div>
        <div v-else-if="text === '[签名]'">
          <div class="meeting-div-top">
            <img src="@/assets/image/meeting.png" alt />
            <div class="meeting-div-right" :class="{'isMine':isMine}">
              <h3>{{catalogName}}签名：</h3>
              <p>{{meetingText}}</p>
            </div>
          </div>
          <div class="meeting-div-bot" v-if="!isMine">
            <el-button type="text" @click="joinSign">立即签名</el-button>
          </div>
        </div>
        <div v-else>
          <div class="meeting-div-top">
            <img src="@/assets/image/meeting.png" alt />
            <div class="meeting-div-right" :class="{'isMine':isMine}">
              <h3>{{catalogName}}签名：</h3>
              <p>{{meetingText}}</p>
            </div>
          </div>
          <div class="meeting-div-bot" v-if="!isMine">
            <el-button type="text" @click="joinReceipt">立即签名</el-button>
          </div>
        </div>
      </div>
    </div>
    <MeetingCallOfJoin
      :visible="dialogOfJoinMeetingCall"
      :options="joinMeetingCallConfig"
      @closeDialog="closeDialog"
      @refreshData="refreshData"
    />
    <div  v-if="dialogOfWhiteBoard" class="white">
      <WhiteBoard
              :options="joinWhiteBoardConfig" @exitMeeting="exitMeeting"
      ></WhiteBoard>
    </div>
    <Sign :options="joinSignConfig" :visible="dialogOfSign"  @closeDialog="closeDialog"></Sign>
    <Receipt :options="joinSignConfig" :visible="dialogOfReceipt"  @closeDialog="closeDialog"></Receipt>
  </message-bubble>
</template>

<script>
import MessageBubble from '../message-bubble'
import { Rate } from 'element-ui'
import { formatDuration } from '@/utils/formatDuration'
import { mapState } from 'vuex'
import MeetingCallOfJoin from './../../layout/components/MeetingCallOfJoin'
import WhiteBoard from "../../layout/components/WhiteBoard";
import Sign from "../../layout/components/sign";
import Receipt from "../../layout/components/Receipt";
import { getPackingSignature } from '@/api/open-box'
export default {
  name: 'CustomElement',
  props: {
    payload: {
      type: Object,
      required: true,
    },
    message: {
      type: Object,
      required: true,
    },
    isMine: {
      type: Boolean,
    },
  },
  components: {
    MessageBubble,
    ElRate: Rate,
    MeetingCallOfJoin,
    WhiteBoard,
    Sign,
    Receipt
  },
  computed: {
    ...mapState({
      currentConversation: (state) => state.conversation.currentConversation,
    }),
    text() {
      return this.translateCustomMessage(this.payload)
    },
    meetingText() {
      if (this.isMine) {
        if(this.text === '[视频会议]'){
          let userName = this.currentConversation.userProfile.nick
          return `您邀请了${userName}参加视频会议`
        }else if(this.text === '[白板会议]'){
          if(this.currentConversation.type==='C2C'){
            let userName = this.currentConversation.userProfile.nick
            return `您邀请了${userName}参加白板会议`
          }else{
            return `您发起了白板会议`
          }

        }else{
          let userName = this.currentConversation.userProfile.nick
          return `您邀请了${userName}签名`
        }
      } else {
        let payloadData = JSON.parse(this.payload.data)
        return payloadData.content
      }
    },
    rate() {
      return parseInt(this.payload.description)
    },
  },
  data() {
    return {
      dialogOfJoinMeetingCall: false,
      joinMeetingCallConfig: {
        roomId: 0,
      },
      joinWhiteBoardConfig:{
        roomId: 0,
      },
      dialogOfWhiteBoard:false,
      dialogOfSign:false,
      joinSignConfig:{
        signatureType:'',
        signatureTypeId: '',
      },
      jiaUserNameFileUrl:'',
      yiUserNameFileUrl:'',
      catalogName:'',
      dialogOfReceipt:false
    }
  },
  mounted() {
    // console.log(JSON.parse(this.payload.data))
    if(JSON.parse(this.payload.data).catalogName!==undefined){
      this.catalogName = JSON.parse(this.payload.data).catalogName
    }

    // let _this = this
    // var timer = setInterval(function () {
    //   // 判断页面所有资源已加载完毕
    //   if (document.readyState === "complete") {
    //     _this.$nextTick(()=>{
    //       setTimeout(()=>{
    //         _this.$bus.$on('dialogOfWhiteBoard',(val)=>{
    //           _this.joinWhiteBoard()
    //         })
    //       },100)
    //
    //     })
    //     window.clearInterval(timer);
    //   }
    // }, 800);
  },
  methods: {
    translateCustomMessage(payload) {
      let videoPayload = {}
      try {
        videoPayload = JSON.parse(payload.data)
      } catch (e) {
        videoPayload = {}
      }
      if (
        payload.data === 'group_create' ||
        (videoPayload.hasOwnProperty('businessID') &&
          videoPayload.businessID === 'group_create')
      ) {
        return this.message.nick + '创建群组'
      }
      if (videoPayload.hasOwnProperty('actionType')) {
        // 音视频消息
        switch (videoPayload.actionType) {
          case 1:
            let { call_end, room_id } = JSON.parse(videoPayload.data)
                  // console.log(JSON.parse(videoPayload.data).call_end)
            return call_end > 0
              ? `[结束通话,通话时长:${formatDuration(call_end)}]`
              : 0 !== call_end && room_id
              ? '[发起通话]'
              : '[结束群聊]'
          case 2:
            return '[取消通话]'
          case 3:
            return '[已接听]'
          case 4:
            let { line_busy } = JSON.parse(videoPayload.data)
            return line_busy && line_busy === 'line_busy'
              ? '[忙线中]'
              : '[拒绝通话]'
          case 5:
            return '[无应答]'
          default:
            return '[未定义消息]'
        }
      }
      if (videoPayload.hasOwnProperty('type')) {
        // 视频会议消息
        switch (videoPayload.type) {
          case 1:
            return `[视频会议]`
            break
          case 2:
            return `[白板会议]`
          case 3:
            return `[签名]`
          case 4:
            return `[回执单签名]`
          default:
            return `[未知消息]`
            break
        }
      }
    },
    closeDialog(dialog) {
      this[dialog] = false
    },
    refreshData() {},
    // 加入视频会议群组
    joinGroup(groupID) {
      return new Promise((resolve, reject) => {
        let data = {
          groupID,
          type: this.TIM.TYPES.GRP_MEETING,
        }
        this.tim
          .joinGroup(data)
          .then((imResponse) => {
            console.log('加入视频会议群组', imResponse)
            resolve()
          })
          .catch((err) => {
            reject()
          })
      })
    },
    // 参加视频会议
    async joinMeetingVideo() {
      await this.$store.dispatch('loading/open')
      let payloadData = {}
      try {
        payloadData = JSON.parse(this.payload.data)
      } catch (e) {
        payloadData = {}
      }
      if (payloadData.hasOwnProperty('type')) {
        // 视频会议类型的消息
        let roomId = payloadData.roomId
        // 1.加入群聊
        await this.joinGroup(roomId + '')
          .then(() => {
            this.$store.dispatch('video/updateCallStatus', 'calling')
            this.joinMeetingCallConfig.roomId = roomId
            this.dialogOfJoinMeetingCall = true
            this.$store.dispatch('loading/close')
          })
          .catch(() => {
            this.$store.dispatch('loading/close')
            // 视频会议已经结束
            this.$store.commit('showMessage', {
              type: 'error',
              message: '视频会议已经结束了!',
            })
          })
      }
      await this.$store.dispatch('loading/close')
    },
    async joinWhiteBoard(){
      await this.$store.dispatch('loading/open')
      let payloadData = {}
      try {
        payloadData = JSON.parse(this.payload.data)
      } catch (e) {
        payloadData = {}
      }
      if (payloadData.hasOwnProperty('type')) {
        // 视频会议类型的消息
        let roomId = payloadData.roomId
        console.log(payloadData)
        this.dialogOfWhiteBoard= true
        this.joinWhiteBoardConfig.roomId = roomId
        this.$store.dispatch('loading/close')
        // 1.加入群聊
        // await this.joinGroup(roomId + '')
        //         .then(() => {
        //           this.$store.dispatch('video/updateCallStatus', 'calling')
        //           this.joinMeetingCallConfig.roomId = roomId
        //           this.dialogOfJoinMeetingCall = true
        //           this.$store.dispatch('loading/close')
        //         })
        //         .catch(() => {
        //           this.$store.dispatch('loading/close')
        //           // 白板会议已经结束
        //           this.$store.commit('showMessage', {
        //             type: 'error',
        //             message: '白板会议已经结束了!',
        //           })
        //         })
      }
    },
    exitMeeting(e){
      console.log(e)
      this.dialogOfWhiteBoard = false
    },
    async joinSign(){
      //签名
      await this.$store.dispatch('loading/open')
      let payloadData = {}
      try {
        payloadData = JSON.parse(this.payload.data)
      } catch (e) {
        payloadData = {}
      }
      if (payloadData.hasOwnProperty('type')) {
        let signatureType = payloadData.signatureType
        let signatureTypeId = payloadData.signatureTypeId
        console.log(payloadData)

        this.joinSignConfig.signatureType = signatureType
        this.joinSignConfig.signatureTypeId = signatureTypeId
        this.$store.dispatch('loading/close')
        this.getPackingSignature()
      }
    },
    async joinReceipt(){
      //签名
      await this.$store.dispatch('loading/open')
      let payloadData = {}
      try {
        payloadData = JSON.parse(this.payload.data)
      } catch (e) {
        payloadData = {}
      }
      if (payloadData.hasOwnProperty('type')) {
        let signatureType = payloadData.signatureType
        let signatureTypeId = payloadData.signatureTypeId
        console.log(payloadData)

        this.joinSignConfig.signatureType = signatureType
        this.joinSignConfig.signatureTypeId = signatureTypeId
        this.$store.dispatch('loading/close')
        this.getPackingSignature()
      }
    },
    getPackingSignature(){
      getPackingSignature({signatureType:this.joinSignConfig.signatureType,signatureTypeId:this.joinSignConfig.signatureTypeId}).then((res)=>{
        if(res.code === 200){
          this.jiaUserNameFileUrl = res.data.jiaUserNameFileUrl
          this.yiUserNameFileUrl = res.data.yiUserNameFileUrl
          if(this.jiaUserNameFileUrl!==''&& this.yiUserNameFileUrl !== ''){
            this.$store.commit('showMessage', {
              type: 'error',
              message: '该箱已完成签名!',
            })
          }else{
            if(this.joinSignConfig.signatureType==='packing'){
              this.dialogOfReceipt = true
            }else{
              this.dialogOfSign= true
            }
          }
        }
      })
    },
  },
}
</script>

<style lang="stylus" scoped>
.text {
  font-weight: bold;
}

.title {
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 10px;
}

.survey {
  background-color: white;
  color: black;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.suggestion {
  padding-top: 10px;
  font-size: 14px;
}
.white{
  position: fixed;
  left: 0;
  z-index :10;
  top: 0;
  width: 100vw;
  height: 100vh;
  max-width: 1280px;
  background #fff
}
.meeting-div {
  padding: 2px;
  background: #fff;
  border-radius: 4px;
  color: #333;
  min-width: 200px;
  max-width: 240px;

  img {
    width: 40px;
    height: 32px;
  }

  .meeting-div-top {
    display: flex;
    padding: 8px 0px;
    align-items: center;
  }

  .meeting-div-right {
    padding: 0px 10px;

    h3 {
      line-height: 18px;
      font-size: 15px;
    }

    p {
      font-size: 12px;
      line-height: 14px;
    }
  }

  .meeting-div-bot {
    text-align: center;
    border-top: 1px solid #eee;

    .el-button {
      padding: 4px 15px;
    }
  }

  .isMine {
    padding: 10px;
  }
}
</style>
