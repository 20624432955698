//指派出差
const AddTrip = () =>
    import( /* webpackChunkName: "AddTrip" */ "@/views/Dashboard/AddTrip/AddTrip")
//编辑预派单
const EditTrip = () =>
    import( /* webpackChunkName: "EditTrip" */ "@/views/Dashboard/AddTrip/EditTrip")
export default [
    {
        path: 'add-trip/:workOrder',
        name: 'AddTrip',
        meta: { title: '指派出差' },
        component: AddTrip,
    },
    {
        path: 'edit-trip/:tripId',
        name: 'EditTrip',
        meta: { title: '编辑预派单' },
        component: EditTrip,
    },
]