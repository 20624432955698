<template>
  <el-dialog
    title="视频会议"
    ref="dialogJoin"
    :visible.sync="visible"
    custom-class="custom-video-meeting1"
    :before-close="beforeClose"
    :close-on-click-modal="false"
    :append-to-body="true"
    :show-close="false"
    :modal="false"
    top="8vh"
    v-dialogDrag
    :width='dialogWidth'
    :height="dialogHeight"
  >
    <div style="position: absolute;top:23px;right:78px;cursor: pointer;" @click="shrinkBtnVideo" title="缩小放大">一</div>
    <div style="position: absolute;top:23px;right:48px;cursor: pointer;" @click="fullEnlarge"><img src="@/assets//image/large.png" alt=""></div>
    <div style="width:100%;height:100%;background: #fff;" id="call">
      <div v-if="EnlargeShow" style="text-align: right;margin-right: 24px;margin-top:10px;" @click="fullEnlarge"><img src="@/assets//image/large.png" alt=""></div>
      <section class="video-meeting-container">
        <section class="video-list">
          <section class="video-list-sec">
            <div  class="meeting-video-list">
              <div style="padding-left:10px;margin-top:10px;width:224px;">
                <el-select v-model="deviceName" multiple placeholder="请添加摄像头"  collapse-tags @change="selectDevice">
                  <el-option
                          v-for="item in liveList"
                          :key="item.deviceId"
                          :label="item.deviceName"
                          :value="item.deviceId"
                          :disabled="item.deviceOnLine === 0?true:false"
                  >
                  </el-option>
                </el-select>
                <!--摄像头-->
                <template  >
                  <PlayerLive :liveData="liveData" class="childLive" ></PlayerLive>
                </template>
              </div>
              <div v-if="joinedList.length">
                <template v-for="(user,key) in joinedList">
                  <div
                          v-if="user.display"
                          class="meeting-item"
                          @click="selectUserIdOfMeeting=user.userID"
                          :class="{'is-mine-meeting':user.userID==userID,'big':selectUserIdOfMeeting===user.userID,'small':selectUserIdOfMeeting!==user.userID}"
                          :key="'meeting'+user.userID+key"
                  >
                    <div :id="'video-meeting_'+user.userID" style="height:100%">
                      <div style="position: absolute;left:10px;bottom:15px;color: #000;z-index: 1;">{{user.nick}}</div>
                      <template v-if="isCalling">
                        <template v-if="user.userID==userID">
                          <div class="video-meeting-mask" v-if="!isVideoOn">
                            <img class="image" src="@/assets/tweblive/camera-max.png" />
                            <p class="notice">摄像头未打开</p>
                          </div>
                        </template>
                        <template v-else>
                          <div class="video-meeting-mask" v-if="!user.isVideoOn">
                            <img class="image" src="@/assets/tweblive/camera-max.png" />
                            <p class="notice">摄像头未打开</p>
                          </div>
                        </template>
                      </template>

                      <div class="meeting-status" v-if="isCalling">
                        <template v-if="user.userID==userID">
                          <img v-if="isVideoOn" src="@/assets/tweblive/camera-on.png" alt />
                          <img v-else src="@/assets/tweblive/camera-off.png" alt />
                          <img v-if="isAudioOn" src="@/assets/tweblive/mic-on.png" alt />
                          <img v-else src="@/assets/tweblive/mic-off.png" alt />
                          <img v-if="!fullScreenShow" src="@/assets/tweblive/fullScrren.png" @click="fullScreen(user.userID)" >
                          <img v-else src="@/assets/tweblive/fullscreen_exit.png" @click="fullScreen(user.userID)" >
                        </template>
                        <template v-else>
                          <img v-if="user.isVideoOn" src="@/assets/tweblive/camera-on.png" alt />
                          <img v-else src="@/assets/tweblive/camera-off.png" alt />
                          <img v-if="user.isAudioOn" src="@/assets/tweblive/mic-on.png" alt />
                          <img v-else src="@/assets/tweblive/mic-off.png" alt />
                          <img v-if="!fullScreenShow" src="@/assets/tweblive/fullScrren.png" @click="fullScreen(user.userID)" >
                          <img v-else src="@/assets/tweblive/fullscreen_exit.png" @click="fullScreen(user.userID)" >
                        </template>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </section>
          <section class="meeting-operator" v-if="isCalling">
            <el-button type="success" @click="toggleVideo">{{isVideoOn ? '关闭摄像头' : '打开摄像头'}}</el-button>
            <el-button type="success" @click="toggleAudio">{{isAudioOn ? '关闭麦克风' : '打开麦克风'}}</el-button>
            <el-button type="danger" @click="holdUp">挂 断</el-button>
          </section>
        </section>
      </section>
    </div>

  </el-dialog>
</template>
   <script>
import MeetingClient from '@/utils/video-meeting-client'
import { mapState } from 'vuex'
import { getFriendList,getLiveList} from '@/api/im'
import PlayerLive from "./PlayerLive";
import {launchIntoFullscreen ,exitFullscreen} from "../../../../utils/fullScreen";
import '@/api/dialog'
export default {
  name: 'MeetingCallOfJoin',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState({
      userID: (state) => state.user.userID,
      userSig: (state) => state.user.userSig,
      sdkAppID: (state) => state.user.sdkAppID,
      realName: (state) => state.user.realName,
      currentConversation: (state) => state.conversation.currentConversation,
    }),
    // 是否
    isCalling() {
      return this.VideoMeetingTRTC && this.VideoMeetingTRTC.isPublished_
    },
  },
  components: {
    PlayerLive
  },
  watch: {
    visible: {
      handler(val) {
        if (val) {
          this.init()
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      joinedList: [],
      VideoMeetingTRTC: null,
      isVideoOn: true,
      isAudioOn: true,
      selectUserIdOfMeeting: '',
      liveList:[],
      liveData:[],
      deviceName:'',
      selectList:[],
      list:[],
      fullScreenShow:false,
      fullUserId:'',
      EnlargeShow:false,
      suo:false,
      dialogWidth:'80vw',
      dialogHeight:'80vh'
    }
  },
  created() {
    let that = this

    window.onresize = function() {
      if (!that.checkFull()) {
        //触发esc事件，执行业务逻辑。
       // that.fullScreen(that.fullUserId)
        that.fullScreenShow = false
        that.EnlargeShow = false
      }
    };
  },
  mounted() {
    this.selectUserIdOfMeeting = this.userID
  },
  methods: {
    shrinkBtnVideo(){
      this.suo = !this.suo
      const dialogDom = document.querySelector('.custom-video-meeting1')
      if(this.suo){
        this.dialogWidth = '400px';
        this.$refs.dialogJoin.$el.firstChild.style.height ='300px';
      }else{
        this.dialogWidth = '80vw';
        this.$refs.dialogJoin.$el.firstChild.style.height = '80vh';
      }
    },
    fullEnlarge(){
      this.EnlargeShow = !this.EnlargeShow
      if (this.EnlargeShow) {
        launchIntoFullscreen(document.getElementById('call'))
        return false;
      } else {
        exitFullscreen(document.getElementById('call'))
      }
    },
    checkFull(){
      //判断浏览器是否处于全屏状态 （需要考虑兼容问题）
      //火狐浏览器
      let isFull = document.mozFullScreen||
              document.fullScreen ||
              //谷歌浏览器及Webkit内核浏览器
              document.webkitIsFullScreen ||
              document.webkitRequestFullScreen ||
              document.mozRequestFullScreen ||
              document.msFullscreenEnabled
      if(isFull === undefined) {
        isFull = false
      }
      return isFull;

    },

    //全屏
    fullScreen(userID){
      this.fullUserId = userID
      this.fullScreenShow = !this.fullScreenShow;
      if (this.fullScreenShow) {
        launchIntoFullscreen(document.getElementById('video-meeting_'+userID))
        return false;
      } else {
        exitFullscreen(document.getElementById('video-meeting_'+userID))
      }
    },
    async init() {
      this.resetForm()
      if (!this.VideoMeetingTRTC) {
        this.getFriendList()
        await
        this.getLiveList()
      }
    },
    getFriendList() {
      let data = {
        page: 1,
        limit: 9999,
        realName: '',
      }
      getFriendList(data)
              .then((res) => {
                // let {
                //   data: { list },
                // } = res
                // if (list.length) {
                //   list.map((i) => {
                //     i.selected = false
                //     i.isVideoOn = true
                //     i.isAudioOn = true
                //     return i
                //   })
                // }
                this.list = res.data.list
                this.initTRTC()
              })
              .catch((err) => {
                this.$store.commit('showMessage', {
                  type: 'error',
                  message: err.msg,
                })
              })
    },
    //选中摄像头
    selectDevice(value){
      this.$nextTick(() => {
        this.selectList = value
        this.liveData = []
        this.$forceUpdate()
        this.selectList.forEach((item,index)=>{
          this.liveList.forEach((item1,index1)=>{
            if(item === item1.deviceId){
              this.liveData.push(item1)
              this.$nextTick(()=>{

              })
            }
          })
        })
        this.$forceUpdate()
      })
    },
    resetForm() {
      this.joinedList = []
      window.VideoMeetingTRTC = this.VideoMeetingTRTC = null
      this.isVideoOn = true
      this.isAudioOn = true
    },
    refreshData() {
      this.$emit('refreshData')
    },
    beforeClose(done) {
      this.closeDialog()
      done()
    },
    closeDialog() {
      // 挂断之后，将音视频状态改为空闲
      this.$store.dispatch('video/updateCallComponentType', '')
      this.$store.dispatch('video/updateCallStatus', 'idle')
      this.$emit('closeDialog', 'dialogOfJoinMeetingCall')
      this.liveData=[]
      this.deviceName = ''
    },
    submit() {
      this.refreshData()
      this.closeDialog()
    },
    // 退出群聊
    quitGroup() {
      return new Promise((resolve, reject) => {
        this.tim
          .quitGroup(this.options.roomId + '')
          .then((imResponse) => {
            console.log('退出视频会议群组', imResponse.data.groupID) // 加入的群组资料
            resolve()
          })
          .catch((err) => {
            reject()
          })
      })
    },
    // 获取群成员列表
    getGroupMemberList() {
      return new Promise((resolve, reject) => {
        let data = {
          groupID: this.options.roomId + '',
          count: 100,
          offset: 0,
        }
        this.tim
          .getGroupMemberList(data)
          .then((imResponse) => {
            let {
              data: { memberList },
            } = imResponse
            if (memberList.length) {
              memberList.map((item) => {
                item.isAudioOn = true
                item.isVideoOn = true
                return item
              })
            }
            this.joinedList = memberList
            resolve()
          })
          .catch((err) => {
            console.log('获取群成员列表失败！')
            reject()
          })
      })
    },
    initTRTC: async function () {
      let options = {
        roomId: this.options.roomId,
        userId: this.userID,
        userSig: this.userSig,
        sdkAppId: this.sdkAppID,
      }
      this.VideoMeetingTRTC = new MeetingClient(options)
      // 1.1阻止音视频通话
      this.$store.dispatch('video/updateCallStatus', 'calling')
      this.$store.dispatch('video/updateCallComponentType', 'meeting')
      // 3.监听视频会议消息
      this.initListener()
      // 3.1自己加入
      this.joinedList.push({
        userID: this.userID,
        isAudioOn: true,
        isVideoOn: true,
        display: true,
        nick: this.realName
      })
      //  4.加入视频会议
      await this.VideoMeetingTRTC.join()
    },
    holdUp: async function () {
      try {
        // 2.退出房间
        await this.VideoMeetingTRTC.leave()
        // 1.退出群组
        await this.quitGroup()
      } catch (error) {}
      this.resetData()
      this.closeDialog()
    },
    resetData() {
      this.VideoMeetingTRTC = null
      this.joinedList = []
    },
    // trtc Client 方法
    initListener() {
      if (this.VideoMeetingTRTC) {
        // fired when a remote peer is joining the room
        this.VideoMeetingTRTC.client_.on('peer-join', this.handleUserEnter)
        // fired when a remote peer is leaving the room
        this.VideoMeetingTRTC.client_.on('peer-leave', this.handleUserLeave)
        // fired when a remote stream is added
        this.VideoMeetingTRTC.client_.on('stream-added', this.handleStreamAdded)
        // fired when a remote stream has been subscribed
        this.VideoMeetingTRTC.client_.on(
          'stream-subscribed',
          this.handleStreamSubscribed
        )
        // fired when the remote stream is removed, e.g. the remote user called Client.unpublish()
        this.VideoMeetingTRTC.client_.on(
          'stream-removed',
          this.handleStreamRemoved
        )
        this.VideoMeetingTRTC.client_.on('mute-audio', this.muteAudio)
        this.VideoMeetingTRTC.client_.on('unmute-audio', this.unmuteAudio)
        this.VideoMeetingTRTC.client_.on('mute-video', this.muteVideo)
        this.VideoMeetingTRTC.client_.on('unmute-video', this.unmuteVideo)
      }
    },
    // fired when a remote peer is joining the room
    handleUserEnter(evt) {
      console.log('handleUserEnter', evt)
      if (this.joinedList.every((i) => i.userID != evt.userId)) {
        this.joinedList.push({
          userID: evt.userId,
          isAudioOn: true,
          isVideoOn: true,
          display: true,
        })
      } else {
        for (let item of this.joinedList) {
          if (item.userID == evt.userId) {
            item.display = true
          }
        }
      }
      this.list.forEach((item,index)=>{
        this.joinedList.forEach((item1,index1)=>{
          if(item.friendImId ==item1.userID){
            item1.nick = item.realName
          }
        })
      })
    },
    // fired when a remote peer is leaving the room
    handleUserLeave(evt) {
      console.log('handleUserLeave', evt)
      // 主播离开，则退出
      if (evt.userId === this.currentConversation.userProfile.userID) {
        this.holdUp()
        return
      }
      let userId = evt.userId
      for (let item of this.joinedList) {
        if (item.userID == userId) {
          item.display = false
        }
      }
    },
    // fired when a remote stream is added
    handleStreamAdded(evt) {
      console.log('handleStreamAdded', evt)
      const remoteStream = evt.stream
      const id = remoteStream.getId()
      const userId = remoteStream.getUserId()
      console.log(
        `remote stream added: [${userId}] ID: ${id} type: ${remoteStream.getType()}`
      )
      // 订阅远程流
      console.log('subscribe to this remote stream')
      this.VideoMeetingTRTC.client_.subscribe(remoteStream)
    },
    // fired when a remote stream has been subscribed
    handleStreamSubscribed(evt) {
      console.log('handleStreamSubscribed', evt)
      const uid = evt.userId
      const remoteStream = evt.stream
      const id = `video-meeting_${remoteStream.userId_}`
      console.log('handleStreamSubscribed', id)
      this.VideoMeetingTRTC.remoteStreams_.push(remoteStream)
      remoteStream.on('player-state-changed', (event) => {
        console.log(`${event.type} player is ${event.state}`)
      })
      remoteStream.play(id, { objectFit: 'cover' })
      if (!remoteStream.hasVideo()) {
        console.log('remoteStream.hasVideo is false')
      }
      console.log('stream-subscribed ID: ', id)
    },
    // fired when the remote stream is removed, e.g. the remote user called Client.unpublish()
    handleStreamRemoved(evt) {
      console.log('handleStreamRemoved', evt)
      const remoteStream = evt.stream
      const id = remoteStream.getId()
      remoteStream.stop()
      this.VideoMeetingTRTC.remoteStreams_ =
        this.VideoMeetingTRTC.remoteStreams_.filter((stream) => {
          return stream.getId() !== id
        })
      // removeView(id)
      console.log(`stream-removed ID: ${id}  type: ${remoteStream.getType()}`)
    },
    muteAudio(evt) {
      let userId = evt.userId
      let userList = this.joinedList.filter((i) => i.userID === userId)
      if (userList.length) {
        userList[0].isAudioOn = false
      }
    },
    unmuteAudio(evt) {
      let userId = evt.userId
      let userList = this.joinedList.filter((i) => i.userID === userId)
      if (userList.length) {
        userList[0].isAudioOn = true
      }
    },
    muteVideo(evt) {
      let userId = evt.userId
      let userList = this.joinedList.filter((i) => i.userID === userId)
      if (userList.length) {
        userList[0].isVideoOn = false
      }
    },
    unmuteVideo(evt) {
      let userId = evt.userId
      let userList = this.joinedList.filter((i) => i.userID === userId)
      if (userList.length) {
        userList[0].isVideoOn = true
      }
    },
    toggleVideo() {
      this.isVideoOn = !this.isVideoOn
      if (this.isVideoOn) {
        // 启用摄像头
        this.VideoMeetingTRTC.unmuteLocalVideo()
      } else {
        // 禁用摄像头
        this.VideoMeetingTRTC.muteLocalVideo()
      }
    },
    toggleAudio() {
      this.isAudioOn = !this.isAudioOn
      if (this.isAudioOn) {
        // 启用麦克风
        this.VideoMeetingTRTC.unmuteLocalAudio()
      } else {
        // 禁用麦克风
        this.VideoMeetingTRTC.muteLocalAudio()
      }
    },
    //获取摄像头列表
    getLiveList(){
      getLiveList().then(( res )=>{
        console.log(res)
        if(res.code === 200){
          this.liveList = res.data
        }
      })
    }
  },
}
</script>
<style lang='scss' scoped>
::v-deep .custom-video-meeting1 {
  width: 80vw;
  height: 80vh;
  max-width: 1280px;
  box-shadow: 0 11px 20px 0 rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  .el-dialog__body {
    padding: 16px 24px;
    flex: 1;
  }
}
.el-dialog {
  .video-meeting-container {
    width: 98%;
    height: 98%;
    display: flex;
    position: relative;
    background: #fff;
    margin:0 auto;
    top:1%;
  }
  .friend-list,
  .video-list {
    // 80vh - el-dialog__header height - el-dialog__body padding
    height: calc(80vh - 86px);
    position: relative;
  }
  .video-list-sec {
    width: 100%;
    height: calc(100% - 52px);
    overflow: hidden;
    position: relative;
  }
  .video-list {
    flex: 1;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-left: 20px;
    height: 100%;
  }
  // 视频
  .meeting-video-list {
    height: 100%;
    overflow: hidden auto;

    .meeting-item {
      width: 200px;
      height: 200px;
      overflow: hidden;
      margin: 10px;
      position: relative;
    }
    .meeting-status {
      position: absolute;
      right: 10px;
      bottom: 10px;
      z-index: 2;
      img {
        margin-right: 8px;
      }
    }
    .video-meeting-mask {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: #d8d8d8;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: 1;
    }
    .big {
      position: absolute;
      width: calc(100% - 224px);
      height: calc(100% - 16px);
      z-index: 9999;
      top: 0;
      margin-left: 216px;
      background:#000;
      ::v-deep video {
        width: 100% !important;
        height: 100% !important;
        object-fit: contain !important;
      }
    }
    .small {
      z-index: 99999;
      background:#000;
      ::v-deep video {
        width: 200px !important;
        height: 200px !important;
        object-fit: contain !important;
      }
    }
  }

  .meeting-operator {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    bottom: 10px;
    .el-button {
      width: 120px;
    }
  }
}
/deep/.vcp-volume{
  display: none !important;
}
.childLive{
  max-height: 90%;
  overflow-y: scroll;
}
.el-dialog__wrapper{
  pointer-events:none;
  /deep/ .el-dialog{
    pointer-events:auto;
  }
}
</style>