<template>
  <div
    id="app"
    class="app"
    v-loading="layoutLoading"
    :class="{'mobile': device === 'mobile'}"
    element-loading-background="rgba(0, 0, 0, 0.2)"
  >
    <Chat ref="chatRef" v-if="isLogin"></Chat>
    <my-drag class="my-min-chat" target="#app">
      <el-button v-if="isLogin" class="maximize" @click="maximize" :class="{'my-shark':sharkFlag}" id="my-shark">
        <el-image
          class="chat-avatar"
          :src="avatar"
          fit="cover"
          style="width:46px;height:46px;border-radius:50%;cursor: pointer;"
        ></el-image>
        <span style="cursor:pointer">我的会话</span>
        <div class="msg-count" v-if="totalUnreadCount !== 0">
          <template v-if="totalUnreadCount > 99">99+</template>
          <template v-else>{{totalUnreadCount}}</template>
        </div>
      </el-button>
    </my-drag>
    <transition name="fade-transverse" mode="out-in">
      <router-view v-if="isRouterAlive && !$route.meta.keepAlive" />
    </transition>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import ResizeHandler from '@/mixins/ResizeHandler'
import Chat from '@/components/Chat'
import { MyDrag } from '$ui'
export default {
  name: 'App',
  components: { Chat, MyDrag },
  mixins: [ResizeHandler],
  provide() {
    return {
      reload: this.reload,
    }
  },
  computed: {
    ...mapGetters(['totalUnreadCount']),
    ...mapState({
      layoutLoading: (state) => state.loading.layoutLoading,
      device: (state) => state.app.device,
      avatar: (state) => state.user.avatar,
      sharkFlag: (state) => state.app.sharkFlag,
      isLogin: (state) => state.user.isLogin,
    }),
  },
  data() {
    return {
      isRouterAlive: true,
    }
  },
  methods: {
    reload() {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    },
    maximize() {
      let chatDom = document.querySelector('.chat-container')
      chatDom.style.display = 'block'
    },
  },
}
</script>
<style scoped lang="scss">
.mobile {
  overflow-x: hidden;
  overflow-y: auto !important;
}
.my-min-chat {
  width: 150px;
  height: 66px;
  position: absolute;
  z-index: 9999;
  right: 0;
  bottom: 0;
  .chat-avatar {
    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background: transparent;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}
.maximize {
  cursor: move;
  & > span {
    display: inline-block;
    height: 46px;
    span {
      display: inline-block;
      height: 46px;
      line-height: 46px;
      vertical-align: top;
      margin-left: 16px;
    }
  }
  .msg-count {
    position: absolute;
    background: #f56c6c;
    color: #fff;
    font-weight: 600;
    left: -10px;
    top: -6px;
    font-size: 16px;
    height: 26px;
    padding: 0 5px;
    white-space: nowrap;
    border: 1px solid #fff;
    line-height: 26px;
    border-radius: 10px;
    text-align: center;
  }
}
.my-shark {
  animation: login-shake 0.8s 1 ease-in;
}
@keyframes login-shake {
  0% {
    transform: scale(1);
  }
  10%,
  20% {
    transform: scale(0.9) rotate(-3deg);
  }
  30%,
  50%,
  70%,
  90% {
    transform: scale(1.1) rotate(3deg);
  }
  40%,
  60%,
  80% {
    transform: scale(1.1) rotate(-3deg);
  }
  100% {
    transform: scale(1) rotate(0);
  }
}
</style>
