// 出差台账
const TripBoard = () =>
    import( /* webpackChunkName: "TripBoard" */ "@/views/Dashboard/TripBoard")
//服务记录详情
const TripServerRecords = () =>
    import(/* webpackChunkName: "TripServerRecords" */ "@/views/Dashboard/TripBoard/TripServerRecords")
export  default [
    {
        path: 'trip-board',
        name: 'TripBoard',
        meta: { title: '出差台账' },
        component: TripBoard
    },
    {
        path: 'trip-detail/:tripId',
        name: 'TripServerRecords',
        component: TripServerRecords,
        meta: { 'title': '出差台账-详情' },
    },
]