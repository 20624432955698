import tim from '@/tim'

const groupModules = {
  namespaced: true,
  state: {

    groupList: [],
    currentMemberList: [],
    createGroupModelVisible: false,
    showGroupMemberList: false,
    isGroupPane: false
  },
  getters: {
    hasGroupList: state => state.groupList.length > 0
  },
  mutations: {
    updateGroupList (state, groupList) {
      state.groupList = groupList
    },
    updateCreateGroupModelVisible (state, visible) {
      state.createGroupModelVisible = visible
    },
    updateCurrentMemberList (state, memberList) {
      state.currentMemberList = [...state.currentMemberList, ...memberList]
    },
    deleteGroupMemeber (state, userID) {
      state.currentMemberList = state.currentMemberList.filter((member) => member.userID !== userID)
    },
    deleteGroupMemberList (state, userIDList) {
      state.currentMemberList = state.currentMemberList.filter((member) => !userIDList.includes(member.userID))
    },
    resetCurrentMemberList (state) {
      state.currentMemberList = []
    },
    reset (state) {
      Object.assign(state, {
        groupList: [],
        currentMemberList: [],
        createGroupModelVisible: false
      })
    },
    TOGGLE_MEMBERLIST (state, bStop) {
      state.showGroupMemberList = bStop
    }
    ,
    TOGGLE_ISGRPPANE (state, bStop) {
      state.isGroupPane = bStop
    }
  },
  actions: {
    updateGroupList (context, groupList) {
      context.commit('updateGroupList', groupList)
    },
    updateCreateGroupModelVisible ({ commit }, visible) {
      commit('updateCreateGroupModelVisible', visible)
    },
    updateCurrentMemberList ({ commit }, memberList) {
      commit('updateCurrentMemberList', memberList)
    },
    deleteGroupMemeber ({ commit }, userID) {
      commit('deleteGroupMemeber', userID)
    },
    deleteGroupMemberList ({ commit }, userIDList) {
      commit('deleteGroupMemberList', userIDList)
    },
    getGroupMemberList (context, groupID) {
      return tim.getGroupMemberList({
        groupID: groupID,
        offset: context.state.currentMemberList.length,
        count: 30
      }).then((imResponse) => {
        let { data: { memberList } } = imResponse
        let list = []
        if (memberList.length) {
          memberList.forEach(item => {
            if (item.role === 'Owner') {
              list.unshift(item)
            } else {
              list.push(item)
            }
          });
        }
        context.commit('updateCurrentMemberList', list)
        return imResponse
      })
    },
    resetGroup ({ commit }) {
      commit('reset')
    },
    resetCurrentMemberList ({ commit }) {
      commit('resetCurrentMemberList')
    },
    showGroupMember ({ commit }, bStop) {
      commit('TOGGLE_MEMBERLIST', bStop)
    },
    isGroupPane ({ commit }, bStop) {
      commit('TOGGLE_ISGRPPANE', bStop)
    }
  }
}

export default groupModules
