<template>
  <section class="noData-container" :style="{height:height}">
    <img src="@/assets/image/nodata-filter.png" alt />
    <p class="no-data">暂无数据！</p>
  </section>
</template>

<script>
export default {
  name: 'Nodata',
  props: {
    height: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped lang="scss">
.noData-container {
  width: 100%;
  padding: 16px 0px;
  min-height: 150px;
  text-align: center;
  .no-data {
    line-height: 20px;
    text-align: center;
    font-size: 14px;
    color: #cccccc;
  }
}
</style>
