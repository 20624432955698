<template>
    <el-dialog :title="catalogName+'签名'"   custom-class="custom-video-meeting" :append-to-body="true" :visible.sync="visible" width="60%" :close-on-click-modal="false" >
        <el-table :data="listOfOutNo"  ref="table" :cell-class-name="delLine">
            <template slot="empty">
                <Nodata/>
            </template>
            <el-table-column width="80px" label="序号/No" align="center" prop="caseIndex"></el-table-column>
            <el-table-column prop="caseNo" label="物号/箱号" align="center">
                <template slot-scope="scope">
                    <span  v-html="scope.row.caseNo"></span>
                </template>
            </el-table-column>
            <el-table-column prop="caseName" label="名称规格" align="center">
                <template slot-scope="scope">
                    <span v-html="scope.row.caseName"></span>

                </template>
            </el-table-column>
            <el-table-column prop="caseQty" width="80" label="数量" align="center">
                <template slot-scope="scope">
                    <span v-html="scope.row.caseQty"></span>
                </template>
            </el-table-column>
            <el-table-column prop="caseUnit" width="80" label="单位" align="center">
                <template slot-scope="scope">
                    <span v-html="scope.row.caseUnit"></span>
                </template>
            </el-table-column>
            <el-table-column prop="caseRemarks" label="图号备注" align="center">
                <template slot-scope="scope">
                    <span v-html="scope.row.caseRemarks"></span>
                </template>
            </el-table-column>
        </el-table>
        <div>
            <div v-for="(item,index) in listOfOutHave">
                <div style="margin-top:20px;font-size: 22px; ">{{item.checkedTypeStr}}&nbsp;：(<span v-html="item.caseName"></span>):</div>
                <div style="margin-top:20px; ">缺损备注：{{item.checkedDesc}}</div>
                <div v-for="item1 in item.checkedFileList">
                    <img :src="item1.furl" alt="" style="max-width: 80%;margin-top:20px;" >
                </div>
            </div>
        </div>
        <div style="border: 1px solid #ccc;margin: 0 auto;display: flex;width:800px;position: relative;" >
            <div style="position: absolute;top:0;width:400px;height:300px;background:rgba(204,204,204,0.2);text-align: center;line-height: 300px;" v-if="signJia">
                <el-button type="primary" @click="signJiaClick">甲方签名</el-button>

            </div>
            <div style="position: absolute;top:0;left:400px;width:400px;height:300px;background: rgba(204,204,204,0.2);text-align: center;line-height: 300px;" v-if="signYi">
                <el-button type="primary" @click="signYiClick">乙方签名</el-button>

            </div>
            <div style="width:400px;height:300px;text-align: center;line-height: 300px;border-right: 1px solid #ccc;    display: flex;justify-content: center;align-items: center;" v-if="jiaUserNameFileUrl!==''">
                <img :src="jiaUserNameFileUrl" style="display: block;margin: 0 auto;" alt="">
            </div>
            <div v-if="jiaUserNameFileUrl=='' || yiUserNameFileUrl == ''">
                <vue-esign style="border: 1px solid #ccc;" ref="esign" :width="400" :height="300"  :lineWidth="lineWidth" :lineColor="lineColor"  :bgColor.sync="bgColor" />
            </div>
            <div style="width:400px;height:300px;text-align: center;line-height: 300px;border-left: 1px solid #ccc;display: flex;justify-content: center;align-items: center;" v-if="yiUserNameFileUrl!==''">
                <img :src="yiUserNameFileUrl" style="display: block;margin: 0 auto;" alt="">
            </div>
        </div>

        <span slot="footer" class="dialog-footer">
                    <el-button @click="handleReset">取 消</el-button>
                    <el-button type="primary" @click="resetReceipt">重新签名</el-button>
                    <el-button type="primary" @click="handleGenerate">确认签名</el-button>
                </span>
    </el-dialog>
</template>

<script>
    import { packingCatalogTreeList , packingCatalogCaseList , getPackingSignature  , updPackingSignature} from '@/api/open-box'
    export default {
        name: "sign",
        props:{
            visible: {
                type: Boolean,
                default: false,
            },
            options: {
                type: Object,
                required: true,
            },
        },
        watch: {
            visible: {
                handler(val) {
                    if (val) {
                        if(this.options.signatureType=='catalog'){
                            this.getTable(this.options.signatureTypeId)
                        }
                        this.getPackingSignature()
                    }
                },
                immediate: true,
            },
        },
        data(){
            return{
                catalogName:'',
                listOfOut:[],
                listOfOutNo:[],
                listOfOutHave:[],
                signYi:true,
                signJia:true,
                lineWidth: 6,
                lineColor: '#000000',
                bgColor: '',
                resultImg: '',
                isCrop: true,
                jiaUserNameFileUrl:'',
                yiUserNameFileUrl:'',
            }
        },
        mounted() {
            // console.log(this.options)

        },
        methods:{
            // 删除线
            delLine ({ row, column, rowIndex, columnIndex }) {
                if (row.invalid == 1 && column.id !== 'el-table_1_column_10')  return 'del_line'
            },
            //获取表格
            getTable(catalogId){
                this.listOfOutNo = []
                this.listOfOutHave = []
                this.$store.dispatch('loading/setLayoutLoading', true)
                packingCatalogCaseList({catalogId:catalogId}).then((res) => {
                    if(res.code === 200 ){
                        this.listOfOut = res.data
                        this.listOfOut.forEach((item,index)=>{
                            item.checkedFileList.forEach((item1,index1)=>{
                                this.$set(item1,'name',item1.fname)
                                this.$set(item1,'url',item1.furl)
                            })
                                if(item.checked === 1){
                                    this.listOfOutHave.push(item)
                                }else if(item.checked === 0){
                                    this.listOfOutNo.push(item)
                                }
                            this.$set(item,'fileList',item.checkedFileList)
                        })
                        this.$store.dispatch('loading/setLayoutLoading', false)
                    }else{
                        this.$store.dispatch('loading/setLayoutLoading', false)
                        this.$Message.error(res.msg)
                    }
                }).catch((err) => {
                    this.$store.dispatch('loading/setLayoutLoading', false)
                    this.$Message.error(err.msg)
                })
            },
            handleReset(){
                if(this.$refs.esign!==undefined){
                    this.$refs.esign.reset()
                }
                this.$emit('closeDialog', 'dialogOfSign')
            },
            resetReceipt(){
                this.$refs.esign.reset()
            },
            handleGenerate(){
                //生成图片
                this.$refs.esign.generate().then(res => {
                    // //发送自定义消息
                    // let message = {
                    //     to:this.realName,
                    //     conversationType:this.TIM.TYPES.CONV_C2C,
                    //     payload:{
                    //         data:{
                    //             type: 3,
                    //             signatureType:data1.signatureType,
                    //             signatureTypeId: data1.signatureTypeId,
                    //             // content: `${this.realName}邀请您参加视频会议`,
                    //             content: `签名`,
                    //         }
                    //     }
                    // }
                    // this.sendInviteMessage(message)
                    console.log(res)
                    this.resultImg = res
                    let data = {
                        signatureType:this.options.signatureType,
                        signatureTypeId:this.options.signatureTypeId,
                        jiaUserNameBase64File:'',
                        yiUserNameBase64File:''
                    }
                    if(this.jiaUserNameFileUrl === ''){
                        data.jiaUserNameBase64File = res
                    }else if(this.yiUserNameFileUrl === ''){
                        data.yiUserNameBase64File = res
                    }
                    console.log(data)
                    updPackingSignature(data).then((res)=>{
                        if(res.code === 200){
                            this.dialogOfSign = false
                            this.$refs.esign.reset()
                            this.$Message.success('签名成功')
                        }
                    })
                }).catch(err => {
                    alert(err) // 画布没有签字时会执行这里 'Not Signned'
                })
                this.$emit('closeDialog', 'dialogOfSign')
            },
            //查看签名
            getPackingSignature(){
                getPackingSignature({signatureType:this.options.signatureType,signatureTypeId:this.options.signatureTypeId}).then((res)=>{
                    if(res.code === 200){
                        this.jiaUserNameFileUrl = res.data.jiaUserNameFileUrl
                        this.yiUserNameFileUrl = res.data.yiUserNameFileUrl
                        if(this.jiaUserNameFileUrl==''){
                            this.signJia = true
                        }else{
                            this.signJia = false
                        }
                        if(this.yiUserNameFileUrl==''){
                            this.signYi = true
                        }else{
                            this.signYi = false
                        }
                    }
                })
            },
            signJiaClick(){
                this.signJia = false
            },
            signYiClick(){
                this.signYi = false
            }
        }
    }
</script>

<style scoped>

</style>