const loading = {
    namespaced: true,
    state: {
        layoutLoading: false,
        appLoading: false
    },
    mutations: {
        SET_LAYOUTLOADING (state, loading) {
            state.layoutLoading = loading
        },
        SET_APPLOADING (state, loading) {
            state.appLoading = loading
        }
    },
    actions: {
        setLayoutLoading ({ commit }, loading) {
            commit("SET_LAYOUTLOADING", loading)
        },
        setAppLoading ({ commit }, loading) {
            commit('SET_APPLOADING', loading)
        },
        open ({ commit }) {
            commit("SET_LAYOUTLOADING", true)
        },
        close ({ commit }) {
            commit("SET_LAYOUTLOADING", false)
        }
    }
}
export default loading;
