import Vue from "vue";
import Vuex from "vuex";
import user from "./modules/user";
import dialog from "./modules/dialog";
import loading from "./modules/loading";
import settings from "./modules/settings";
import app from "./modules/app";
import tagsView from "./modules/tagsView";
import model from "./modules/model";
import conversation from "./modules/conversation";
import conversationOfLive from "./modules/conversationOfLive";

import group from "./modules/group";
import video from "./modules/video";
import friend from "./modules/friend";
import dict from "./modules/dict";
import { Message } from "element-ui";

Vue.use(Vuex);
const store = new Vuex.Store({
  state: {
    current: Date.now(), // 当前时间
    intervalID: 0,
    message: undefined,
  },
  getters: {
    roles: (state) => state.user.roles,
    hidden(state) {
      // eslint-disable-next-line no-unused-vars
      const temp = state.current;
      if (typeof document.hasFocus !== "function") {
        return document.hidden;
      }
      return !document.hasFocus();
    },
  },
  mutations: {
    startComputeCurrent(state) {
      state.intervalID = setInterval(() => {
        state.current = Date.now();
      }, 500);
    },
    stopComputeCurrent(state) {
      clearInterval(state.intervalID);
      state.intervalID = 0;
    },
    showMessage(state, options) {
      if (state.message) {
        state.message.close();
      }
      state.message = Message({
        message: options.message,
        type: options.type || "success",
        duration: options.duration || 2000,
        offset: 40,
      });
    },
  },
  modules: {
    user,
    dialog,
    loading,
    settings,
    app,
    tagsView,
    model,
    // IM begin
    conversation,
    conversationOfLive,
    group,
    friend,
    video,
    // IM end
    dict,
  },
});
export default store;
