import store from '@/store'

const { body } = document
const WIDTH = 992

export default {
  watch: {
    $route (route) {
      if (this.device === "mobile" && !this.openSidebar) {
        //移动设备 且 不显示导航栏
        store.dispatch('app/setApp', { key: 'openSidebar', value: true })
      }
    }
  },
  beforeMount () {
    window.addEventListener('resize', this.$_resizeHandler)
  },
  mounted () {
    const isMobile = this.$_isMobile()
    if (isMobile) {
      //初始化 移动设备 导航栏 显示状态：隐藏
      store.dispatch('app/setApp', { key: 'device', value: 'mobile' })
      store.dispatch('app/setApp', { key: 'openSidebar', value: true })
    }
  },
  methods: {
    $_isMobile () {
      const rect = body.getBoundingClientRect()
      return rect.width - 1 < WIDTH
    },
    $_resizeHandler () {
      if (!document.hidden) {
        const isMobile = this.$_isMobile()
        store.dispatch('app/setApp', { key: 'device', value: isMobile ? 'mobile' : 'desktop' })
        if (isMobile) {
          store.dispatch('app/setApp', { key: 'openSidebar', value: true })
        }
      }
    }
  }
}