// 客户管理
const CustomerOfCompany = () =>
  import( /* webpackChunkName: "CustomerOfCompany" */ "@/views/Customer/CustomerOfCompany")
//客户详情页
const CustomerOfCompanyDetail = () =>
  import( /* webpackChunkName: "CustomerOfCompanyDetail" */ "@/views/Customer/CustomerOfCompany/CustomerOfCompanyDetail")
//机组列表
const CustomerOfCrew = () =>
  import( /* webpackChunkName: "CustomerOfCrew" */ "@/views/Customer/CustomerOfCrew")
//批量新增机组
const CustomerOfCrewAddUnit = () =>
  import( /* webpackChunkName: "CustomerOfCrewAddUnit" */ "@/views/Customer/CustomerOfCrew/CustomerOfCrewAddUnit")
//部门管理
const DepartmentManagement = () =>
    import( /* webpackChunkName: "CustomerOfCrewAddUnit" */ "@/views/Customer/CustomerOfCompany/DepartmentManagement")
// 新同步机组
const CustomerOfSyncCrew = () =>
  import( /* webpackChunkName: "CustomerOfSyncCrew" */ "@/views/Customer/CustomerOfCrew/CustomerOfSyncCrew")
//机组服务进度
const CustomerServe = () =>
    import( /* webpackChunkName: "CustomerServe" */ "@/views/Customer/CustomerOfCrew/CustomerServe")


export default [
  {
    path: 'company',
    name: 'CustomerOfCompany',
    component: CustomerOfCompany,
    meta: { title: '客户管理' }
  },
  {
    path: 'company-des/:companyId',
    name: 'CustomerOfCompanyDetail',
    component: CustomerOfCompanyDetail,
    meta: { title: '企业详情', }
  },
  {
    path: 'crew',
    name: 'CustomerOfCrew',
    component: CustomerOfCrew,
    meta: { title: '设备管理' }
  },
  {
    path: 'crew/sync/:crewCode',
    name: 'CustomerOfSyncCrew',
    component: CustomerOfSyncCrew,
    meta: { title: '同步机组' }
  },
  {
    path: 'crew-add',
    name: 'CustomerOfCrewAddUnit',
    component: CustomerOfCrewAddUnit,
    meta: { title: '批量新增机组' }
  },
  {
    path: 'department',
    name: 'CustomerOfCrewAddUnit',
    component: DepartmentManagement,
    meta: { title: '部门管理' }
  },
  {
    path: 'crew-serve',
    name: 'CustomerServe',
    component: CustomerServe,
    meta: { title: '机组服务进度' }
  }
]