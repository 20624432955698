
import NProgress from "nprogress";
import router from "./router";
import { getToken } from "./utils/auth";
import store from "./store";
import { Message } from "element-ui"
import { roleNotEnterRoles } from "@/settings";
router.beforeEach(async (to, from, next) => {
    NProgress.start()
    let whiteList = ['/login', "/forgetPass", '/check-list', '/redirect/check-list', '/contact-desc','/authWeb']    // 白名单
    let hasToken = getToken()
    if (hasToken) {
        if (whiteList.includes(to.path)) {
            next();
        } else {
            let hasRoles = store.getters.roles.length > 0 ? true : false;
            //处理未退出登录情况下的重新登陆
            if (to.path == '/dashboard/department' && from.path == '/login') {
                await store.dispatch('user/getUserInfo')
                next();
            }
            if (hasRoles) {
                next();
            } else {
                await store.dispatch('user/getUserInfo')
                next();
            }
        }
    } else {
        if (whiteList.includes(to.path)) {
            next()
        } else {
            Message.error("请登录后操作!")
            next('/login');
        }
    }

});

router.afterEach((to, from) => {
    NProgress.done()
})

