// 发起音视频会议工作方法
import { mapState } from 'vuex'
import vue from 'vue'
export default {
  computed: {
    ...mapState({
      userID: state => state.user.userID,
      isFromGroup: state => state.video.isFromGroup,
      muteVideoUserIdList: (state) => state.video.muteVideoUserIdList,
      currentUserProfile: (state) => state.user.currentUserProfile,
    })
  },
  methods: {
    // 更新会话列表
    refreshConversationMessageList () {
      let { conversationID } = this.currentConversation
      this.$store.dispatch('updateCurrentMessageList', conversationID)
    },
    // 建立通话成功之后，当前用户的操作
    updateCallConfig (options) {
      // 响铃
      this.$store.dispatch('app/setMsgType', 'calling')
      this.$bus.$emit('msgVoiceTip')
      if (!this.isFromGroup) {
        // 避免群聊时，重复调用
        this.$store.dispatch(
          'video/updateCallComponentType',
          options.callComponentType
        )
      }
      this.refreshConversationMessageList()
      this.$store.dispatch('video/userJoinMeeting', this.currentUserProfile)
      this.$store.dispatch('video/updateCallStatus', 'calling')
      this.$store.dispatch('video/updateIsInviter', true)
      this.$store.dispatch('video/updateGroupChat', options.isFromGroup)
      // 音频通话时，置空
      if (options.callComponentType === 'voice') {
        const muteUserList = this.muteVideoUserIdList.concat(this.userID)
        this.$store.dispatch('video/updateMuteVideoUserIdList', muteUserList)
      }
    },
    // 发起trtcCalling 单聊通话  type 通话类型： video  voice
    makeTrtcCalling (type) {
      return new Promise((resolve, reject) => {
        this.$trtcCalling
          .call({
            userID: this.toAccount,
            type,
            timeout: process.env.VUE_APP_ENV === 'development' ? 20 : 60,
          })
          .then(() => {
            resolve()
          })
          .catch((err) => {
            console.log('单聊trtcCalling makeTrtcCalling is error', err)
            vue.prototype.$Message.error('获取用户设备权限失败');
            reject()
          })
      })
    },
    // 发起trtcCalling 群通话  type 通话类型： video  voice
    makeGroupTrtcCalling (type, list,groupID) {
      return new Promise((resolve, reject) => {
        this.$trtcCalling
          .groupCall({
            userIDList: list,
            groupID: groupID,
            type,
            timeout: process.env.VUE_APP_ENV === 'development' ? 20 : 60,
          })
          .then(() => {
            resolve()
          })
          .catch((err) => {
            console.log('trtcCalling makeTrtcCalling is error', err)
            vue.prototype.$Message.error('获取用户设备权限失败');
            reject()
          })
      })
    },
    // 挂断电话的操作
    handleHangup: async function () {
      this.$emit('holdUpCall')
    },
  }
}