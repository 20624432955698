<template>
    <div v-if="dialogVisible" class="box">
        <section class="member-list">
            <template v-for="(member,key) in memberList">
                <div class="member-item" :key="'member'+member.userID+key" v-if="member.userID!==userID">
                    <el-checkbox
                            v-model="member.selected"
                            @change="updateCheckBox"
                            :key="'checkbox'+member.userID+key"
                            :disabled="member.disabled"
                    ></el-checkbox>
                    <div class="user-info" @click="toggleCheck(key)">
                        <el-image :src="member.avatar"></el-image>
                        <span>{{member.nick}}</span>
                    </div>
                </div>
            </template>
        </section>
        <div class="member-operator">
            <el-button size="mini" type="success" @click="submit">确定</el-button>
            <el-button size="mini" type="danger" @click="cancel">取消</el-button>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import trtcCalling from './../mixins/trtcCalling'
    export default {
        name: "current-invite",
        mixins: [trtcCalling],
        computed: {
            ...mapState({
                currentConversation: (state) => state.conversation.currentConversation,
                showGroupMemberList: (state) => state.group.showGroupMemberList,
                userID: (state) => state.user.userID,
                meetingUserList: (state) => state.video.meetingUserList,
                callStatus: (state) => state.video.callStatus,
                callComponentType: (state) => state.video.callComponentType,
            }),
        },
        props:['dialogVisible'],
        watch: {
            dialogVisible:{
                handler: function (val) {
                    this.getMemberList()
                },
                immediate: true,
                deep: true,
            },
            showGroupMemberList: {
                handler: function (val) {
                    if (val) {
                        this.getMemberList()
                    }
                },
                immediate: true,
                deep: true,
            },
            meetingUserList:{
                handler: function (val) {
                    if (val) {
                        this.forUser()
                    }
                },
                immediate: true,
                deep: true,
            }
        },
        data() {
            return {
                memberList: [],
                dialogVisible1:false
            }
        },
        created() {
           this.getMemberList()
        },
        mounted() {
            console.log(this.currentConversation.groupProfile)
        },
        methods:{
            updateCheckBox () {
                this.$forceUpdate()
            },
            getMemberList() {
                return new Promise((resolve, reject) => {
                    let data = {
                        groupID: this.currentConversation.groupProfile.groupID,
                        count: 100,
                        offset: 0,
                    }
                    this.tim
                        .getGroupMemberList(data)
                        .then(({ data }) => {
                            let { memberList } = data
                            if (memberList.length) {
                                memberList.map((item) => {
                                    this.$set(item,'select',false)
                                    this.$set(item,'disabled',false)
                                    // item.selected = false
                                    // item.disabled = false
                                    return item
                                })
                            }

                            this.memberList = memberList
                            console.log(this.memberList)
                            this.forUser()
                            resolve()
                        })
                        .catch((err) => {
                            reject()
                        })
                })
            },
            //循环用户
            forUser(){
                this.memberList.map( (item) => {
                    item.selected = false
                    item.disabled = false
                    this.meetingUserList.map((item1) => {
                        if(item1.userID === item.userID){
                            item.selected = true
                            item.disabled = true
                        }
                    })
                })
            },
            toggleCheck(key) {
                this.memberList[key].selected = !this.memberList[key].selected
                this.updateCheckBox()
            },
            submit() {
                console.log(this.memberList)
                let userData = []
                this.memberList.map((item)=>{
                    if(item.selected===true&&item.disabled===false){
                        userData.push(item)
                    }
                })
                console.log(userData)
                if (this.callComponentType === 'voice') {
                    this.voiceCall(userData)
                } else if (this.callComponentType === 'video') {
                    this.videoCall(userData)
                }
                // this.voiceCall(userData)
                this.cancel()
            },
            // 发起语音通话 - 群聊
            voiceCall(memberList) {
                let list = []
                memberList.map((item) => {
                    if (item.selected || item.userID === this.userID) {
                        list.push(item.userID)
                    }
                })
                if (list.includes(this.userID)) {
                    list.splice(list.indexOf(this.userID), 1)
                }
                console.log('list member', list)
                this.makeGroupTrtcCalling(
                    this.TrtcCalling.CALL_TYPE.AUDIO_CALL,
                    list,
                    this.currentConversation.groupProfile.groupID
                ).then(() => {
                    console.log(this.callStatus)
                    // this.updateCallConfig({ callComponentType: 'voice', isFromGroup: true })
                })
            },
            // 发起视频通话 - 群聊
            videoCall(memberList) {
                let list = []
                memberList.map((item) => {
                    if (item.selected || item.userID === this.userID) {
                        list.push(item.userID)
                    }
                })
                if (list.includes(this.userID)) {
                    list.splice(list.indexOf(this.userID), 1)
                }
                console.log('list member', list)
                this.makeGroupTrtcCalling(
                    this.TrtcCalling.CALL_TYPE.VIDEO_CALL,
                    list,
                    this.currentConversation.groupProfile.groupID
                ).then(() => {
                    // this.updateCallConfig({ callComponentType: 'video', isFromGroup: true })
                })
                return
            },
            cancel(){
                this.dialogVisible1 = false
                this.$emit('show', this.dialogVisible1);
            }
        }
    }
</script>

<style lang='scss' scoped>
    .box{
        position: absolute;
        width: 80%;
        height: 70%;
        text-align: center;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background: #fff;
        z-index: 1000000;
        margin: auto;
    }
    .member-list {
        width: 100%;
        height: 80%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        overflow-y: scroll;
        margin-top:20px;
        margin-left:20px;
    .member-item {
        display: flex;
        justify-content: center;
        height: 90px;
    }
    .user-info {
        display: flex;
        flex-direction: column;
        height: 90px;
        margin-right: 10px;
        cursor: pointer;
    .el-image {
        width: 60px;
        height: 60px;
        border-radius: 50%;
    }
    span {
        color: #333;
        font-size: 14px;
        text-align: center;
        height: 22px;
        line-height: 22px;
        display: inline-block;
    }
    }
    }
    ::v-deep .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner{
        background-color: #409eff;
        border-color: #409eff;
    }
</style>