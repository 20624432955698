import axios from 'axios'
import API_HOST from './host'
import Qs from 'qs'
import store from "@/store";
import router from "@/router";
import { getToken } from "@/utils/auth";
import { Message } from 'element-ui'
let baseURL = API_HOST
if (API_HOST === 'http://125.124.208.150:8083/hql') {
    //线上环境支持外网和内网访问
    baseURL = window.location.origin + '/hql'
}
const instance = axios.create({
    baseURL,
    timeout: 1000 * 20,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        "Content-Type": "multipart/form-data",
    }
})
instance.defaults.transformRequest = [function (data, config) {
    if (!config['Content-Type']) return Qs.stringify(data)
    if (config['Content-Type'].toLowerCase().indexOf('application/json') > -1) {
        return JSON.stringify(data)
    } else if (config['Content-Type'].toLowerCase().indexOf('multipart/form-data') > -1) {
        return data
    } else {
        return Qs.stringify(data)
    }
}]

// 添加请求拦截器
instance.interceptors.request.use(
    config => {
        //白名单   :  环境点检表页面SeriousForm.vue中的接口加入白名单，然后再接口内部定义token
        let arr = ["/admin/auth/login", "/admin/auth/updPassword", "/admin/auth/sendSmsCode", '/open/file/getContactform']
        if (!arr.includes(config.url)) {
            config.headers['X-Qst-Token'] = getToken();
        }
        config.headers['X-Token-currentTenantId'] = localStorage.getItem('locale2') || '0'
        return config
    },
    error => Promise.error(error)
)

// 添加响应拦截器
instance.interceptors.response.use(
    // 响应成功
    res => {
        if (res.status === 200) {
            let { code } = res.data
            if (process.env.NODE_ENV === "development") {
                if (res.data.code !== 200) {
                    console.log("%c 错误接口：" + res.config.url + ",错误信息：" + res.data.msg, "color:red");
                }
            }
            if (typeof code !== "undefined" && code == 200) {
                return Promise.resolve(res.data)
            } else {
                let { code } = res.data;
                // 501 请先登录   5000 token过期  5002 刷新token失败   502 : bal gateway
                if (code == 501 || code == 5000 || code == 5002) {
                    router.push("/login")
                }
                store.dispatch('loading/setLayoutLoading', false)
                return Promise.reject(res.data)
            }
        } else {
            Message.error("服务异常！")
            return Promise.reject(res)
        }
    },
    error => {
        const { response } = error
        if (response) { // 收到响应，但是不在2xx的范围
            let code = response.status
            store.dispatch('loading/setLayoutLoading', false)
            Message.error("服务异常！错误代码：" + code)
            // router.push('/login')
            return Promise.reject(response);
        } else { // 请求超时或断网时
            store.dispatch('loading/setLayoutLoading', false)
            // router.push('/login')
            Message.error("请检查网络连接！");
            // return Promise.reject(response);
        }
    }
)

export default instance

export const download = (remoteUrl, fileName) => {
    axios({
        method: 'get',
        url: remoteUrl,
        responseType: 'blob'
    }).then(res => {
        let url = URL.createObjectURL(res.data)
        let dom = document.createElement('a')
        dom.href = url
        dom.download = fileName
        document.body.appendChild(dom)
        dom.click()
        document.body.removeChild(dom)
    })
}
