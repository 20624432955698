const conversationOfLiveModules = {
  namespaced: true,
  state: {
    currentMessageList: [],
    joinRoom: false, //是否加入房间
  },
  mutations: {
    /**
     * 将消息插入当前会话列表
     * 调用时机：收/发消息事件触发时
     * @param {Object} state
     * @param {Message[]|Message} data
     * @returns
     */
    pushCurrentMessageList (state, data) {
      if (Array.isArray(data)) {
        state.currentMessageList = [...state.currentMessageList, ...data]
      } else {
        state.currentMessageList = [...state.currentMessageList, data]
      }
    },
    /**
     * 从当前消息列表中删除某条消息
     * @param {Object} state
     * @param {Message} message
     */
    reset (state) {
      Object.assign(state, {
        currentMessageList: [],
        joinRoom: false,
      })
    },
    toggleIsJoinRoom (state, bstop) {
      state.joinRoom = bstop
    }
  },
  actions: {
    pushCurrentMessageList ({ commit }, data) {
      commit('pushCurrentMessageList', data)
    },
    toggleIsJoinRoom ({ commit }, bstop) {
      commit('toggleIsJoinRoom', bstop)
    },
    reset ({ commit }) {
      commit('reset')
    },
  }
}

export default conversationOfLiveModules
