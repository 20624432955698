<template>
  <div>
    <el-form :model="form" :rules="rules" ref="createGroupForm" label-width="100px">
      <!-- <el-form-item label="群ID">
        <el-input v-model="form.groupID"></el-input>
      </el-form-item>-->
      <el-form-item label="群名称" prop="name">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <!-- <el-form-item label="群类型" v-if="false">
        <el-select v-model="form.type">
          <el-option label="Work" :value="TIM.TYPES.GRP_WORK"></el-option>
          <el-option label="Public" :value="TIM.TYPES.GRP_PUBLIC"></el-option>
          <el-option label="Meeting" :value="TIM.TYPES.GRP_MEETING"></el-option>
          <el-option label="AVChatRoom" :value="TIM.TYPES.GRP_AVCHATROOM"></el-option>
        </el-select>
      </el-form-item>-->
      <el-form-item label="群头像地址">
        <el-input v-model="form.avatar"></el-input>
      </el-form-item>
      <!-- <el-form-item label="群简介">
        <el-input type="textarea" v-model="form.introduction" :maxlength="240"></el-input>
      </el-form-item>-->
      <!-- <el-form-item label="群公告" v-if="false">
        <el-input type="textarea" v-model="form.notification" :maxlength="300"></el-input>
      </el-form-item>-->
      <!-- <el-form-item label="加群方式" v-if="false">
        <el-radio-group v-model="form.joinOption" :disabled="joinOptionDisabled">
          <el-radio label="FreeAccess">自由加群</el-radio>
          <el-radio label="NeedPermission">需要验证</el-radio>
          <el-radio label="DisableApply">禁止加群</el-radio>
        </el-radio-group>
      </el-form-item>-->
      <el-form-item label="群成员列表">
        <el-select
          v-model="form.memberList"
          default-first-option
          multiple
          filterable
          remote
          :disabled="form.type === TIM.TYPES.GRP_AVCHATROOM"
          :remote-method="handleSearchUser"
          :loading="loading"
          placeholder="请输入好友名称"
        >
          <el-option
            v-for="item in options"
            :key="'create-group_friend'+item.friendImId"
            :label="item.realName"
            :value="item.friendImId"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button type="primary" @click="onSubmit('createGroupForm')">立即创建</el-button>
      <el-button @click="closeCreateGroupModel">取消</el-button>
    </div>
  </div>
</template>

<script>
import {
  Form,
  FormItem,
  Input,
  Select,
  Option,
  Radio,
  RadioGroup,
} from 'element-ui'
import { getFriendList } from '@/api/im'
import { mapState } from 'vuex'
import chat from './../mixins/chat'
export default {
  mixins: [chat],
  components: {
    ElForm: Form,
    ElFormItem: FormItem,
    ElInput: Input,
    ElSelect: Select,
    ElOption: Option,
    ElRadioGroup: RadioGroup,
    ElRadio: Radio,
  },
  data() {
    return {
      form: {
        groupID: '',
        name: '',
        type: this.TIM.TYPES.GRP_WORK,
        avatar: '',
        introduction: '',
        notification: '',
        joinOption: 'FreeAccess',
        memberList: [],
      },
      options: [],
      loading: false,
      rules: {
        name: [{ required: true, message: '请输入群名称', trigger: 'blur' }],
      },
    }
  },
  computed: {
    joinOptionDisabled() {
      return [
        this.TIM.TYPES.GRP_WORK,
        this.TIM.TYPES.GRP_MEETING,
        this.TIM.TYPES.GRP_AVCHATROOM,
      ].includes(this.form.type)
    },
    ...mapState({
      createGroupModelVisible: (state) => {
        return state.group.createGroupModelVisible
      },
    }),
  },
  watch: {
    createGroupModelVisible: {
      handler(val) {
        if (val) {
          this.resetForm()
        }
      },
      immediate: true,
    },
  },
  methods: {
    resetForm() {
      this.form.groupID = ''
      this.form.name = ''
      this.form.type = this.TIM.TYPES.GRP_WORK
      this.form.avatar = ''
      this.form.introduction = ''
      this.form.notification = ''
      this.form.joinOption = 'FreeAccess'
      this.form.memberList = []
      this.options = []
    },
    onSubmit(ref) {
      this.$refs[ref].validate((valid) => {
        if (!valid) {
          return false
        }
        this.createGroup()
      })
    },
    closeCreateGroupModel() {
      this.$store.dispatch('group/updateCreateGroupModelVisible', false)
    },
    createGroup() {
      this.tim
        .createGroup(this.getOptions())
        .then((imResponse) => {
          this.$store.commit('showMessage', {
            message: `群组：【${imResponse.data.group.name}】创建成功`,
            type: 'success',
          })
          this.closeCreateGroupModel()
          this.getGroupList()
          this.$store.dispatch(
            'checkoutConversation',
            `GROUP${imResponse.data.group.groupID}`
          )
        })
        .catch((error) => {
          this.$store.commit('showMessage', {
            type: 'error',
            message: error.message,
          })
        })
    },
    getOptions() {
      let options = {
        ...this.form,
        memberList: this.form.memberList.map((userID) => ({
          userID,
        })),
      }
      if (
        [this.TIM.TYPES.GRP_WORK, this.TIM.TYPES.GRP_AVCHATROOM].includes(
          this.form.type
        )
      ) {
        delete options.joinOption
      }
      return options
    },
    handleSearchUser(userID) {
      if (userID !== '') {
        this.loading = true
        let data = {
          page: 1,
          limit: 30,
          realName: userID,
        }
        getFriendList(data)
          .then((res) => {
            let {
              data: { list },
            } = res
            this.options = list
            this.loading = false
          })
          .catch((err) => {
            this.loading = false
            this.$store.commit('showMessage', {
              type: 'error',
              message: err.message,
            })
          })
      }
    },
  },
}
</script>

<style lang="stylus" scoped></style>
