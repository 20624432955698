const HTCSWebCustomer = () =>
  import( /* webpackChunkName: "HTCSWebCustomer" */ "@/views/HTCSWeb/HTCSWebCustomer")
const HTCSWebCustomerOfAdd = () =>
  import( /* webpackChunkName: "HTCSWebCustomerOfAdd" */ "@/views/HTCSWeb/HTCSWebCustomer/HTCSWebCustomerOfAdd")

const HTCSWebCustomerOfEdit = () =>
  import( /* webpackChunkName: "HTCSWebCustomerOfEdit" */ "@/views/HTCSWeb/HTCSWebCustomer/HTCSWebCustomerOfEdit")
export default [
  {
    path: "list",
    name: "HTCSWebCustomer",
    component: HTCSWebCustomer,
    meta: { title: "客服管理" }
  },
  {
    path: "add",
    name: "HTCSWebCustomerOfAdd",
    component: HTCSWebCustomerOfAdd,
    meta: { title: "新增客服" }
  },
  {
    path: "edit/:id",
    name: "HTCSWebCustomerOfEdit",
    component: HTCSWebCustomerOfEdit,
    meta: { title: "修改客服" }
  },
]