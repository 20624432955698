import api from './request'

/**
 * 根据token获取用户相关信息
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getUserInfo = () => {
    return api.get("/admin/auth/info")
}
/**
 * 用户登录
 * @param params
 * @returns {Promise<AxiosResponse<T>>}
 */
export const login = (params) => {
    return api.post("/admin/auth/login", params);
}
/**
 * HTCS平台的登录功能
 */
export const loginByAccount = (params) => {
    return api.post('/app/auth/loginByAccount', params, { baseURL: 'https://39.100.94.39:8086/qst' })
}
/**
 * 获取短信验证码
 * @param params
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getSmsCode = (params) => {
    return api.get("/admin/auth/sendSmsCode", { params })
}
/**
 * 修改密码
 * @param params
 * @returns {Promise<AxiosResponse<T>>}
 */
export const modifyPassword = (params) => {
    return api.post("/admin/auth/updPassword", params)
}
/**
 * 用户安全退出
 * @returns {Promise<AxiosResponse<T>>}
 */
export const loginOut = () => {
    return api.post('/admin/auth/logout')
}
/**
 * 上传文件
 * @param params
 * @returns {Promise<AxiosResponse<T>>}
 */
export const uploadFile = (params) => {
    return api.post("/open/file/upload", params, { headers: { "Content-Type": "multipart/form-data" }, timeout: 1000 * 120 })
}
/**
 * 批量上传文件
 * @param params
 * @returns {Promise<AxiosResponse<T>>}
 */
export const uploadFileList = (params) => {
    return api.post("open/file/listUpload", params, { headers: { "Content-Type": "multipart/form-data" }, timeout: 1000 * 120 })
}
/**
 * 删除图片
 * @param params
 * @returns {Promise<AxiosResponse<T>>}
 */
export const delUploadFile = (params) => {
    return api.get("/open/file/del", { params })
}
/**
 * 获取部门信息list
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getDepartmentList = () => {
    return api.get("/admin/department/getJsonList");
}
/**
 * 获取所有员工
 * @param params
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getDepartmentStaffList = (params) => {
    return api.post("/admin/departmentUser/getList", params);
}
/**
 * 关键字检索员工信息【指派出差，输入框选择员工】
 * @param params
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getStaffListByKeyword = (params) => {
    return api.post("/admin/departmentUser/getDpmUserList", params);
}
/**
 * 获取  用户单位List(单位名搜索)
 * @param params
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getCompanyList = (params) => {
    return api.get("/admin/crew/company/getList", { params })
}
/**
 * 获取 地址所有地址list
 * @param params
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getAddressByCityType = (params) => {
    return api.get("/admin/address/getAllList", { params })
}
/**
 * 地址模糊list
 * @param {*} params 
 */
export const getAddressByCityName = (params) => {
    return api.get("/open/address/searchList", { params })
}
/**
 * 新增 指派出差任务  or  技术交流
 * @param params
 * @returns {Promise<AxiosResponse<T>>}
 */
export const addTripTask = (params) => {
    return api.post("/admin/crew/trip/add", params, { headers: { "Content-Type": "application/json" } })
}

/**
 * 获取机组不同阶段所处的不同服务内容
 * @param params
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getCrewCodeServerTypeList = (params) => {
    return api.get("/admin/crew/trip/getCrewCodeServerTypeList", { params })
}
/**
 * 获取机组详情 【机组详情(精简)】
 * @param params
 * @returns {Promise<AxiosResponse<T>>}
 */
export const getCrewDetailByCrewCode = (params) => {
    return api.get("/admin/crew/info/getDetail", { params })
}

/**
 * 用户单位新增/修改
 * @param params
 * @returns {Promise<AxiosResponse<T>>}
 */
export const updCompany = (params) => {
    return api.post('/admin/crew/company/updCompany', params)
}
/**
 * 获取码表
 */
export const getSystemConfig = (params) => {
    return api.get('/admin/systemConfig/getKey', { params })
}
/**
 * 获取socket历史消息
 */
export const listWhieBoardHistoryMsg = (params) => {
    return api.get('open/socket/listWhieBoardHistoryMsg', { params })
}
/**
 * 清空socket历史消息
 */
export const cleanWhieBoardHistoryMsg = (params) => {
    return api.get('v1/pushMsg/cleanWhieBoardHistoryMsg', { params })
}
/**
 * 获取新token
 */
export const getNewWebToken = (params) => {
    return api.get('/admin/auth/getNewWebToken', { params })
}