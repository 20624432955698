<template>
  <div>
    <!-- <el-input v-model="userID" placeholder="输入userID后 按回车键" @keydown.enter.native="addGroupMember"></el-input> -->
    <el-select
      v-model="userID"
      filterable
      remote
      placeholder="请输入关键字搜索好友"
      :remote-method="remoteMethod"
      :loading="loading"
      @change="addGroupMember"
    >
      <el-option
        v-for="(user,key) in list"
        :key="'friend'+user.friendImId+key"
        :label="user.realName"
        :value="user.friendImId"
      ></el-option>
    </el-select>
    <!-- reserve-keyword -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getFriendList } from '@/api/im'
export default {
  components: {},
  data() {
    return {
      userID: '',
      loading: false,
      list: [],
    }
  },
  computed: {
    ...mapState({
      currentConversation: (state) => state.conversation.currentConversation,
    }),
  },
  created() {
    this.remoteMethod('')
  },
  methods: {
    resetData() {
      this.list = []
      this.userID = ''
    },
    addGroupMember() {
      console.log('addGroupMember')
      if (this.userID.length === 0) {
        return
      }
      const groupID = this.currentConversation.conversationID.replace(
        'GROUP',
        ''
      )
      this.tim
        .addGroupMember({
          groupID,
          userIDList: [this.userID],
        })
        .then((imResponse) => {
          const { successUserIDList, failureUserIDList, existedUserIDList } =
            imResponse.data
          let userList = this.list.filter((i) => i.friendImId == this.userID)
          let userName = userList.length ? userList[0].realName : this.userID
          if (successUserIDList.length > 0) {
            this.$store.commit('showMessage', {
              message: `群成员：${userName}加群成功`,
              type: 'success',
            })
            this.tim
              .getGroupMemberProfile({ groupID, userIDList: successUserIDList })
              .then(({ data: { memberList } }) => {
                this.$store.dispatch(
                  'group/updateCurrentMemberList',
                  memberList
                )
              })
            this.resetData()
          }
          if (failureUserIDList.length > 0) {
            this.$store.commit('showMessage', {
              message: `群成员：${userName}添加失败！`,
              type: 'error',
            })
          }
          if (existedUserIDList.length > 0) {
            this.$store.commit('showMessage', {
              message: `群成员：${userName}已在群中`,
            })
            this.resetData()
          }
          this.remoteMethod('')
        })
        .catch((error) => {
          this.$store.commit('showMessage', {
            type: 'error',
            message: error.message,
          })
        })
    },
    remoteMethod(query) {
        this.loading = true
        let data = {
          page: 1,
          limit: 30,
          realName: query,
        }
        getFriendList(data)
          .then((res) => {
            let {
              data: { list },
            } = res
            this.list = list
            this.loading = false
          })
          .catch((err) => {
            this.loading = false
            this.$store.commit('showMessage', {
              type: 'error',
              message: err.message,
            })
          })
    },
  },
}
</script>

<style lang="stylus" scoped></style>
