import { render, staticRenderFns } from "./call-layer-video.vue?vue&type=template&id=5dcade8d&scoped=true&"
import script from "./call-layer-video.vue?vue&type=script&lang=js&"
export * from "./call-layer-video.vue?vue&type=script&lang=js&"
import style0 from "./call-layer-video.vue?vue&type=style&index=0&id=5dcade8d&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5dcade8d",
  null
  
)

export default component.exports