import Vue from "vue";
import { parseTime, random } from '@/utils'
// Android/iOS设备判断
const getDevice = () => {
  let ua = navigator.userAgent
  if (/(iPhone|iPad|iPod|iOS)/i.test(ua)) {
    return 'IOS'
  } else if (/(Android)/i.test(navigator.userAgent)) {
    return 'ANDROID'
  } else {
    return 'H5'
  }
}

const parseDate = (val) => {
  let d = new Date(val).getDay()
  let weeks = new Array(
    '周日',
    '周一',
    '周二',
    '周三',
    '周四',
    '周五',
    '周六'
  )
  return weeks[d]
}
// 自定义时间，开课时间显示
const parseDate1 = (start, end) => {
  // 同一天
  let str = ''
  const format = '{y}/{m}/{d}'
  const mformat = '{h}:{i}'
  if (parseTime(start, format) === parseTime(end, format)) {
    str = parseTime(start, format) + `( ${parseDate(start)} ) ${parseTime(start, mformat)} ~ ${parseTime(end, mformat)}`
  } else {
    str = parseTime(start, format) + `( ${parseDate(start)} ) ${parseTime(start, mformat)} ~ ${parseTime(end, format + ' ' + mformat)}`
  }
  return str
}


const filters = {
  getDevice,
  parseTime,
  parseDate,
  parseDate1,
  random
}

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});
