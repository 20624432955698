const HTCSWebVideos = () =>
  import( /* webpackChunkName: "HTCSWebVideos" */ "@/views/HTCSWeb/HTCSWebVideos")
const HTCSWebVideosOfAdd = () =>
  import( /* webpackChunkName: "HTCSWebVideosOfAdd" */ "@/views/HTCSWeb/HTCSWebVideos/HTCSWebVideosOfAdd")
const HTCSWebVideosOfEdit = () =>
  import( /* webpackChunkName: "HTCSWebVideosOfEdit" */ "@/views/HTCSWeb/HTCSWebVideos/HTCSWebVideosOfEdit")

export default [
  {
    path: "list",
    name: "HTCSWebVideos",
    component: HTCSWebVideos,
    meta: { title: "视频管理" }
  },
  {
    path: "add",
    name: "HTCSWebVideosOfAdd",
    component: HTCSWebVideosOfAdd,
    meta: { title: "新增视频" }
  },
  {
    path: "edit/:id",
    name: "HTCSWebVideosOfEdit",
    component: HTCSWebVideosOfEdit,
    meta: { title: "修改视频" }
  },
]