<!-- 消息提示音 -->
<template>
  <section class="msg-voice-tip">
    <audio src="./msg-tip.mp3" id="msgTip"></audio>
    <audio src="./calling.mp3" loop id="calling"></audio>
    <audio src="./hold-up.mp3" id="holdUp"></audio>
  </section>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'MessageTipVoice',
  components: {},
  computed: {
    ...mapState({
      msgType: (state) => state.app.msgType,
    }),
  },
  watch: {},
  data() {
    return {
      playerOfMsgTip: null,
      playerOfCalling: null,
      playerOfHoldUp: null,
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.playerOfMsgTip = this.$el.querySelector('#msgTip')
      this.playerOfCalling = this.$el.querySelector('#calling')
      this.playerOfHoldUp = this.$el.querySelector('#holdUp')
    },
    play() {
      if (this.msgType === 'cancelCalling') {
        this.playerOfCalling.pause()
        return
      }
      let obj = {
        txt: 'MsgTip',
        calling: 'Calling',
        holdUp: 'HoldUp',
      }
      if (this['playerOf' + obj[this.msgType]]) {
        if (obj[this.msgType] === 'HoldUp') {
          this.playerOfCalling.pause()
        }
        this['playerOf' + obj[this.msgType]].play()
      } else {
        this.init()
        setTimeout(() => {
          this.play()
        }, 200)
      }
    },
  },
}
</script>
<style lang='scss' scoped>
.msg-voice-tip {
  position: fixed;
  z-index: 9999999;
}
</style>