<template>
    <div>
        <div v-for="(item,index) in liveData" class="">
            <div :id='"videoPlayer"+item.deviceId' class="small" style=" width: calc(100% - 64px - 270px);
        height: calc(100% - 104px) !important;">
                <div style="position: absolute;top: 0px;z-index: 1;height: 80%;width: 100%;" @click="big(item)"></div>
                <div>{{item.deviceName}}</div>
            </div>
        </div>
        <!--        <div id='id_test_video1' style="width:100%; height:auto;"></div>-->
    </div>


</template>
<!--<script src="@/utils/TcPlayer-2.4.1.js"></script>-->
<script>
    import {mapState} from "vuex";

    export default {
        name: "PlayerLiveGroup",
        props:['liveData','selectUserIdOfGroup'],
        computed: {
            ...mapState({
                userID: (state) => state.user.userID,
            }),
        },
        inject: ['reload'],
        watch: {
            liveData: {
                handler(val,old) {
                    this.$nextTick(()=>{
                        if(old!==undefined){
                            old.forEach((item,index)=>{
                                this.vars["tcplyaer" + item.deviceId].destroy()
                                // if(document.getElementById('videoPlayer'+item.deviceId)!==null){
                                //     document.getElementById('videoPlayer'+item.deviceId).removeChild(document.getElementById('videoPlayer'+item.deviceId).childNodes[0])
                                // }

                            })
                        }
                    })
                    if (val.length>0) {
                        this.liveData = val
                        this.$nextTick(()=>{
                            this.liveData.forEach((item,index)=>{
                                this.live(item)
                            })
                        })
                    }else{
                        // this.reload()
                    }
                },
                immediate: true,
                deep:true
            },
            selectUserIdOfGroup:{
                handler(val,old){
                   console.log(val,old)
                    if(val!==''){
                        this.liveData.forEach((item,index)=>{
                            let  playerName = "tcplyaer" + item.deviceId;
                            this['width'+playerName]='256'
                            this['height'+playerName] = '144'
                            document.getElementById("videoPlayer"+item.deviceId).className = 'small'
                            this.vars["tcplyaer" + item.deviceId].destroy()
                            this.live(item)
                        })
                    }
                }
            }
        },
        data (){
            return{
                vars: {},
            }
        },
        mounted() {
            // this.live()
        },
        methods:{
            live(item){
                var varName = "tcplyaer" + item.deviceId; //动态定义变量名
                this.vars[varName] = new TcPlayer('videoPlayer'+item.deviceId, {
                    "m3u8": item.playUrl, //请替换成实际可用的播放地址
                    "autoplay" : true,      //iOS 下 safari 浏览器，以及大部分移动端浏览器是不开放视频自动播放这个能力的
                    "poster" : "http://www.test.com/myimage.jpg",
                    "width" :  this['width'+varName]!=undefined?this['width'+varName]:'256',//视频的显示宽度，请尽量使用视频分辨率宽度
                    "height" : this['height'+varName]!=undefined?this['height'+varName]:'144',//视频的显示高度，请尽量使用视频分辨率高度
                    "live" :true
                });
            },
            big(value){
                var varName = "tcplyaer" + value.deviceId; //动态定义变量名
                console.log(this.vars[varName])
                this.liveData.forEach((item,index)=>{
                    let  playerName = "tcplyaer" + item.deviceId;
                    if( varName !==playerName){
                        this['width'+playerName]='256'
                        this['height'+playerName] = '144'
                        document.getElementById("videoPlayer"+item.deviceId).className = 'small'
                    }
                    this.vars["tcplyaer" + item.deviceId].destroy()
                    this.live(item)
                })
                if(this['width'+varName] == '100%'){
                    console.log(123)
                    this['width'+varName] = '256'
                    this.$emit('userIdData',this.userID)
                    document.getElementById("videoPlayer"+value.deviceId).className = 'small'
                }else{
                    this['width'+varName] = '100%'
                    this.$emit('userIdData','')
                    document.getElementById("videoPlayer"+value.deviceId).className = 'big'
                }
                if(this['height'+varName] == '100%'){
                    this['height'+varName] = '144'
                }else{
                    this['height'+varName] = '100%'
                }
                this.vars["tcplyaer" + value.deviceId].destroy()
                this.live(value)

            }
        },

    }
</script>

<style lang="scss" scoped>
    ::v-deep.vcp-volume{
        display: none !important;
    }
    .big{
        position: absolute !important;
        width: calc(100% - 64px - 270px) !important;
        height: calc(100% - 104px) !important;
        top: 0px;
        margin-left: 270px;
        z-index:31;
    ::v-deep video {
        object-fit: contain !important;
    }
    }
    .small{
        width:256px !important;
        height:auto;
        margin-top:10px;
        position: relative;
    }
</style>