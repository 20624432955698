<template>
  <div class="list-container">
    <div class="header-bar">
      <el-input
        v-model="groupName"
        placeholder="输入群组名称搜索"
        @keypress.enter.native="searchGroupByGroupName"
      ></el-input>
      <button title="创建群组" @click="showCreateGroupModel">
        <i class="tim-icon-add"></i>
      </button>
    </div>
    <div class="group-container">
      <group-item v-for="group in newGroupList" :key="group.groupID" :group="group" />
      <el-dialog
        title="创建群组"
        :visible="createGroupModelVisible"
        @close="closeCreateGroupModel"
        width="30%"
        :append-to-body="true"
      >
        <create-group></create-group>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { Dialog, Autocomplete } from 'element-ui'
import CreateGroup from './create-group.vue'
import GroupItem from './group-item.vue'
export default {
  data() {
    return {
      groupName: '',
      newGroupList: [],
      hideSearchLoading: true,
    }
  },
  components: {
    GroupItem,
    ElDialog: Dialog,
    CreateGroup,
    ElAutocomplete: Autocomplete,
  },
  computed: {
    groupListOfMid: function () {
      return this.$store.state.group.groupList.filter(
        (i) => i.type !== 'AVChatRoom'
      )
    },
    ...mapState({
      isGroupPane: (state) => state.group.isGroupPane,
      groupList: (state) => state.group.groupList,
      createGroupModelVisible: (state) => {
        return state.group.createGroupModelVisible
      },
    }),
  },
  watch: {
    isGroupPane: {
      handler(val) {
        if (val) {
          this.getGroupList()
        }
      },
      immediate: true,
    },
    groupList: {
      handler(val) {
        this.newGroupList = val.filter((i) => i.type !== 'AVChatRoom')
      },
      deep: true,
    },
  },
  methods: {
    showCreateGroupModel() {
      this.$store.commit('group/updateCreateGroupModelVisible', true)
    },
    closeCreateGroupModel() {
      this.$store.commit('group/updateCreateGroupModelVisible', false)
    },
    getGroupList() {
      this.tim
        .getGroupList()
        .then(({ data: groupList }) => {
          this.$store.dispatch('group/updateGroupList', groupList.groupList)
          this.newGroupList = this.groupListOfMid
        })
        .catch((error) => {
          this.$store.commit('showMessage', {
            type: 'error',
            message: error.message,
          })
        })
    },
    searchGroupByGroupName() {
      if (this.groupName.length === 0) {
        this.newGroupList = this.groupListOfMid
        return
      }
      let reg = new RegExp(this.groupName, 'ig')
      this.newGroupList = this.groupListOfMid.filter((i) => reg.test(i.name))
    },
    applyJoinGroup(group) {
      this.tim
        .joinGroup({ groupID: group.groupID })
        .then(async (res) => {
          switch (res.data.status) {
            case this.TIM.TYPES.JOIN_STATUS_WAIT_APPROVAL:
              this.$store.commit('showMessage', {
                message: '申请成功，等待群管理员确认。',
                type: 'info',
              })
              break
            case this.TIM.TYPES.JOIN_STATUS_SUCCESS:
              await this.$store.dispatch(
                'checkoutConversation',
                `GROUP${res.data.group.groupID}`
              )
              this.$store.commit('showMessage', {
                message: '加群成功',
                type: 'success',
              })
              break
            case this.TIM.TYPES.JOIN_STATUS_ALREADY_IN_GROUP:
              this.$store.commit('showMessage', {
                message: '您已经是群成员了，请勿重复加群哦！',
                type: 'info',
              })
              break
            default:
              break
          }
        })
        .catch((error) => {
          this.$store.commit('showMessage', {
            message: error.message,
            type: 'error',
          })
        })
    },
  },
}
</script>

<style lang="stylus" scoped>
.list-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .group-container {
    overflow-y: scroll;
  }

  .header-bar {
    display: flex;
    flex-shrink: 0;
    height: 50px;
    border-bottom: 1px solid $background-deep-dark;
    padding: 10px 10px 10px 20px;

    .group-seach-bar {
      width: 100%;
      margin-right: 10px;

      >>> .el-input {
        input {
          color: $first;
          border: none;
          border-radius: 30px;
          background-color: $deep-background !important;

          &::placeholder {
            color: $font-dark;
          }
        }

        .el-icon-search {
          color: $font-dark;
        }
      }
    }

    button {
      float: right;
      display: inline-block;
      cursor: pointer;
      background: $background-deep-dark;
      border: none;
      color: $font-dark;
      box-sizing: border-box;
      transition: 0.3s;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      margin: 0;
      padding: 0;
      width: 30px;
      height: 30px;
      line-height: 34px;
      font-size: 24px;
      text-align: center;
      white-space: nowrap;
      border-radius: 50%;
      outline: 0;
      flex-shrink: 0;

      &:hover {
        transform: rotate(360deg);
        color: $light-primary;
      }
    }
  }

  .scroll-container {
    overflow-y: scroll;
    flex: 1;
  }
}
</style>
