// 机组管理
const UnitManage = () =>
  import( /* webpackChunkName: "unitManage" */ "@/views/UnitManage")
//添加机组
const AddUnit = () =>
  import( /* webpackChunkName: "AddUnit" */ "@/views/UnitManage/AddUnit")
//特殊机组的服务记录详情
const SpecUnitDetail = () =>
  import( /* webpackChunkName: "SpecUnitDetail" */ "@/views/UnitManage/SpecUnitDetail")
//常规机组的服务记录详情
const UnitDetail = () =>
  import( /* webpackChunkName: "UnitDetail" */ "@/views/UnitManage/UnitDetail")
export default [
  {
    path: "board/:companyName",
    name: "UnitManage",
    component: UnitManage,
    meta: { title: "设备管理" }
  },
  {
    path: 'add',
    name: 'AddUnit',
    component: AddUnit,
    meta: { title: "添加设备" }
  },
  {
    path: 'spec-unit-desc/:unitId',
    name: 'SpecUnitDetail',
    component: SpecUnitDetail,
    meta: { title: "服务记录" }
  },
  {
    path: 'unit-desc/:unitId',
    name: 'UnitDetail',
    component: UnitDetail,
    meta: { title: "服务记录" }
  },
]