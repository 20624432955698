import { render, staticRenderFns } from "./image-element.vue?vue&type=template&id=31640674&scoped=true&"
import script from "./image-element.vue?vue&type=script&lang=js&"
export * from "./image-element.vue?vue&type=script&lang=js&"
import style0 from "./image-element.vue?vue&type=style&index=0&id=31640674&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31640674",
  null
  
)

export default component.exports