const dialog = {
    namespaced: true,
    state: {
        dialogOfModifyPassword: false, //修改密码      -x
        dialogOfPublishNotice: false, //发布公告       -x
        dialogOfShowNotice: false, //查看公告      -x
        dialogOfTripType: false, //出差类型      -x
        dialogOfEditNotice: false, //编辑公告        -x
        dialogOfAddHost: false, //我的桌面-指派任务-新增机组   包含技术交流和用户现场服务  -x
        dialogOfAddStaffOfTechnologyTrip: false, //我的桌面-指派任务-新增员工   用户现场服务       -x
        dialogOfEditHost: false, //机组维护-查看-编辑           -x
        dialogOfRejectCheckTask: false, //我的桌面-审核任务-审核-不通过 -x
        dialogOfModifyStaff: false, //员工管理 - 高级管理- 修改员工信息   -x
        dialogOfManualAddStaff: false, //员工管理 - 高级管理- 手动添加员工    -x
        dialogOfBatchAddStaff: false, //员工管理 - 高级管理- 批量添加员工     -x
        showOfAddRemind: false, //员工管理 -新增提醒               -nx
        dialogOfAddRemind: false, //员工管理 -新增提醒               -nx
        showOfSetRemind: false, //员工管理 -提醒设置               -nx
        dialogOfSetRemind: false, //员工管理 -提醒设置               -nx
        dialogOfDispatchList: false, //显示预派单                   -x          
        dialogVisibleOfStaffStatus: false, //员工 出差 状态
        dialogVisibleOfStaffStatusPre: false, //员工 预派单 状态              
        showDeleteTask: false, //出差台账 - 删除预派单        -no
        addHostOrCompany: false, //指派出差  - 新建
        //合格证 begin
        dialogOfAddServer: false,
        dialogOfEditServer: false,
        dialogOfDelServer: false,
        dialogOfPubServer: false,
        dialogOfAddInstall: false,
        dialogOfEditInstall: false,
        dialogOfAddTest: false,
        dialogOfEditTest: false,
        dialogOfDelInstallOrTest: false,
        installListDialog: false,           //安装编号
        showArgumentsDialog: false,      //安装试车数据
        changeStateDialog: false,    //安装试车退回
        //合格证 end
        // 服务记录详情begin
        dialogOfAddRecord: false,
        dialogOfEditRecord: false,
        dialogOfEndTrip: false,
        dialogOfEndTripTip: false,
        dialogOfMustEndTrip: false,
        // 服务记录详情end
        // 出差统计
        dialogOfModifyPay: false,
        dialogOfStaffCompare: false,
        dialogOfCrewCompare: false,
        dialogOfShowImage: false,   //显示上传文件
        dialogOfSeriousTable: false, //环境安全点检表
        dialogOfSeriousForm: false, //环境安全点检表A4
        dialogOfTripRate: false,    // 完工确认单 评分弹窗
        dialogOfAddCompany: false,  //客户管理 - 新增客户
        dialogOfEditCompany: false,  //客户管理 - 修改客户
        dialogOfAddCompanyTags: false,  //客户管理 -  新增用户单位标签
        dialogOEditCompanyTags: false, //客户管理 -  修改用户单位标签
        dialogOfAddCrewCode: false, //客户管理-客户管理详情-新增机组
        dialogOfAddContact: false,  //客户管理-客户管理详情-新增联系人
        dialogOfGears: false, //出差统计-薪资-档位管理
        dialogOfArea: false, //出差统计-薪资-地区管理
        dialogOfAddImportantQues: false,     //出差统计 - 重大问题 - 新增重大问题
        dialogOfPreviewImportantQuest: false, //出差统计 - 重大问题 - 预览重大问题
        dialogOfPreviewImportantQuestFinance: false, //出差统计 - 科室小结 - 预览科室小结
        dialogOfFeedbackDetail: false,       //系统消息-环境安全点检表反馈信息详情
        dialogOfUploadConditionOrder: false, //档案管理- 上传条件确认单
        dialogOfGenerateFile: false,             //档案管理  - 联系单 - 生成联系单
        dialogOfQualityInformationFeedbackSheetOfAdd: false, //档案管理-质量反馈单 -新建
        dialogOfSysMessageQualityOrderDetail: false,  //系统消息-- 质量反馈单--详情
        dialogOfUpdateCrewStatus: false,     //客户管理 - 机组管理--手动更新节点
        // 实时看板begin
        dialogOfTimeBoardTripPane: false,        //我的桌面-实时看板-显示指派清单面板
        // 实时看板end
        dialogOfCutImage: false,

    },
    mutations: {
        SET_DIALOG (state, { key, value }) {
            if (state.hasOwnProperty(key)) {
                state[key] = value
            }
        },
        SET_SHOWOFADDREMIND (state, bStop) {
            state.showOfAddRemind = bStop;
        },
        SET_DIALOGOFADDREMIND (state, bStop) {
            state.dialogOfAddRemind = bStop;
        },
        SET_SHOWOFSETREMIND (state, bStop) {
            state.showOfSetRemind = bStop;
        },
        SET_DIALOGOFSETREMIND (state, bStop) {
            state.dialogOfSetRemind = bStop;
        },
        SET_DIALOGOFSTAFFSTATUS (state, bStop) {
            state.dialogVisibleOfStaffStatus = bStop;
        },
        SET_DIALOGOFSTAFFSTATUSPRE (state, bStop) {
            state.dialogVisibleOfStaffStatusPre = bStop;
        }
    },
    actions: {
        setDialog ({ commit }, { key, value }) {
            commit('SET_DIALOG', { 'key': key, 'value': value });
        },

        setShowOfAddRemind ({ commit }, bStop) {
            commit("SET_SHOWOFADDREMIND", bStop)
        },
        setDialogOfAddRemind ({ commit }, bStop) {
            commit("SET_DIALOGOFADDREMIND", bStop)
        },
        setShowOfSetRemind ({ commit }, bStop) {
            commit("SET_SHOWOFSETREMIND", bStop)
        },
        setDialogOfSetRemind ({ commit }, bStop) {
            commit("SET_DIALOGOFSETREMIND", bStop)
        },
        setDialogOfStaffStatus ({ commit }, bStop) {
            commit("SET_DIALOGOFSTAFFSTATUS", bStop)
        },
        setDialogOfStaffStatusPre ({ commit }, bStop) {
            commit("SET_DIALOGOFSTAFFSTATUSPRE", bStop)
        },
    }
}
export default dialog