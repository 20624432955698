import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState({
      isGroupPane: (state) => state.group.isGroupPane,
    })
  },
  methods: {
    // 获取其他用户资料  userIDList : []
    getUserProfile (userIDList) {
      return new Promise((resolve, reject) => {
        this.tim
          .getUserProfile({ userIDList })
          .then(({ data }) => {
            console.log('mixins chat getUserProfile', data)
            resolve(data)
          })
          .catch((err) => {
            console.log('mixins chat getUserProfile is error', err)
            reject()
          })
      })
    },
    // 获取群组信息
    getGroupProfile (groupID) {
      return new Promise((resolve, reject) => {
        this.tim
          .getGroupProfile({ groupID })
          .then(({ data }) => {
            console.log('mixins chat getGroupProfile', data.group)
            resolve(data.group)
          })
          .catch((err) => {
            console.log('mixins chat getGroupProfile is error', err)
            reject()
          })
      })
    },
    getGroupList () {
      let val = this.isGroupPane
      this.$store.dispatch('group/isGroupPane', !val)
      setTimeout(() => {
        this.$store.dispatch('group/isGroupPane', val)
      }, 200)
    },
  }
}