import { render, staticRenderFns } from "./face-element.vue?vue&type=template&id=19f7fcb0&scoped=true&"
import script from "./face-element.vue?vue&type=script&lang=js&"
export * from "./face-element.vue?vue&type=script&lang=js&"
import style0 from "./face-element.vue?vue&type=style&index=0&id=19f7fcb0&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19f7fcb0",
  null
  
)

export default component.exports