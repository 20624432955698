<template>
  <section class="scroll-pane-container" ref="scrollPaneContainer" @wheel="handleScroll">
    <slot />
  </section>
</template>

<script>
const tagAndTagSpacing = 4 // tagAndTagSpacing

export default {
  name: 'ScrollPane',
  data() {
    return {
      left: 0
    }
  },
  methods: {
    handleScroll(e) {
      console.log('wheel')
      const eventDelta = e.wheelDelta || -e.deltaY * 40
      const $scrollPaneContainer = this.$refs.scrollPaneContainer
      $scrollPaneContainer.scrollLeft =
        $scrollPaneContainer.scrollLeft - eventDelta / 4
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
