const friendModules = {
  namespaced: true,
  state: {
    friendList: [],
    createFriendModelVisible: false,
    isFriendPane: false
  },
  mutations: {
    upadteFriendList (state, friendList) {
      state.friendList = friendList
    },
    reset (state) {
      Object.assign(state, {
        friendList: [],
        createFriendModelVisible: false
      })
    },
    TOGGLE_ISFRIPANE (state, bStop) {
      state.isFriendPane = bStop
    }
  },
  actions: {
    upadteFriendList ({ commit }, list) {
      commit('upadteFriendList', list)
    },
    isFriendPane ({ commit }, bStop) {
      commit('TOGGLE_ISFRIPANE', bStop)
    }
  }
}

export default friendModules
