<template>
  <div
    id="message-send-box-wrapper"
    :style="focus ? {'backgroundColor': 'white'} : {}"
    @drop="dropHandler"
    v-loading.fullscreen.lock="fullscreenLoading"
  >
    <div class="send-header-bar">
      <el-popover placement="top" v-model="visibleOfEmoji" width="400" trigger="click">
        <div class="emojis">
          <div v-for="item in emojiName" class="emoji" :key="item" @click="chooseEmoji(item)">
            <img :src="emojiUrl + emojiMap[item]" style="width:30px;height:30px" />
          </div>
        </div>
        <i class="iconfont icon-smile" slot="reference" title="发表情"></i>
      </el-popover>
      <i class="iconfont icon-tupian" title="发图片" @click="handleSendImageClick"></i>
      <i class="el-icon-camera" title="发视频" @click="handleSendVideoClick"></i>
      <i class="el-icon-s-platform" title="白板会议" @click="handWhiteBoard"></i>
      <i class="iconfont icon-wenjian" title="发文件" @click="handleSendFileClick"></i>
      <template v-if="toAccount !== userID">
        <template v-if="currentConversationType==='C2C'">
          <i class="el-icon-phone" title="语音通话" @click="voiceCall"></i>
          <i class="el-icon-video-camera" title="视频通话" @click="videoCall"></i>
        </template>
        <template v-if="currentConversationType==='GROUP'">
          <i class="el-icon-phone" title="语音通话" @click="showMemberList('voice')"></i>
          <i class="el-icon-video-camera" title="视频通话" @click="showMemberList('video')"></i>
        </template>
      </template>
    </div>
    <el-popover
      trigger="manual"
      v-model="showAtGroupMember"
      placement="top"
      style="max-height:500px;overflow-y:scroll;"
    >
      <el-radio-group
        v-model="atUserID"
        style="display:flex;flex-decoration: column;"
        v-for="(member,key) in memberList"
        :key="member.userID+key"
        @change="handleSelectAtUser"
      >
        <el-radio :label="member.userID">{{ member.nameCard || member.nick || member.userID }}</el-radio>
      </el-radio-group>
    </el-popover>
    <div class="bottom">
      <textarea
        ref="text-input"
        rows="4"
        resize="false"
        v-model="messageContent"
        class="text-input"
        @focus="focus = true"
        @blur="focus = false"
        @keydown.enter.exact.prevent="handleEnter"
        @keyup.ctrl.enter.prevent.exact="handleLine"
        @keydown.up.stop="handleUp"
        @keydown.down.stop="handleDown"
      ></textarea>
      <el-tooltip
        class="item"
        effect="dark"
        content="按Enter发送消息，Ctrl+Enter换行"
        placement="left-start"
      >
        <div class="btn-send" @click="sendTextMessage">
          <div class="tim-icon-send"></div>
        </div>
      </el-tooltip>
    </div>
    <input
      type="file"
      id="imagePicker"
      ref="imagePicker"
      accept=".jpg, .jpeg, .png, .gif"
      @change="sendImage"
      style="display:none"
    />
    <input type="file" id="filePicker" ref="filePicker" @change="sendFile" style="display:none" />
    <input
      type="file"
      id="videoPicker"
      ref="videoPicker"
      @change="sendVideo"
      style="display:none"
      accept=".mp4"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { emojiMap, emojiName, emojiUrl } from '@/utils/emojiMap'
import trtcCalling from './../mixins/trtcCalling'
import { debounce } from '@/utils'
export default {
  name: 'message-send-box',
  props: ['scrollMessageListToButtom'],
  mixins: [trtcCalling],
  data() {
    return {
      messageContent: '',
      file: '',
      emojiMap: emojiMap,
      emojiName: emojiName,
      emojiUrl: emojiUrl,
      showAtGroupMember: false,
      atUserID: '',
      focus: false,
      visibleOfEmoji: false,
      fullscreenLoading:false
    }
  },
  computed: {
    ...mapGetters(['toAccount', 'currentConversationType']),
    ...mapState({
      memberList: (state) => state.group.currentMemberList,
      userID: (state) => state.user.userID,
      currentConversation: (state) => state.conversation.currentConversation,
      showGroupMemberList: (state) => state.group.showGroupMemberList,
      currentUserProfile: (state) => state.user.currentUserProfile,
      callStatus: (state) => state.video.callStatus,
    }),
  },
  mounted() {
    this.$refs['text-input'].addEventListener('paste', this.handlePaste)
    this.$bus.$on('reEditMessage', this.reEditMessage)
  },
  beforeDestroy() {
    this.$refs['text-input'].removeEventListener('paste', this.handlePaste)
  },
  methods: {
    reEditMessage(payload) {
      this.messageContent = payload
    },
    handleSelectAtUser() {
      this.messageContent += this.atUserID + ' '
      this.showAtGroupMember = false
    },
    handleUp() {
      const index = this.memberList.findIndex(
        (member) => member.userID === this.atUserID
      )
      if (index - 1 < 0) {
        return
      }
      this.atUserID = this.memberList[index - 1].userID
    },
    handleDown() {
      const index = this.memberList.findIndex(
        (member) => member.userID === this.atUserID
      )
      if (index + 1 >= this.memberList.length) {
        return
      }
      this.atUserID = this.memberList[index + 1].userID
    },
    handleEnter() {
      if (this.showAtGroupMember) {
        this.handleSelectAtUser()
      } else {
        this.sendTextMessage()
      }
    },
    handleLine() {
      this.messageContent += '\n'
    },
    handlePaste(e) {
      let clipboardData = e.clipboardData
      let file
      if (
        clipboardData &&
        clipboardData.files &&
        clipboardData.files.length > 0
      ) {
        file = clipboardData.files[0]
      }

      if (typeof file === 'undefined') {
        return
      }
      // 1. 创建消息实例，接口返回的实例可以上屏
      let message = this.tim.createImageMessage({
        to: this.toAccount,
        conversationType: this.currentConversationType,
        payload: {
          file: file,
        },
        onProgress: (percent) => {
          this.$set(message, 'progress', percent) // 手动给message 实例加个响应式属性: progress
        },
      })
      this.$store.commit('pushCurrentMessageList', message)

      // 2. 发送消息
      let promise = this.tim.sendMessage(message)
      promise.catch((error) => {
        this.$store.commit('showMessage', {
          type: 'error',
          message: error.message,
        })
      })
    },
    dropHandler(e) {
      e.preventDefault()
      let file = e.dataTransfer.files[0]
      let message = {}
      if (file.type === 'video/mp4') {
        message = this.tim.createVideoMessage({
          to: this.toAccount,
          conversationType: this.currentConversationType,
          payload: {
            file: file,
          },
          onProgress: (percent) => {
            this.$set(message, 'progress', percent) // 手动给message 实例加个响应式属性: progress
          },
        })
      } else {
        message = this.tim.createFileMessage({
          to: this.toAccount,
          conversationType: this.currentConversationType,
          payload: {
            file: file,
          },
          onProgress: (percent) => {
            this.$set(message, 'progress', percent) // 手动给message 实例加个响应式属性: progress
          },
        })
      }
      this.$store.commit('pushCurrentMessageList', message)
      this.tim
        .sendMessage(message)
        .then(() => {
          this.$refs.videoPicker.value = null
        })
        .catch((imError) => {
          this.$store.commit('showMessage', {
            message: imError.message,
            type: 'error',
          })
        })
    },
    sendTextMessage() {
      if (
        this.messageContent === '' ||
        this.messageContent.trim().length === 0
      ) {
        this.messageContent = ''
        this.$store.commit('showMessage', {
          message: '不能发送空消息哦！',
          type: 'info',
        })
        return
      }
      const message = this.tim.createTextMessage({
        to: this.toAccount,
        conversationType: this.currentConversationType,
        payload: { text: this.messageContent },
      })
      this.$store.commit('pushCurrentMessageList', message)
      this.$bus.$emit('scroll-bottom')
      this.tim.sendMessage(message).catch((error) => {
        this.$store.commit('showMessage', {
          type: 'error',
          message: error.message,
        })
      })
      this.messageContent = ''
    },
    chooseEmoji(item) {
      this.messageContent += item
      this.visibleOfEmoji = false
    },
    handleSendImageClick() {
      this.$refs.imagePicker.click()
    },
    handleSendFileClick() {
      this.$refs.filePicker.click()
    },
    handleSendVideoClick() {
      this.$refs.videoPicker.click()
    },
    //白板会议
    handWhiteBoard(){
      let message = {
        to:this.toAccount,
        conversationType:this.currentConversationType,
        payload:{
          data:{
            type: 2,
            roomId: Math.floor(Math.random(100000000,9999999999)*1000000000),
            // content: `${this.realName}邀请您参加视频会议`,
            content: `白板会议`,
          }
        }
      }
      this.sendInviteMessage(message)
    },
    // 发送白板会议邀请的自定义消息
    sendInviteMessage: function ({ to,conversationType, payload: { data } }) {
      return new Promise((resolve, reject) => {
        const message = this.tim.createCustomMessage({
          to: to,
          conversationType: conversationType,
          payload: {
            data: JSON.stringify(data),
            description: '',
            extension: '',
          },
        })
        this.$store.commit('pushCurrentMessageList', message)
        this.tim
                .sendMessage(message)
                .then(() => {
                  this.$bus.$emit('dialogOfWhiteBoard',true)
                  resolve()
                })
                .catch((error) => {
                  this.$store.commit('showMessage', {
                    type: 'error',
                    message: error.message,
                  })
                  reject()
                })
      })
    },
    // 显示群组人员列表
    showMemberList(callComponentType) {
      if(this.callStatus !== 'connected'){
        this.$store.dispatch('video/updateCallComponentType', callComponentType)
        this.$store.dispatch('group/showGroupMember', !this.showGroupMemberList)
      }else{
        this.$Message({
          message: '您正在通话中...',
          type: 'warning'
        });
      }

    },
    // 发起语音通话   - 单聊
    voiceCall: debounce(function () {
      if(this.callStatus !== 'connected'){
        this.fullscreenLoading = true;
        this.$store.dispatch('video/updateCallComponentType', 'voice')
        this.makeTrtcCalling(this.TrtcCalling.CALL_TYPE.AUDIO_CALL).then(() => {
          this.updateCallConfig({
            callComponentType: 'voice',
            isFromGroup: false,
          })
          this.fullscreenLoading = false;
        }).catch(()=>{
          this.fullscreenLoading = false
        })
      }else{
        this.$Message({
          message: '您正在通话中...',
          type: 'warning'
        });
      }

    }, 500),
    // 发起视频通话  - 单聊
    videoCall: debounce(function () {
      if(this.callStatus !== 'connected'){
        this.fullscreenLoading = true;
        this.$store.dispatch('video/updateCallComponentType', 'video')
        this.makeTrtcCalling(this.TrtcCalling.CALL_TYPE.VIDEO_CALL).then(() => {
          this.$store.dispatch('video/updateCallStatus', 'calling')
          this.$store.dispatch('video/updateIsInviter', true)
          this.updateCallConfig({
            callComponentType: 'video',
            isFromGroup: false,
          })
          this.fullscreenLoading = false;
        }).catch((err) => {
          // this.$Message.error('获取用户设备权限失败');
          this.fullscreenLoading = false
        })
      }else{
        this.$Message({
          message: '您正在通话中...',
          type: 'warning'
        });
      }
    }, 500),
    sendImage() {
      const message = this.tim.createImageMessage({
        to: this.toAccount,
        conversationType: this.currentConversationType,
        payload: {
          file: document.getElementById('imagePicker'), // 或者用event.target
        },
        onProgress: (percent) => {
          this.$set(message, 'progress', percent) // 手动给message 实例加个响应式属性: progress
        },
      })
      this.$store.commit('pushCurrentMessageList', message)
      this.tim
        .sendMessage(message)
        .then(() => {
          this.$refs.imagePicker.value = null
        })
        .catch((imError) => {
          this.$store.commit('showMessage', {
            message: imError.message,
            type: 'error',
          })
        })
    },
    sendFile() {
      const message = this.tim.createFileMessage({
        to: this.toAccount,
        conversationType: this.currentConversationType,
        payload: {
          file: document.getElementById('filePicker'), // 或者用event.target
        },
        onProgress: (percent) => {
          this.$set(message, 'progress', percent) // 手动给message 实例加个响应式属性: progress
        },
      })
      this.$store.commit('pushCurrentMessageList', message)
      this.tim
        .sendMessage(message)
        .then(() => {
          this.$refs.filePicker.value = null
        })
        .catch((imError) => {
          this.$store.commit('showMessage', {
            message: imError.message,
            type: 'error',
          })
        })
    },
    sendVideo() {
      const message = this.tim.createVideoMessage({
        to: this.toAccount,
        conversationType: this.currentConversationType,
        payload: {
          file: document.getElementById('videoPicker'), // 或者用event.target
        },
        onProgress: (percent) => {
          this.$set(message, 'progress', percent) // 手动给message 实例加个响应式属性: progress
        },
      })
      this.$store.commit('pushCurrentMessageList', message)
      this.tim
        .sendMessage(message)
        .then(() => {
          this.$refs.videoPicker.value = null
        })
        .catch((imError) => {
          this.$store.commit('showMessage', {
            message: imError.message,
            type: 'error',
          })
        })
    },
  },
}
</script>

<style lang="stylus" scoped>
#message-send-box-wrapper {
  box-sizing: border-box;
  overflow: hidden;
  padding: 3px 20px 20px 20px;
  position: relative;
}

.emojis {
  height: 160px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: scroll;
}

.emoji {
  height: 40px;
  width: 40px;
  box-sizing: border-box;
}

.send-header-bar {
  box-sizing: border-box;
  padding: 3px 0 0 0;
}

.send-header-bar i {
  cursor: pointer;
  font-size: 24px;
  color: gray;
  margin: 0 12px 0 0;
}

.send-header-bar i:hover {
  color: $black;
}

textarea {
  resize: none;
}

.text-input {
  font-size: 16px;
  width: 100%;
  box-sizing: box-sizing;
  border: none;
  outline: none;
  background-color: transparent;
}

.block {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

.bottom {
  padding-top: 10px;
  position: relative;

  .btn-send {
    cursor: pointer;
    position: absolute;
    color: $primary;
    font-size: 30px;
    right: 0;
    bottom: -5px;
    padding: 6px 6px 4px 4px;
    border-radius: 50%;
  }
}
</style>
