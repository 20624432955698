<!-- 选择群聊人员 -->
<template>
  <section class="member-dialog" v-if="showGroupMemberList">
    <section class="member-list">
      <template v-for="(member,key) in memberList">
        <div class="member-item" :key="'member'+member.userID+key" v-if="member.userID!==userID">
          <el-checkbox
            v-model="member.selected"
            @change="updateCheckBox"
            :key="'checkbox'+member.userID+key"
          ></el-checkbox>
          <div class="user-info" @click="toggleCheck(key)">
            <el-image :src="member.avatar"></el-image>
            <span>{{member.nick}}</span>
          </div>
        </div>
      </template>
    </section>
    <div class="member-operator">
      <el-button size="mini" type="success" @click="submit">确定</el-button>
      <el-button size="mini" type="danger" @click="cancel">取消</el-button>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'CallLayerMember',
  components: {},
  computed: {
    ...mapState({
      currentConversation: (state) => state.conversation.currentConversation,
      showGroupMemberList: (state) => state.group.showGroupMemberList,
      userID: (state) => state.user.userID,
    }),
  },
  watch: {
    showGroupMemberList: {
      handler: function (val) {
        if (val) {
          this.getMemberList()
        }
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      memberList: [],
    }
  },
  methods: {
    updateCheckBox() {
      this.$forceUpdate()
    },
    toggleCheck(key) {
      this.memberList[key].selected = !this.memberList[key].selected
      this.updateCheckBox()
    },
    getMemberList() {
      return new Promise((resolve, reject) => {
        let data = {
          groupID: this.currentConversation.groupProfile.groupID,
          count: 100,
          offset: 0,
        }
        this.tim
          .getGroupMemberList(data)
          .then(({ data }) => {
            let { memberList } = data
            if (memberList.length) {
              memberList.map((item) => {
                item.selected = false
                return item
              })
            }
            this.memberList = memberList
            resolve()
          })
          .catch((err) => {
            reject()
          })
      })
    },
    refreshData() {
      let list = this.memberList.filter((i) => {
        return (
          (i.hasOwnProperty('selected') && i.selected) ||
          i.userID === this.userID
        )
      })
      this.$emit('refreshMemberList', list)
    },
    submit() {
      this.refreshData()
      this.cancel()
    },
    cancel() {
      this.$store.dispatch('group/showGroupMember', false)
    },
  },
}
</script>
<style lang='scss' scoped>
.member-dialog {
  width: 100%;
  height: 246px;
  padding: 16px 24px 50px;
  box-sizing: border-box;
  position: absolute;
  bottom: 8px;
  background: #fff;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 12px 0px;
}
.member-list {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: scroll;
  .member-item {
    display: flex;
    justify-content: center;
    height: 90px;
  }
  .user-info {
    display: flex;
    flex-direction: column;
    height: 90px;
    margin-right: 10px;
    cursor: pointer;
    .el-image {
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }
    span {
      color: #333;
      font-size: 14px;
      text-align: center;
      height: 22px;
      line-height: 22px;
      display: inline-block;
    }
  }
}
.member-operator {
  position: absolute;
  right: 24px;
  bottom: 16px;
}
</style>