<template>
  <div class="list-container">
    <div class="header-bar">
      <el-input
        size="mini"
        v-model="realName"
        placeholder="输入好友名搜索"
        @keypress.enter.native="searchFriendByRealName"
      ></el-input>
    </div>
    <div class="group-container">
      <template v-if="hasFriend">
        <friend-item v-for="friend in friendList" :key="friend.friendImId" :friend="friend" />
      </template>
      <template v-else>
        <div style="color:gray;padding: 10px 20px;">暂无好友</div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import FriendItem from './friend-item.vue'
import { Autocomplete } from 'element-ui'
import { getFriendList } from '@/api/im'
export default {
  components: {
    FriendItem,
    ElAutocomplete: Autocomplete,
  },
  computed: {
    ...mapState({
      friendList: (state) => state.friend.friendList,
      isFriendPane: (state) => state.friend.isFriendPane,
    }),
    hasFriend() {
      return this.friendList.length > 0
    },
  },
  watch: {
    isFriendPane: {
      handler(val) {
        if (val) {
          this.getFriendList()
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      realName: '',
    }
  },
  methods: {
    getFriendList() {
      let data = {
        page: 1,
        limit: 9999,
        realName: this.realName,
      }
      getFriendList(data)
        .then((res) => {
          let {
            data: { list },
          } = res
          this.$store.commit('friend/upadteFriendList', list)
        })
        .catch((err) => {
          this.$store.commit('showMessage', {
            type: 'error',
            message: err.message,
          })
        })
    },
    searchFriendByRealName() {
      this.getFriendList()
    },
    selectFriend() {},
  },
}
</script>

<style lang="stylus" scpoed>
.list-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .group-container {
    overflow-y: scroll;
  }

  .header-bar {
    display: flex;
    flex-shrink: 0;
    height: 50px;
    border-bottom: 1px solid $background-deep-dark;
    padding: 10px 10px 10px 20px;

    .group-seach-bar {
      width: 100%;
      margin-right: 10px;

      >>> .el-input {
        input {
          color: $first;
          border: none;
          border-radius: 30px;
          background-color: $deep-background !important;

          &::placeholder {
            color: $font-dark;
          }
        }

        .el-icon-search {
          color: $font-dark;
        }
      }
    }

    button {
      float: right;
      display: inline-block;
      cursor: pointer;
      background: $background-deep-dark;
      border: none;
      color: $font-dark;
      box-sizing: border-box;
      transition: 0.3s;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      margin: 0;
      padding: 0;
      width: 30px;
      height: 30px;
      line-height: 34px;
      font-size: 24px;
      text-align: center;
      white-space: nowrap;
      border-radius: 50%;
      outline: 0;
      flex-shrink: 0;

      &:hover {
        transform: rotate(360deg);
        color: $light-primary;
      }
    }
  }

  .scroll-container {
    overflow-y: scroll;
    flex: 1;
  }
}
</style>
