
import api from "./request"
/**
 * 截图
 * @param params
 * @returns {Promise<AxiosResponse<T>>}
 */
export const addScreenShot = (params) => {
    return api.post("/admin/htcs/trtcTranscribe/addScreenShot", params)
}
/**
 * 录制
 * @param params
 * @returns {Promise<AxiosResponse<T>>}
 */
export const startTrtcRoomTranscribe = (params) => {
    return api.get("/admin/htcs/trtcTranscribe/startTrtcRoomTranscribe", { params })
}
/**
 * 停止录制
 * @param params
 * @returns {Promise<AxiosResponse<T>>}
 */
export const stopTrtcRoomTranscribe = (params) => {
    return api.get("/admin/htcs/trtcTranscribe/stopTrtcRoomTranscribe", { params })
}