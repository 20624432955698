const HTCSWebOrder = () =>
  import( /* webpackChunkName: "HTCSWebOrder" */ "@/views/HTCSWeb/HTCSWebOrder")
const HTCSWebOrderOfAdd = () =>
  import( /* webpackChunkName: "HTCSWebOrderOfAdd" */ "@/views/HTCSWeb/HTCSWebOrder/HTCSWebOrderOfAdd")
const HTCSWebOrderOfEdit = () =>
  import( /* webpackChunkName: "HTCSWebOrderOfEdit" */ "@/views/HTCSWeb/HTCSWebOrder/HTCSWebOrderOfEdit")
const HTCSWebOrderOfHandle = () =>
  import( /* webpackChunkName: "HTCSWebOrderOfHandle" */ "@/views/HTCSWeb/HTCSWebOrder/HTCSWebOrderOfHandle")
const HTCSWebOrderOfDetail = () =>
  import( /* webpackChunkName: "HTCSWebOrderOfDetail" */ "@/views/HTCSWeb/HTCSWebOrder/HTCSWebOrderOfDetail")
export default [
  {
    path: "list",
    name: "HTCSWebOrder",
    component: HTCSWebOrder,
    meta: { title: "工单管理" }
  },
  {
    path: "add",
    name: "HTCSWebOrderOfAdd",
    component: HTCSWebOrderOfAdd,
    meta: { title: "新增工单" }
  },
  {
    path: "edit/:id",
    name: "HTCSWebOrderOfEdit",
    component: HTCSWebOrderOfEdit,
    meta: { title: "修改工单" }
  },
  {
    path: "handle/:id",
    name: "HTCSWebOrderOfHandle",
    component: HTCSWebOrderOfHandle,
    meta: { title: "处理工单" }
  },
  {
    path: "detail/:id",
    name: "HTCSWebOrderOfDetail",
    component: HTCSWebOrderOfDetail,
    meta: { title: "工单详情" }
  },

]