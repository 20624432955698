//档案管理
const ContactOrder = () =>
  import( /* webpackChunkName: "ContactOrder" */ "@/views/Archives/ContactOrder")
const ContactOrderOfAdd = () =>
  import( /* webpackChunkName: "ContactOrderOfAdd" */ "@/views/Archives/ContactOrder/ContactOrderOfAdd")
const ContactOrderOfEdit = () =>
  import( /* webpackChunkName: "ContactOrderOfEdit" */ "@/views/Archives/ContactOrder/ContactOrderOfEdit")
const ContactOrderOfDetail = () =>
  import( /* webpackChunkName: "ContactOrderOfDetail" */ "@/views/Archives/ContactOrder/ContactOrderOfDetail")
const QualityInformationFeedbackSheet = () =>
  import( /* webpackChunkName: "QualityInformationFeedbackSheet" */ "@/views/Archives/QualityInformationFeedbackSheet")
const ConditionConfirmOrder = () =>
  import( /* webpackChunkName: "ConditionConfirmOrder" */ "@/views/Archives/ConditionConfirmOrder")
const ReplaceOrder = () =>
  import( /* webpackChunkName: "ReplaceOrder" */ "@/views/Archives/ReplaceOrder")
const MeetingSummary = () =>
  import( /* webpackChunkName: "MeetingSummary" */ "@/views/Archives/MeetingSummary")
const DispatchOrder = () =>
  import( /* webpackChunkName: "DispatchOrder" */ "@/views/Archives/DispatchOrder")
export default [
  {
    path: 'contact-order',
    name: 'ContactOrder',
    meta: { title: '联系单', },
    component: ContactOrder,
  },
  {
    path: 'add-contact',
    name: 'ContactOrderOfAdd',
    meta: { title: '新建联系单', },
    component: ContactOrderOfAdd
  },
  {
    path: 'edit-contact/:id',
    name: 'ContactOrderOfEdit',
    meta: { title: '编辑联系单', },
    component: ContactOrderOfEdit
  },
  {
    path: 'catch-contact/:id',
    name: 'ContactOrderOfDetail',
    meta: { title: '查看联系单', },
    component: ContactOrderOfDetail
  },
  {
    path: 'quality-order',
    name: "QualityInformationFeedbackSheet",
    meta: { title: '质量反馈单' },
    component: QualityInformationFeedbackSheet
  },
  {
    path: 'confirm-order',
    name: 'ConditionConfirmOrder',
    meta: { title: "条件确认单", },
    component: ConditionConfirmOrder
  },
  {
    path: 'replace-order',
    name: 'ReplaceOrder',
    meta: { title: '补发件' },
    component: ReplaceOrder
  },
  {
    path: 'meeting-summary',
    name: 'MeetingSummary',
    meta: { title: '会议纪要' },
    component: MeetingSummary
  },
  {
    path: 'dispatch-order',
    name: 'DispatchOrder',
    meta: { title: '发运单' },
    component: DispatchOrder
  }
]