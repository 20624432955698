const settings = {
    namespaced: true,
    state: {
        openSidebar: false,             //是否显示导航栏： false  :  不显示   true ： 显示
    },
    mutations: {
        SET_SETTING (state, { key, value }) {
            if (state.hasOwnProperty(key)) {
                state[key] = value
            }
        }
    },
    actions: {
    }
}
export default settings;
