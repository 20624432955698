import API_HOST from '@/api/host'
import Cookies from 'js-cookie'

const app = {
  namespaced: true,
  state: {
    openSidebar: false,             //是否显示导航栏： false  :  显示   true ： 隐藏
    device: 'desktop',              // 设备：   deskTop : PC    mobile:  移动设备
    count: 0,            //系统消息未读数
    appMainScroll: true,   //有滚动条时 ，.app-main是否滚动
    showNextDialog: false, //修改工单初始化时是否显示下一步的弹窗
    sharkFlag: false,  //收到消息时，最小化框抖动
    msgType: "txt",//txt calling cancelCalling  holdUp
    kkFileUrl:"https://fileview.zjxianlin.com/onlinePreview?url=",
    uploadUrl: API_HOST + '/open/file/upload',
    sidebar: {
      withoutAnimation: false
    },
  },
  mutations: {
    SET_APP (state, { key, value }) {
      if (state.hasOwnProperty(key)) {
        state[key] = value
      }
    },
    CLOSE_SIDEBAR: (state, withoutAnimation) => {
      Cookies.set('sidebarStatus', 0)
      state.sidebar.opened = false
      state.sidebar.withoutAnimation = withoutAnimation
    },
  },
  actions: {
    setApp ({ commit }, { key, value }) {
      commit('SET_APP', { key, value })
    },
    shark ({ commit }) {
      commit('SET_APP', { 'key': 'sharkFlag', 'value': true });
      let timer = setTimeout(() => {
        commit('SET_APP', { 'key': 'sharkFlag', 'value': false });
        clearTimeout(timer)
      }, 2000)
    },
    setMsgType ({ commit }, type) {
      commit('SET_APP', { key: 'msgType', value: type })
    },
    closeSideBar({ commit }, { withoutAnimation }) {
      commit('CLOSE_SIDEBAR', withoutAnimation)
    },
  }
}
export default app;
