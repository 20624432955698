import { parseTime, getCurrentMonthFirst, getCurrentMonthLast } from '@/utils'
export default {
    methods: {
        //table  斑马纹 class
        tabRowClassName ({ row, rowIndex }) {
            let index = rowIndex + 1;
            if (index % 2 == 0) {
                return 'warning-row'
            }
        },
        //table  表头 class
        tabHeadCellClassName () {
            return "header-cell";
        },
        // 递归获取table表格中的tr元素
        getTrDom (el) {
            let nodeName = el.nodeName
            if (nodeName === 'TR') {
                return el
            } else {
                return this.getTrDom(el.parentNode)
            }
        },
        /**
         * 表格处理双击选中样式
         * dom：鼠标选中的元素   tr里面的元素
         */
        changeTrStyle (dom) {
            let clickTrDom = this.getTrDom(dom)
            let trBodyDom = clickTrDom.parentNode
            let trList = trBodyDom.querySelectorAll('tr')
            for (let i = 0; i < trList.length; i++) {
                trList[i].classList.remove('my-current-row')
            }
            clickTrDom.classList.add('my-current-row')
        },
        /**
         * 表格删除选中的样式
         * dom：table元素标签
         */
        cancelTrStyle (dom) {
            let trList = dom.querySelectorAll('.my-current-row')
            for (let i = 0; i < trList.length; i++) {
                trList[i].classList.remove('my-current-row')
            }
        },
        //半年前的月份的第一天
        getCurrentMonthFirstDay (chat = '-') {
            let time = new Date();
            time.setMonth(time.getMonth() - 5);
            return parseTime(getCurrentMonthFirst(time), `{y}${chat}{m}${chat}{d}`)
        },
        //当月的最后一天
        getCurrentMonthLastDay (chat = '-') {
            let time = new Date();
            return parseTime(getCurrentMonthLast(time), `{y}${chat}{m}${chat}{d}`)
        },
        toLine (name) {
            return name.replace(/([A-Z])/g, '_$1').toLowerCase()
        },
        closeSelectedTag (view) {
            this.$store
                .dispatch('tagsView/delView', view)
            // .then(({ visitedViews }) => {
            //     if (this.isActive(view)) {
            //         this.toLastView(visitedViews, view)
            //     }
            // })
        },
        
    }
}