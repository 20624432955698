// 员工管理
const StaffManage = () =>
  import( /* webpackChunkName: "staffManage" */ "@/views/StaffManage")
const StaffDetail = () =>
  import( /* webpackChunkName: "StaffDetail" */ "@/views/StaffDetail")
// 高级管理
const SeniorStaffManage = () =>
  import( /* webpackChunkName: "SeniorStaffManage" */ "@/views/StaffManage/components/SeniorManage")
export default [
  {
    path: 'board',
    name: 'StaffManage',
    meta: { title: '科员管理' },
    component: StaffManage
  },
  {
    path: 'high-manage',
    name: 'SeniorStaffManage',
    meta: { title: '高级管理' },
    component: SeniorStaffManage
  },
  {
    path: 'detail/:id',
    name: 'StaffDetail',
    meta: { title: '科员详情' },
    component: StaffDetail
  }
]