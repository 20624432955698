import { getCookie } from "./auth";

export const getUserInfo = () => {
  return getCookie("hql-userInfo");
};

export const firstWordToUp = (str) => {
  return str[0].toUpperCase() + str.slice(1);
};
export const random = () => {
  let max = 100000000;
  let min = 1;
  return Math.floor(Math.random() * (max - min) + min);
};
export const toLowerCaseOfCamel = (str) => {
  return str
    .replace(/[A-Z]/g, function(word) {
      return `-${word.toLowerCase()}`;
    })
    .slice(1);
};

export const transformWord = (str, transType = "up") => {
  if (!str.length || str.length == 0) {
    return str;
  }
  return str
    .split("")
    .map((i) => {
      if (transType == "up") {
        i.toUpperCase();
      } else {
        i.toLowerCase();
      }
      return i;
    })
    .join("");
};

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }
  const format = cFormat || "{y}-{m}-{d} {h}:{i}:{s}";
  let date;
  if (typeof time === "object") {
    date = time;
  } else {
    if (typeof time === "string" && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }
    if (typeof time === "number" && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  };
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === "a") {
      return ["日", "一", "二", "三", "四", "五", "六"][value];
    }
    return value.toString().padStart(2, "0");
  });
  return time_str;
}
/**
 * @param {Array} actual
 * @returns {Array}
 */
export function cleanArray(actual) {
  const newArray = [];
  for (let i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i]);
    }
  }
  return newArray;
}
/**
 * @param {Object} json
 * @returns {Array}
 */
export function param(json) {
  if (!json) return "";
  return cleanArray(
    Object.keys(json).map((key) => {
      if (json[key] === undefined) return "";
      return key + "=" + json[key];
      // return encodeURIComponent(key) + '=' + encodeURIComponent(json[key])
    })
  ).join("&");
}
/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
  const search = url.split("?")[1];
  if (!search) {
    return {};
  }
  return JSON.parse(
    '{"' +
      decodeURIComponent(search)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"')
        .replace(/\+/g, " ") +
      '"}'
  );
}
// kkfile文件预览时，linux和windows路径转换不一致
export function url2Right(url) {
  let retUrl = "";
  if (url.startsWith("https://")) {
    let str = url.slice(8);
    str = str.replace(/\\/g, "/");
    str = str.replace(/\/\//g, "/");
    retUrl = `https://${str}`;
  } else if (url.startsWith("http://")) {
    let str = url.slice(7);
    str = str.replace(/\\/g, "/");
    str = str.replace(/\/\//g, "/");
    retUrl = `http://${str}`;
  } else {
    let str = url;
    str = str.replace(/\\/g, "/");
    str = str.replace(/\/\//g, "/");
    retUrl = str;
  }
  return retUrl;
}
/**
 * 获取某月份的第25天
 * @param time      当前时间
 * @returns {Date}
 */
export const getCurrentMonthDate = (time) => {
  let object = {};
  let dateTime = new Date(time);
  //获取当前月的号
  let day = dateTime.getDate();
  //获取当前月份0-11
  let currentMonth = dateTime.getMonth();
  //获取当前年
  let currentYear = dateTime.getFullYear();
  let start = "";
  let end = "";
  let startYear = currentYear;
  let startMon = currentMonth;
  let endYear = currentYear;
  let endMon = currentMonth;

  //当前日是否小于24号
  if (day <= 24) {
    //如果本月为1号 则 开始月为上年的12月
    if (currentMonth == 0) {
      startYear--;
      startMon = 12;
    }
    start = startYear + "-" + startMon + "-" + "25";
    //结束日期为 本年本月的 24号
    end = endYear + "-" + (endMon + 1) + "-" + "24";
  } else {
    console.log(endMon);
    //如果本月 是 12月 则 结束月为明年的1月
    if (currentMonth == 11) {
      endYear++;
      endMon = 1;
    } else {
      endMon = endMon + 2;
    }
    //开始日期为本年本月的25号
    start = startYear + "-" + (startMon + 1) + "-" + "25";
    //结束日期为下个月的24号
    end = endYear + "-" + endMon + "-" + "24";
  }
  object.beginDate = start;
  object.endDate = end;
  return object;
};
/**
 * 获取某月份的第一天
 * @param time      当前时间
 * @returns {Date}
 */
export const getCurrentMonthFirst = (time) => {
  let date = new Date(time);
  date.setDate(1);
  return date;
};

/**
 * trtcCalling 插件打印日志
 */
const LOG_PREFIX = "trtc-callling-webrtc-demo:";
export function log(content) {
  console.log(`${LOG_PREFIX} ${content}`);
}

/**
 * 获取某月份的最后一天
 * @param time   time 当前时间
 * @returns {Date}
 */
export const getCurrentMonthLast = (time) => {
  let date = new Date(time);
  let currentMonth = date.getMonth();
  let nextMonth = ++currentMonth;
  let nextMonthFirstDay = new Date(date.getFullYear(), nextMonth, 1);
  let oneDay = 1000 * 60 * 60 * 24;
  return new Date(nextMonthFirstDay - oneDay);
};
/**
 * 获取上一个月第一天的时间
 * @param time  当前时间
 * @returns {Date}
 */
export const getPreMonthFirst = (time) => {
  return new Date(
    new Date(time).getFullYear(),
    new Date(time).getMonth() - 1,
    1
  );
};
/**
 *  获取上一个月最后一天的时间
 * @param time  当前时间
 * @returns {Date}
 */
export const getPreMonthLast = (time) => {
  let date = new Date(time);
  let day = new Date(date.getFullYear(), date.getMonth(), 0).getDate();
  return new Date(new Date().getFullYear(), new Date().getMonth() - 1, day);
};
/**
 * 获取今年的第一天
 * @param time  当前时间
 * @returns {Date}
 */
export const getCurrentYearFirst = (time) => {
  let date = new Date(time);
  date.setMonth(0);
  date.setDate(1);
  return date;
};
/**
 * 获取上一年的第一天
 * @param time  当前时间
 * @returns {Date}
 */
export const getPreYearFirst = (time) => {
  let date = new Date(time);
  date.setFullYear(date.getFullYear() - 1);
  date.setMonth(0);
  date.setDate(1);
  return date;
};
/**
 * 获取上一年的最后一天
 * @param time
 * @returns {Date}
 */
export const getPreYearLast = (time) => {
  let date = new Date(time);
  date.setFullYear(date.getFullYear() - 1);
  date.setMonth(11);
  date.setDate(31);
  return date;
};
/**
 * 计算时间差 ，单位天
 * @param time1
 * @param time2
 * @returns {number}
 */
export const dayDiff = (time1, time2) => {
  if (!time1 || !time2) return 0;
  let timestamp1 = new Date(time1).getTime();
  let timestamp2 = new Date(time2).getTime();
  let timestampDiff = Math.abs(timestamp1 - timestamp2);
  return Math.ceil(timestampDiff / (1000 * 3600 * 24)) + 1;
};
/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result;

  const later = function() {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp;

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last);
    } else {
      timeout = null;
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args);
        if (!timeout) context = args = null;
      }
    }
  };

  return function(...args) {
    context = this;
    timestamp = +new Date();
    const callNow = immediate && !timeout;
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait);
    if (callNow) {
      result = func.apply(context, args);
      context = args = null;
    }

    return result;
  };
}

/**
 * 空对象判断
 * @param data  Object
 * @returns {boolean}
 * @constructor
 */
export const IsEmptyObject = (data) => {
  if (!data) {
    return true;
  }
  let arr = Object.keys(data);
  if (arr.length == 0) {
    return true;
  } else {
    return false;
  }
};

// 过滤掉 false,null,0,undefiend,NaN
export const bouncer = (arr) => {
  return arr.filter(function(val) {
    return !(!val || val === "");
  });
};
export const getUserId = () => {
  let data = JSON.parse(getCookie("userId"));
  return data;
};

/**
 * 获取操作系统
 */
export const getOS = () => {
  var sUserAgent = navigator.userAgent;
  var isWin = navigator.platform == "Win32" || navigator.platform == "Windows";
  var isMac =
    navigator.platform == "Mac68K" ||
    navigator.platform == "MacPPC" ||
    navigator.platform == "Macintosh" ||
    navigator.platform == "MacIntel";
  if (isMac) return "Mac";
  var isUnix = navigator.platform == "X11" && !isWin && !isMac;
  if (isUnix) return "Unix";
  var isLinux = String(navigator.platform).indexOf("Linux") > -1;
  if (isLinux) return "Linux";
  if (isWin) {
    var isWin2K =
      sUserAgent.indexOf("Windows NT 5.0") > -1 ||
      sUserAgent.indexOf("Windows 2000") > -1;
    if (isWin2K) return "Win2000";
    var isWinXP =
      sUserAgent.indexOf("Windows NT 5.1") > -1 ||
      sUserAgent.indexOf("Windows XP") > -1;
    if (isWinXP) return "WinXP";
    var isWin2003 =
      sUserAgent.indexOf("Windows NT 5.2") > -1 ||
      sUserAgent.indexOf("Windows 2003") > -1;
    if (isWin2003) return "Win2003";
    var isWinVista =
      sUserAgent.indexOf("Windows NT 6.0") > -1 ||
      sUserAgent.indexOf("Windows Vista") > -1;
    if (isWinVista) return "WinVista";
    var isWin7 =
      sUserAgent.indexOf("Windows NT 6.1") > -1 ||
      sUserAgent.indexOf("Windows 7") > -1;
    if (isWin7) return "Win7";
    var isWin10 =
      sUserAgent.indexOf("Windows NT 10") > -1 ||
      sUserAgent.indexOf("Windows 10") > -1;
    if (isWin10) return "Win10";
  }
  return "other";
};

/**
 * 获得浏览器名称及版本号
 */
export const getBrowse = () => {
  var browser = {};
  var userAgent = navigator.userAgent.toLowerCase();
  var s;
  (s = userAgent.match(/msie ([\d.]+)/))
    ? (browser.ie = s[1])
    : (s = userAgent.match(/firefox\/([\d.]+)/))
    ? (browser.firefox = s[1])
    : (s = userAgent.match(/chrome\/([\d.]+)/))
    ? (browser.chrome = s[1])
    : (s = userAgent.match(/opera.([\d.]+)/))
    ? (browser.opera = s[1])
    : (s = userAgent.match(/version\/([\d.]+).*safari/))
    ? (browser.safari = s[1])
    : 0;
  var version = "";
  if (browser.ie) {
    version = "IE " + browser.ie;
  } else {
    if (browser.firefox) {
      version = "firefox " + browser.firefox;
    } else {
      if (browser.chrome) {
        version = "chrome " + browser.chrome;
      } else {
        if (browser.opera) {
          version = "opera " + browser.opera;
        } else {
          if (browser.safari) {
            version = "safari " + browser.safari;
          } else {
            version = "未知浏览器";
          }
        }
      }
    }
  }
  return version;
};
// Android/iOS设备判断
export const getDevice = () => {
  let ua = navigator.userAgent;
  if (/(iPhone|iPad|iPod|iOS)/i.test(ua)) {
    return "IOS";
  } else if (/(Android)/i.test(navigator.userAgent)) {
    return "ANDROID";
  } else {
    return "H5";
  }
};
// IM begin

export function throttle(func, wait) {
  let timeout;
  return function() {
    let that = this;
    let args = arguments;

    if (!timeout) {
      timeout = setTimeout(() => {
        timeout = null;
        func.apply(that, args);
      }, wait);
    }
  };
}

/**
 * 利用 document.title 做新消息提示
 * @export
 * @param {Number} count
 */
export function titleNotify(count) {
  const hasNewMessage = count > 0;
  if (hasNewMessage) {
    if (document.title.search(/\((.*?)\)/) >= 0) {
      document.title = document.title.replace(
        /\((.*?)\)/,
        `(${count > 99 ? "99+" : count})`
      );
    } else {
      document.title = `(${count})${document.title}`;
    }
  } else {
    document.title = document.title.replace(/\((.*?)\)/, "");
  }
}

// IM end

/**
 * 构造树型结构数据
 * @param {*} data 数据源
 * @param {*} id id字段 默认 'id'
 * @param {*} parentId 父节点字段 默认 'parentId'
 * @param {*} children 孩子节点字段 默认 'children'
 */
export function handleTree(data, id, parentId, children) {
  let config = {
    id: id || "id",
    parentId: parentId || "parentId",
    childrenList: children || "children",
  };

  var childrenListMap = {};
  var nodeIds = {};
  var tree = [];

  for (let d of data) {
    let parentId = d[config.parentId];
    if (childrenListMap[parentId] == null) {
      childrenListMap[parentId] = [];
    }
    nodeIds[d[config.id]] = d;
    childrenListMap[parentId].push(d);
  }

  for (let d of data) {
    let parentId = d[config.parentId];
    if (nodeIds[parentId] == null) {
      tree.push(d);
    }
  }

  for (let t of tree) {
    adaptToChildrenList(t);
  }

  function adaptToChildrenList(o) {
    if (childrenListMap[o[config.id]] !== null) {
      o[config.childrenList] = childrenListMap[o[config.id]];
    }
    if (o[config.childrenList]) {
      for (let c of o[config.childrenList]) {
        adaptToChildrenList(c);
      }
    }
  }
  return tree;
}

export const addDateRange = (params, dateRange, propName) => {
  let search = params;
  search.params =
    typeof search.params === "object" &&
    search.params !== null &&
    !Array.isArray(search.params)
      ? search.params
      : {};
  dateRange = Array.isArray(dateRange) ? dateRange : [];
  if (typeof propName === "undefined") {
    search.params["beginTime"] = dateRange[0];
    search.params["endTime"] = dateRange[1];
  } else {
    search.params["begin" + propName] = dateRange[0];
    search.params["end" + propName] = dateRange[1];
  }
  return search;
};
