import { render, staticRenderFns } from "./message-bubble.vue?vue&type=template&id=6c9246b6&scoped=true&"
import script from "./message-bubble.vue?vue&type=script&lang=js&"
export * from "./message-bubble.vue?vue&type=script&lang=js&"
import style0 from "./message-bubble.vue?vue&type=style&index=0&id=6c9246b6&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c9246b6",
  null
  
)

export default component.exports