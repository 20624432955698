<template>
    <div>
        <div v-for="(item,index) in liveData" class="">
            <div :id='"videoPlayer"+item.deviceId' style="width:100%; height:auto;margin-top:10px;" @click="big(item)"></div>
            <div>{{item.deviceName}}</div>
        </div>
<!--        <div id='id_test_video1' style="width:100%; height:auto;"></div>-->
    </div>


</template>
<!--<script src="@/utils/TcPlayer-2.4.1.js"></script>-->
<script>
    export default {
        name: "PlayerLive",
        props:['liveData'],
        inject: ['reload'],
        watch: {
            liveData: {
                handler(val,old) {
                    this.$nextTick(()=>{
                        if(old!==undefined){
                            old.forEach((item,index)=>{
                                this.vars["tcplyaer" + item.deviceId].destroy()
                                // if(document.getElementById('videoPlayer'+item.deviceId)!==null){
                                //     document.getElementById('videoPlayer'+item.deviceId).removeChild(document.getElementById('videoPlayer'+item.deviceId).childNodes[0])
                                // }

                            })
                        }
                    })
                    if (val.length>0) {
                      this.liveData = val
                        this.$nextTick(()=>{
                            this.liveData.forEach((item,index)=>{
                                this.live(item)
                            })
                        })
                    }else{
                        // this.reload()
                    }
                },
                immediate: true,
                deep:true
            },
        },
        data (){
            return{
                vars: {},
                height:"400",
                width:'225'
                // height:"112",
                // width:'198'
            }
        },
        mounted() {
            // this.live()
        },
        methods:{
            live(item){
                var varName = "tcplyaer" + item.deviceId; //动态定义变量名
                this.vars[varName] = new TcPlayer('videoPlayer'+item.deviceId, {
                    "m3u8": item.playUrl, //请替换成实际可用的播放地址
                    "autoplay" : true,      //iOS 下 safari 浏览器，以及大部分移动端浏览器是不开放视频自动播放这个能力的
                    "poster" : "http://www.test.com/myimage.jpg",
                    "width" :  "400",//视频的显示宽度，请尽量使用视频分辨率宽度
                    "height" : "225",//视频的显示高度，请尽量使用视频分辨率高度
                    "live" :true
                });
            },
            big(value){
                console.log(value)
                var varName = "tcplyaer" + value.deviceId; //动态定义变量名
                console.log(this.vars[varName])
                this.vars[varName].height = '300px';
                this.vars[varName].width = '300px'
            }
        },

    }
</script>

<style scoped>
::v-deep.vcp-volume{
    display: none !important;
}
</style>