//Vue 全局组件库
import MainTopComponent from "./MainTop"
import NodataComponent from "./NoData"
import PaginationComponent from "./Pagination"
import ScrollPaneComponent from './ScrollPane'

export const MainTop = () => {
    return {
        install: function (Vue) {
            Vue.component("MainTop", MainTopComponent);
        }
    }
}

export const Nodata = () => {
    return {
        install: function (Vue) {
            Vue.component("Nodata", NodataComponent);
        }
    }
}

export const MyPagination = () => {
    return {
        install: function (Vue) {
            Vue.component("Pagination", PaginationComponent);
        }
    }
}

export const ScrollPane = () => {
    return {
        install: function (Vue) {
            Vue.component("ScrollPane", ScrollPaneComponent)
        }
    }
}