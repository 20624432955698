export const roleTypeTransToRoleName = (roleType) => {
    let rolesType = {
        "10": "super-admin",            //超级管理员 【暂无权限】
        "11": "director",            //老总       【暂无权限】
        "20": "director",             //处长
        "30": "leader",               //科长
        "40": "staff",                //科员
        "41": "checker",               //审核员
        "43": 'finance',             //财务
        "44": 'hgz-staff',          //合格证 - 业务员
        "45": 'hgz-leader',          //合格证 - 管理员
        "46": 'attendanceManager',          //出勤管理员
        "47": 'hgz-searcher',          //合格证 - 检索人员
        "50":'qualityOrder-manage',        //质量反馈单的管理员
    }
    return rolesType[roleType];
}
/**
 * 用户角色不能进入的路由
 * @param role
 */
export const roleNotEnterRoles = (role) => {
    let list = {
        "director": [],
        "leader": [],
        "staff": ['/spare/business-trip', '/spare/technology-trip', '/spare/checkTask/tripData', '/spare/checkTask/otherData', "/spare/viewPane"],
        "checker": ['/spare/business-trip', '/spare/technology-trip', "/spare/viewPane"]
    }
    return list[role];
}
